import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { Filters } from 'components/layout/filters';
import { FormDialog } from 'components/forms/form-dialog';
import Sidebar from 'components/shared/sidebar';
import { ContentWithSidebar } from 'components/shared/content-with-sidebar';
import { authState } from 'features/engine/auth';
import { useRecoilValue } from 'recoil';
import { app } from 'application';
import { ListWidget } from 'shared/build/views';
import { run } from 'dollarscript/build/interpreter';
import Icon from '@material-ui/core/Icon';
import { useListState } from 'features/engine/state';
import { DateRageQueryControl } from 'components/query-controls/date-range-control';

type InputProps = ListWidget['props'] & { displayed?: boolean };

export function List({
  entity,
  filters,
  elementProps,
  element,
  title,
  subtitle,
  sortBy,
  customFilters,
  displayed,
  query,
  queryControl,
}: InputProps) {

  const {
    filteredItems,
    setCustomFilterActivity,
    handleSubmit,
    handleClose,
    handleCreate,
    setFilterValues,
    canCreateGuard,
    dialogOpened,
    frontendEntity,
    filtersKeys,
    customFiltersActivity,
    filterFields,
    items,
    isMaterialized,
    period,
    dateRange
  } = useListState(app, entity, displayed, filters, customFilters, undefined, query as any, queryControl);
  const itemName = frontendEntity.specEntity.name;
  const { t , i18n} = useTranslation();
  const currentUser = useRecoilValue(authState);
  const sortedItems = sortBy ? filteredItems.sort((a, b) => b[sortBy] - a[sortBy]) : filteredItems;
  const listTitle = title ? t(title) : t(frontendEntity.pluralName);
  const listSubtitle = subtitle && i18n.exists(subtitle) && t(subtitle);
  const listContstantElements = (<Box flexGrow={1}>
    { queryControl && <Box mb={2}><DateRageQueryControl period={period} dateRange={dateRange} /></Box>}
    {sortedItems.map(i => (
      <Box
        width="100%"
        key={i.id}
        mb={1}
      >
        {React.createElement(
          element as any,
          {
            entity: frontendEntity,
            item: i,
            ...elementProps,
            isMaterialized,
            link: (elementProps as any).link ? run({ currentEntity: i, currentUser })((elementProps as any).link) : undefined
          }
        )}
      </Box>
    ))}
    {sortedItems.length === 0 && <Box color="rgba(0,0,0,0.1)" display="flex" pt={2} flexDirection="column" alignItems="center">
      <Box height="200px" mb={2} color="rgba(0,0,0,0.1)" fontSize="200px">
        <Icon color="inherit" fontSize="inherit" >cloud_circle</Icon>
      </Box>
      <Typography>
        <Box fontSize="18px" color="rgba(0,0,0,0.2)" component="span">
          {sortedItems.length === 0 && items.length > 0 && t(`no-results-matching-search`)}
          {sortedItems.length === 0 && items.length === 0 && t(`no-${frontendEntity.pluralName}`)}
        </Box>
      </Typography>
    </Box>}
    {dialogOpened && <FormDialog
      title={`create-${frontendEntity.name}`}
      entity={frontendEntity}
      onClose={handleClose}
      onSubmit={handleSubmit}
      open={dialogOpened}
    />}
  </Box>);

  if (filtersKeys.length > 0) {
    return (
      <ContentWithSidebar sidebar={<Sidebar>
        <Box paddingRight={3} paddingLeft={3} paddingTop={3} width="320px">
          <Box mb={2}>
            <Typography variant="h5">{listTitle}</Typography>
          </Box>
          {listSubtitle && <Box mb={3}>
            <Typography color="textSecondary" variant="body2">
              {listSubtitle}
            </Typography>
          </Box>}
          {canCreateGuard && (<Box mb={3}>
            <Button
              variant="contained"
              fullWidth={true}
              color="primary"
              onClick={() => handleCreate()}
              size="large"
              startIcon={frontendEntity.icon ? <Icon>{frontendEntity.icon}</Icon> : undefined}
            >
              {t(`add-new-${itemName}`)}
            </Button>
          </Box>)}
          <>
            <Box mb={1.5}>
              <Typography variant="h5">{t('filters')}</Typography>
            </Box>
            <Box mb={2}>
              <Filters setCustomFilterActivity={setCustomFilterActivity} customFilters={customFiltersActivity} onSubmit={setFilterValues} filters={filterFields} />
            </Box>
          </>
        </Box>
      </Sidebar>}>
        {listContstantElements}
      </ContentWithSidebar>
    );
  } else {
    return <Box width="100%">
      <Box position="sticky" mb={2} display="flex" textAlign="right">
        <Box mr={2}><Typography variant="h6">{listTitle}</Typography></Box>
        {canCreateGuard && (<Box mb={3}>
          <Button
            variant="contained"
            fullWidth={true}
            color="primary"
            onClick={() => handleCreate()}
            size="large"
            startIcon={frontendEntity.icon ? <Icon>{frontendEntity.icon}</Icon> : undefined}
          >
            {t(`add-new-${frontendEntity.specEntity.name}`)}
          </Button>
        </Box>)}
      </Box>
      {listContstantElements}
    </Box>
  }
}
