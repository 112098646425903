import React, { useCallback, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { FieldsFormProperties } from 'features/engine/models';
import { Control, Controller } from 'react-hook-form';
import { app } from 'application';
import Typography from '@material-ui/core/Typography';
import { PostCodesInputType } from 'shared/build/inputs';
import { camelToKebabCase } from 'features/forms/models';
import { FrontendField } from 'features/engine/field';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { run } from 'dollarscript/build/interpreter';
import 'isomorphic-unfetch';
import objectValues from 'lodash/values';
import get from 'lodash/get';

const getPostCode = (code: string | number) => `${code || ''}000`.slice(0, 5)
const fetchLocation = (postCode: string, country: string, code: string) => fetch(`https://app.zipcodebase.com/api/v1/search?apikey=562cfa50-4568-11eb-8b64-ffd73e8a8c76&codes=${postCode}&country=${country}`, {
  headers: { Accept: 'application/json' },
  method: 'GET',
}).then((res) => {
  return res.json().then(body => {
    const result = objectValues(body.results)[0][0];
    const currentEntity: Partial<Record<keyof PostCodesInputType['builder'], any>> = {
      lat: Number(result.latitude),
      lon: Number(result.longitude),
      street: '-',
      city: result.city || '-',
      countryCode: country,
      postalCode: code,
    };
    return currentEntity;
  })
})

export const FormPostCodeAddress: React.FC<{ field: FrontendField, fieldProperties?: FieldsFormProperties, control: Control, inputConfig: PostCodesInputType, name: string, initialValue?: any }> = ({
  field,
  control,
  inputConfig,
  name,
  initialValue,
}) => {
  const values = control.getValues();
  const address = get(values, name);
  const formPostalCode = get(values, `${name}.postalCode`);
  const formCountryCode = get(values, `${name}.countryCode`);
  const [country, setCountry] = useState(formCountryCode || get(values, `${name}.countryCode`));
  const [code, setCode] = useState(formPostalCode || get(values, `${name}.postalCode`));
  useEffect(() => {
    const initialValues: Partial<Record<keyof PostCodesInputType['builder'], any>> = {
      lat: Number(get(values, `${name}.coordinates.lat`)) || Number(get(initialValue || {}, `${name}.coordinates.lat`)) || 0,
      lon: Number(get(values, `${name}.coordinates.lon`)) || Number(get(initialValue || {}, `${name}.coordinates.lon`)) || 0,
      street: get(values, `${name}.street`) || get(initialValue || {}, `${name}.street`) || '-',
      city: get(values, `${name}.city`) || get(initialValue || {}, `${name}.city`) || '-',
      countryCode: get(initialValue || {}, `${name}.countryCode`) || '',
      postalCode: get(initialValue || {}, `${name}.postalCode`) || '',
    };
    setCountry(initialValues.postalCode);
    setCode(initialValues.countryCode);
    const builtInitialAddress = run({ currentEntity: initialValues })(inputConfig.builder);
    control.setValue(`${name}`, builtInitialAddress);
  }, [setCountry, setCode])
  useEffect(() => {
    if (formPostalCode) {
      setCode(formPostalCode);
    }
  }, [formPostalCode]);
  useEffect(() => {
    if (formCountryCode) {
      setCountry(formCountryCode);
    }
  }, [formCountryCode]);
  const [lastRequest, setLastRequest] = useState({ country: address?.countryCode, code: address?.postalCode })
  const { t } = useTranslation();
  const handleBlur = useCallback(() => {
    const postCode = getPostCode(code);
    if (!!country) {
      const initialValues: Partial<Record<keyof PostCodesInputType['builder'], any>> = {
        lat: 0,
        lon: 0,
        street: '-',
        city: '-',
        countryCode: country || formCountryCode,
        postalCode: code || formPostalCode,
      };
      const builtInitialAddress = run({ currentEntity: initialValues })(inputConfig.builder);
      control.setValue(`${name}`, builtInitialAddress);
    }
    if (!!country && postCode.length === 5 && (lastRequest.code !== code || lastRequest.country !== country)) {
      setLastRequest({ code, country });
      const headers = {
        Accept: 'application/json',
      }
      fetchLocation(postCode, country, code).then((currentEntity) => {
        const builtAddress = run({ currentEntity })(inputConfig.builder);
        control.setValue(`${name}`, builtAddress);
      }).catch(() => {
        fetchLocation(postCode, country, code).then((currentEntity) => {
          const builtAddress = run({ currentEntity })(inputConfig.builder);
          control.setValue(`${name}`, builtAddress);
        }).catch(() => {
          const currentEntity: Partial<Record<keyof PostCodesInputType['builder'], any>> = {
            lat: 0,
            lon: 0,
            street: '-',
            city: '-',
            countryCode: country,
            postalCode: code,
          };
          const builtAddress = run({ currentEntity })(inputConfig.builder);
          control.setValue(`${name}`, builtAddress);
        })
      });
    }
  }, [country, code, lastRequest]);
  return (
    <Box>
      <Controller control={control} defaultValue={address} name={name} />
      <Box><Typography color="textSecondary" variant="body2"><Box component="span" fontWeight="600">{t(camelToKebabCase(name))}</Box></Typography></Box>
      <Box width="100%" display="flex" alignItems="flex-start">
        <Box mr={1}>
          <Autocomplete<string>
            options={app.enums.find(e => e.name === 'countryCode')?.values || []}
            getOptionLabel={(option) => option}
            noOptionsText={t('no-options')}
            value={country || ''}
            onChange={(_e, v) => {
              setCountry(v)
            }}
            onKeyPress={e => {
              if (e.which === 13 || e.keyCode === 13 || e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onBlur={handleBlur}
            renderInput={(params) => <TextField error={!field.nullable && !country} {...params} margin="dense" label={t('country-code')} variant="outlined" />}
          />
        </Box>
        <Box flexGrow={1}>
          <TextField
            onKeyPress={e => {
              if (e.which === 13 || e.keyCode === 13 || e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            error={!field.nullable && !code}
            onBlur={handleBlur}
            InputLabelProps={{ shrink: !!code }}
            margin="dense"
            value={code}
            onChange={(v) => setCode(String(v.target.value))}
            type="text"
            label={t('code')}
            variant="outlined"
            fullWidth={true}
          />
        </Box>
      </Box>
      {address && address.city && <Box fontSize={10} color="rgba(0,0,0,0.57)">
        {address.city}
      </Box>
      }
    </Box>
  );
};
