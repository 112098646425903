import { FrontendField, getFieldNames } from 'features/engine/field';
import React, { useEffect } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';
import { GoogleMapsInputType } from 'shared/build/inputs';
import { run } from 'dollarscript/build/interpreter';
import { FieldsFormProperties } from 'features/engine/models';
import { get } from 'lodash';
import { useState } from 'react';
import { app } from 'application';
import { useFormContext } from 'react-hook-form';

interface InputProps {
  inputConfig: GoogleMapsInputType,
  field: FrontendField,
  control: any,
  fieldProperties: FieldsFormProperties;
}

export const selectStyles = {
  container: (provided: any) => ({
    ...provided,
    marginTop: '12px'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '2px 12px'
  }),
  input: (provided: any) => {
    return ({
      ...provided,
      fontFamily: 'Mulish, sans-serif',
      color: 'rgba(0,0,0,0.67)',
      fontSize: 16,
      padding: 0,
    })
  },
  control: (provided: any) => ({
    ...provided,
    borderRadius: '8px',
    borderColor: '#c4c4c4',
    marginBottom: '4px'
  }),
  menu: (provided: any) => ({
    ...provided,
    background: '#fff',
    zIndex: 100000000,
    marginTop: '0'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontFamily: 'Mulish, sans-serif',
    fontSize: 16
  })
}


export const GoogleMapsAutocompleter: React.FC<InputProps> = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    getFieldNames(props.inputConfig.builder, props.field.name).forEach((name) => {
      props.control.register({ name, type: 'custom', defaultValue: get(props.fieldProperties.value, name.split('.').slice(1).join('.')) }, { required: !props.field.nullable })
    });
  }, []);
  const methods = useFormContext();
  const isError = !!get(methods.errors, props.field.name);
  const label = props.fieldProperties.fieldValue && `${props.fieldProperties.fieldValue.street}, ${props.fieldProperties.fieldValue.city}, ${props.fieldProperties.fieldValue.countryCode}${props.fieldProperties.fieldValue.postalCode}`;
  const [autocompleteValue, setValue] = useState(label && {
    label,
    value: {
      description: '',
      matched_substrings: [],
      place_id: '',
      reference: '',
      terms: [],
      types: [],
    }
  })
  return (
    <>
      <GooglePlacesAutocomplete
        apiKey={app.services && app.services.geocoding && app.services.geocoding.apiKey}
        // autocompletionRequest={{ types: ['address'] }} // 'locality', 'postal_code', 
        selectProps={{
          value: autocompleteValue as any,
          onChange: (res: any) => {
            setValue(res);
            if (!res || !res.value || !res.value.place_id) { return }
            geocodeByPlaceId(res.value.place_id)
              .then(place => {
                const lon = place[0].geometry.location.lng();
                const lat = place[0].geometry.location.lat();
                const street = `${place[0].address_components.find(a =>
                  a.types.includes('street_address') ||
                  a.types.includes('route'))?.long_name} ${place[0].address_components.find(a => a.types.includes('street_number'))?.long_name || ''}` || ' '
                const city = place[0].address_components.find(a =>
                  a.types.includes('administrative_area_level_3') ||
                  a.types.includes('administrative_area_level_2') ||
                  a.types.includes('locality') ||
                  a.types.includes('postal_town'))?.short_name || ' ';
                const countryCode = place[0].address_components.find(a =>
                  a.types.includes('country'))?.short_name || ' ';
                const postalCode = place[0].address_components.find(a =>
                  a.types.includes('postal_code'))?.short_name || ' ';
                const currentEntity: Partial<Record<keyof GoogleMapsInputType['builder'], any>> = {
                  lon,
                  lat,
                  street,
                  city,
                  countryCode,
                  postalCode
                };
                const builtAddress = run({ currentEntity })(props.inputConfig.builder);
                props.control.setValue(`${props.field.name}`, builtAddress);
                methods.clearErrors(props.field.name);
              })
          },
          placeholder: t('address-input'),
          styles: {
            ...selectStyles,
            control: (provided: any) => ({
              ...provided,
              borderRadius: '8px',
              borderColor: isError ? 'red' : '#c4c4c4',
              marginBottom: '4px'
            }),
          },
        }}
      />
    </>
  );
};
