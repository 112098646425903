import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { MtcDialog } from 'components/shared/dialog';
import { FrontendEntity, FrontendEntityAction } from 'features/engine/entity';
import { app } from 'application';
import { Guard, SendEmailAction, spec } from 'shared/build';
import keys from 'lodash/keys';
import { Suspense } from 'react';
import PageLoader from 'components/shared/page-loader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { run } from 'dollarscript/build/interpreter';

export interface InputProps<T> {
  entity: FrontendEntity<T>;
  item?: T;
  open: boolean;
  title: string;
  submitButtonText?: string;
  onSubmit: (item: T, action?: FrontendEntityAction) => void;
  onClose: () => void;
  inputEntityGuard?: Guard;
  relatedEntity?: FrontendEntity<T>;
  options?: SendEmailAction['options'],
}


export function FormDialog<T>({
  item,
  entity,
  submitButtonText,
  open,
  onSubmit,
  onClose,
  title,
  inputEntityGuard,
  relatedEntity,
  options
}: InputProps<T>) {
  const fields = entity.fields;
  const methods = useForm<T>({
    mode: 'onTouched',
    defaultValues: item as any,
    shouldFocusError: true,
    shouldUnregister: true,

  });
  const { errors, control, handleSubmit, getValues, trigger } = methods;
  const { t } = useTranslation();
  const invalid = Object.keys(errors).length > 0;
  const authService = app.useAuthService();
  const withCreate = entity.actions([getValues()], app).find(a => !!a.withCreate);
  const [withCreateAction, setWithCreateAction] = useState(false);
  const guard = !!withCreate && withCreate.guard ? run({ currentEntity: getValues(), currentUser: authService.currentUser })(withCreate.guard).allow : true;
  return (
    <MtcDialog
      onClose={onClose}
      isOpen={open}
      title={t(title)}
    >
      <Suspense fallback={<PageLoader />}>
        {open && (
          <Box mb={2}>
            <FormProvider {...methods} >
              <form onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleSubmit(data => {
                  if (keys(errors).length) return;
                  const arrayFilledData = { ...data };
                  entity.fields.forEach(f => { if (f.type === 'entity-array' && !f.nullable && !(arrayFilledData as any)[f.name]) { (arrayFilledData as any)[f.name] = [] } })
                  const outputData = { ...arrayFilledData as T, id: item && (item as any).id };
                  if (withCreateAction) {
                    onSubmit(outputData, withCreate);
                  } else {
                    onSubmit(outputData);
                  }
                })(e);
              }}>
                {fields.map((f, i) =>
                  f.getFormField(
                    app,
                    control,
                    errors,
                    getValues(),
                    t,
                    entity,
                    item,
                    authService.currentUser,
                    item ? 'update' : 'create',
                    undefined,
                    inputEntityGuard,
                    relatedEntity,
                    undefined,
                    undefined,
                    i === fields.length - 1 && !!item,
                    methods.formState.touched,
                    options,
                  )
                )}
                {!item && withCreate && guard && (
                  <FormControlLabel
                    control={<Checkbox
                      aria-invalid={!!errors}
                      checked={Boolean(withCreateAction)}
                      onChange={(_e, v) => {
                        setWithCreateAction(v);
                      }}
                    />}
                    label={t(withCreate.name)}
                  />
                )}
                <Box mt={2}>
                  <Button
                    size="large"
                    fullWidth={true}
                    type="submit"
                    variant="contained"
                    disabled={invalid}
                    color="primary"
                    onClick={e => e.stopPropagation()}
                  >
                    {submitButtonText || t('save')}
                  </Button>
                </Box>
              </form>
            </FormProvider>
          </Box>
        )}
      </Suspense>
    </MtcDialog >
  );
}
