import Button from '@material-ui/core/Button';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutlineRounded';
import ErrorOutline from '@material-ui/icons/ErrorOutlineRounded';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutlineRounded';
import { makeStyles } from '@material-ui/styles';
import { formatBytes } from 'features/engine/models';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles<
  Theme,
  {
    uploadError?: string;
    isSuccess: boolean;
  }
>(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: ({ uploadError, isSuccess }) => ({
    flex: '1 1 auto',
    width: theme.spacing(10),
    height: theme.spacing(10),
    color: uploadError
      ? theme.palette.error.main
      : isSuccess
      ? theme.palette.primary.main
      : theme.palette.grey[700],
  }),
  textContainer: {
    flex: '1 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    flex: '0 0 auto',
  },
}));

interface InputProps {
  closeUploadDialog: () => void;
  uploadedBytes: number;
  isCanceled: boolean;
  uploadError?: string;
}

type Props = InputProps;

const ConfirmationComponent: React.FC<Props> = ({
  isCanceled,
  uploadError,
  uploadedBytes,
  closeUploadDialog,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({
    uploadError,
    isSuccess: !uploadError && !isCanceled,
  });

  let title: string;
  let body: string;
  let icon: JSX.Element;

  if (uploadError) {
    title = t('upload-failed');
    body = uploadError;
    icon = <ErrorOutline className={classes.icon} />;
  } else if (isCanceled) {
    title = t('upload-canceled');
    body = t('no-files-uploaded');
    icon = <RemoveCircleOutline className={classes.icon} />;
  } else {
    title = t('upload-successful');
    body = t('uploaded-total', { total: formatBytes(uploadedBytes) });
    icon = <CheckCircleOutline className={classes.icon} />;
  }

  return (
    <div className={classes.root}>
      {icon}
      <div className={classes.textContainer}>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="subtitle1">{body}</Typography>
      </div>
      <Button
        onClick={closeUploadDialog}
        className={classes.button}
        variant="contained"
        color="primary"
        size="large"
        fullWidth={true}
      >
        {t('ok')}
      </Button>
    </div>
  );
};

export default ConfirmationComponent;
