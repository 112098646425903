import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { FrontendField, getDateString } from 'features/engine/field';
import { FieldsFormProperties } from 'features/engine/models';
import { DateRange } from 'shared/build/inputs';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { camelToKebabCase } from 'features/forms/models';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { FormInput } from 'components/shared/form-input';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


type InputProps = {
  isEdit: boolean;
  inputConfig: DateRange,
  field: FrontendField,
  control: Control,
  fieldProperties: FieldsFormProperties;
  errors: any;
};


export const DateRangeInput: React.FC<InputProps> = props => {
  const { field, control, fieldProperties, errors } = props;
  const { t } = useTranslation();
  const [endDateHidden, setEndDateHidden] = useState(!fieldProperties?.value?.end && !fieldProperties?.fieldValue?.end && props.isEdit)
  const methods = useFormContext();
  const value = methods.watch(field.name);
  const startTimestamp = new Date(value ? value.start : 0).getTime();
  useEffect(() => {
    if (value && startTimestamp > new Date(value.end).getTime() && !endDateHidden) {
      setTimeout(() => methods.setValue(`${field.name}.end`, startTimestamp + 1000 * 60 * 60), 10)
    }
  }, [startTimestamp, methods]);
  useEffect(() => {
    if (endDateHidden) {
      methods.setValue(`${field.name}.end`, null);
    }
  }, [endDateHidden]);
  return (
    <div>
      <Box mb={0.5}>
        <Typography variant="body2"><Box component="span" fontWeight="600">{t(camelToKebabCase(field.name))}</Box></Typography>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={endDateHidden}
            onChange={(_e, v) => {
              setEndDateHidden(v);
            }}
          />
        }
        label={t('fixed-date')}
      />
      {/* <Controller name={`${field.name}.start`} defaultValue={fieldProperties?.value?.start || new Date().setHours(8, 0, 0, 0)} />
      <Controller name={`${field.name}.end`} defaultValue={fieldProperties?.value?.end || new Date().setHours(9, 0, 0, 0)} /> */}
      <Box display="flex">
        <Box flexGrow={1}>
          <FormInput
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
            fullWidth={true}
            label={t('start-date')}
            margin="dense"
            controllerProps={{
              defaultValue: fieldProperties?.value?.start || new Date().setHours(8, 0, 0, 0),
              control,
              name: `${field.name}.start`,
            }}
            errors={get(errors, `${field.name}.start`)}
            formater={{
              parse: (v) => {
                return new Date(Number(v)).getTime() ? getDateString(new Date((Number(v)))) : undefined
              },
              format: (v) => { return new Date(v).getTime() }
            }}
          />
        </Box>
        {!endDateHidden && <Box flexGrow={1} ml={1}>
          <FormInput
            type="datetime-local"
            fullWidth={true}
            label={t('end-date')}
            InputLabelProps={{ shrink: true }}
            margin="dense"
            controllerProps={{
              defaultValue: fieldProperties?.value?.end || new Date().setHours(9, 0, 0, 0),
              control,
              name: `${field.name}.end`,
            }}
            errors={get(errors, `${field.name}.end`)}
            formater={{
              parse: (v) => {
                return new Date(Number(v)).getTime() ? getDateString(new Date((Number(v)))) : undefined
              },
              format: (v) => { return new Date(v).getTime() }
            }}
          />
        </Box>}
      </Box>
    </div>
  );
};
