import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get, kebabCase } from 'lodash';
import Box from '@material-ui/core/Box';
import { border, dashedBorder } from 'features/mui/theme';
import { IconButton, Typography } from '@material-ui/core';
import { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import PhotoIcon from '@material-ui/icons/InsertPhotoRounded';
import PdfIcon from '@material-ui/icons/PictureAsPdfRounded';
import FileIcon from '@material-ui/icons/DescriptionRounded';
import RemoveIcon from '@material-ui/icons/HighlightOffRounded';
import NoFilesIcon from '@material-ui/icons/NoSimRounded';
import DownloadIcon from '@material-ui/icons/CloudDownloadRounded';
import FileUploadDialog from 'components/shared/file-upload/file-upload-dialog';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const FileUploadField: React.FC<{ name: string, control: any, initialValue: any }> = ({
  name, control, initialValue
}) => {
  const { t } = useTranslation();
  const values: string[] = get(control.getValues(), `${name}.urls`) || get(initialValue, `${name}.urls`) || [];
  const [uploadDialogOpened, setUploadDialogOpened] = useState<boolean>(false);
  const handleClose = useCallback(() => setUploadDialogOpened(false), [
    setUploadDialogOpened,
  ]);
  const onUpload = useCallback(
    urls => {
      control.setValue(`${name}.urls`, [...(values || []), ...urls]);
      handleClose();
    },
    [handleClose, name, control, values]
  );
  const onDelete = useCallback(
    src => {
      control.setValue(`${name}.urls`, (values || []).filter(v => src !== v));
      handleClose();
    },
    [handleClose, name, control]
  );
  return <Box padding={2} style={{ background: '#fafafa' }} border={border} borderRadius={12}>
    <Box mb={2} fontWeight={900}>{t('file-list')}</Box>
    <Box ml={-2} display="flex">
      {values.map(v => <Box ml={2} key={v}><FileView onDelete={onDelete} src={v} /></Box>)}
    </Box>
    {values.length === 0 && (<Box color="#666" flexDirection="column" alignItems="center" justifyContent="center" display="flex" fontSize={64}>
      <NoFilesIcon fontSize="inherit" color="inherit" />
      <Box fontSize={16} mt={2}>
        {t('no-files')}
      </Box>
    </Box>)}
    <Box display="flex" justifyContent="flex-end" mt={2}>
      <Button variant="outlined" onClick={() => setUploadDialogOpened(true)}>
        {t('add-file')}
      </Button>
    </Box>
    <FileUploadDialog
      onClose={handleClose}
      isSingleFile={false}
      onUpload={onUpload}
      opened={uploadDialogOpened}
    />
  </Box>
}

const useStyles = makeStyles<Theme>((theme) => ({
  download: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background: 'rgba(255,255,255,0.8)',
    opacity: 0,
    borderRadius: 12,
    position: 'absolute',
    transitionDuration: '200ms',
    left: 0,
    top: 0,
    color: '#666',
    zIndex: 2,
    fontSize: 48,
    ['&:hover']: {
      opacity: 1,
    }
  }
}));


export const FileView: React.FC<{ src: string, onDelete: (src: string) => void }> = ({ src, onDelete }) => {
  const icon = src.includes('pdf') ? <PdfIcon fontSize="large" /> : src.includes('png') || src.includes('jpg') || src.includes('jpeg') ? <PhotoIcon fontSize="large" /> : <FileIcon fontSize="large" />
  const classes = useStyles();
  const del = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Czy jesteś pewien że chcesz usunąć plik?")) {
      onDelete(src);
    }
  }, [src, onDelete]);
  return <a target="_blank" rel="noopener noreferrer" href={src}>
    <Box display="flex" style={{ background: '#fff' }} borderRadius={8} border={dashedBorder} flexDirection="column" alignItems="center" justifyContent="center" position="relative" p={4}>
      {icon}
      <Typography variant="caption" color="textSecondary" align="center">
        <Box maxWidth={90} whiteSpace="pre-wrap" style={{ wordBreak: "break-word" }} maxHeight={45} textAlign="center" fontWeight={900} mt={1}>{src.split('/').pop()}</Box>
      </Typography>
      <Box zIndex={3} position="absolute" right={4} top={4}>
        <IconButton onClick={del} size="small">
          <RemoveIcon />
        </IconButton>
      </Box>
      <div className={classes.download}>
        <DownloadIcon fontSize="inherit" />
      </div>
    </Box>
  </a>
}
