import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { FormInput } from "components/shared/form-input";
import { FieldsFormProperties } from 'features/engine/models';
import { FieldError, get } from 'react-hook-form';
import { DeepMap } from 'react-hook-form/dist/types/utils';
import { app } from 'application';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { PriceInputType } from 'shared/build/inputs';
import { camelToKebabCase } from 'features/forms/models';
import { FrontendField, getFieldNames } from 'features/engine/field';

export const PriceForm: React.FC<{ field: FrontendField, fieldProperties?: FieldsFormProperties, control: any, errors: DeepMap<any, FieldError>, inputConfig: PriceInputType, touched?: DeepMap<any, boolean> }> = ({
  field,
  fieldProperties,
  control,
  errors,
  inputConfig,
  touched
}) => {
  const { props, builder } = inputConfig;
  const { t } = useTranslation();
  const fields = getFieldNames(builder, field.name);
  const amountName = fields.find(f => f.includes('amount'))!;
  const currencyName = fields.find(f => f.includes('currency'))!;
  useEffect(() => {
    return () => {
      control.setValue(amountName.split('.').slice(0, -1).join(''), undefined)
    }
  });
  const currencyEnumValues = app.enums.find(e => e.name === (props.currencyEnumName || 'currency'));
  const onlyOneValue = currencyEnumValues?.values.length === 1;
  return (
    <Box>
      <Box mb={0.5}><Typography variant="body2"><Box component="span" fontWeight="600">{t(camelToKebabCase(field.name))}</Box></Typography></Box>
      <Box width="100%" display="flex" alignItems="flex-start">
        <Box flexGrow={1} mr={2}>
          <FormInput
            type="number"
            fullWidth={true}
            label={t('amount')}
            margin="dense"
            controllerProps={{
              defaultValue: fieldProperties?.value?.amount || get(control.getValues(), amountName) || '',
              control,
              name: amountName,
              rules: fieldProperties ? { ...(fieldProperties.rules || {}), required: true } : { required: true }
            }}
            touched={touched}
            errors={get(errors as any, amountName) as any}
            formater={{
              parse: (v: any) => Number(v) / 100,
              format: (v: any) => Math.round(Number(v) * 100),
            }}
          />
        </Box>
        <Box width={onlyOneValue ? 0 : 'auto'} height={onlyOneValue ? 0 : 'auto'} overflow="hidden" minWidth={!onlyOneValue ? 110 : 0}>
          <FormInput
            type="text"
            fullWidth={true}
            select={true}
            label={t('currency')}
            margin="dense"
            controllerProps={{
              defaultValue: onlyOneValue ? currencyEnumValues?.values[0] : (fieldProperties?.value?.currency || ''),
              control,
              name: currencyName,
              rules: fieldProperties ? { ...(fieldProperties.rules || {}), required: true } : { required: true }
            }}
            errors={get(errors as any, currencyName) as any}
          >
            {app.enums.find(e => e.name === (props.currencyEnumName || 'currency'))?.values.map((currency) => (
              <MenuItem value={currency} key={currency}>
                {t(currency)}
              </MenuItem>
            ))}
          </FormInput>
        </Box>
      </Box>
    </Box>
  );
};
