import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

interface InputProps {
  loaderDelay?: number;
}

const PageLoader: React.FC<InputProps> = ({ loaderDelay = 0 }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, loaderDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [loaderDelay]);
  if (!show) {
    return null;
  }

  return (
    <Box
      minHeight="30vh"
      width="100%"
      flexDirection="column"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={64} />
    </Box>
  );
};

export default PageLoader;
