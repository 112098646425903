import React, { Suspense } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './features/mui/theme';
import { Layout } from './components/shared/layout';
import { AnimatePresence } from 'framer-motion';
import { Switch, Route } from 'react-router-dom';
import RequireAuth from 'components/auth/require-auth';
import CssBaseline from '@material-ui/core/CssBaseline';
import './i18n';
import { app } from 'application';
import { createPage } from 'features/engine/create-pages';
import LoaderOverlay from 'components/shared/loader-overlay';
import { ErrorDialog } from 'components/shared/error-dialog';
import { Chat } from 'features/chat';
import SignIn from 'pages/sign-in';
import PageLoader from 'components/shared/page-loader';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Layout>
        <Suspense fallback={<PageLoader />}>
          <AnimatePresence exitBeforeEnter>
            <Switch>
              {app.authorization && (<>
                <Route exact path={'/sign-in'}>
                  {<SignIn />}
                </Route>
                <RequireAuth>
                  {app.pages.map(p => createPage(p, app))}
                </RequireAuth>
              </>)}
              {!app.authorization && <>{app.pages.map(p => createPage(p, app))}</>}
            </Switch>
          </AnimatePresence>
        </Suspense>
      </Layout>
      <Chat />
      <ErrorDialog />
      <LoaderOverlay />
    </ThemeProvider>
  );
}

export default React.memo(App);
