import 'isomorphic-unfetch';
import { ApiError, queryParamsObjectToQueryString, statusToMessage } from './models';
import Cookies from 'universal-cookie';

export function apiGet(
  path: string,
  queryObject?: { [key: string]: number | string } | any,
  token?: string
): Promise<any> {
  const addressWithQuery = queryObject
    ? path + queryParamsObjectToQueryString(queryObject)
    : path;
  return apiQuery(addressWithQuery, undefined, 'GET', token);
}

export function apiPost<T = any>(
  path: string,
  body: object | undefined,
  token?: string,
  parse = true
): Promise<any> {
  return apiQuery(path, body, 'POST', token, parse);
}

export function apiPut(
  path: string,
  body: object | undefined,
  token?: string,
  parse = true
): Promise<any> {
  return apiQuery(path, body, 'PUT', token, parse);
}

export function apiDelete(
  path: string,
  token?: string,
  parse = true
): Promise<any> {
  return apiQuery(path, undefined, 'DELETE', token, parse);
}

async function apiQuery(
  path: string,
  body: object | undefined,
  method: 'POST' | 'GET' | 'PUT' | 'DELETE',
  inputToken?: string,
  parse = true
): Promise<any> {
  const token = inputToken || new Cookies().get('token');
  try {
    const headers = Object.assign(
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Cache: 'no-cache'
      },
      token
        ? {
          Authorization: `Bearer ${token}`,
          Cookie: `token=${token}`
        }
        : {
          Authorization: '',
          Cookie: ''
        }
    );
    const response = body
      ? await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
        body: JSON.stringify(body),
        headers,
        method,
      })
      : await fetch(`${process.env.REACT_APP_API_URL}/${path}`, {
        headers,
        method,
      });
    if (parse) {
      const json = await response.json();
      if ((response.status < 200 || response.status >= 300) && json.detail) {
        throw {
          status: response.status,
          message: json.detail
        };
      } else if (response.status < 200 || response.status >= 300) {
        throw {
          status: response.status,
          message: statusToMessage(response.status)
        };
      }
      return json;
    } else {
      if (response.status < 200 && response.status >= 300) {
        const jsonError = await response.json();
        throw {
          status: response.status,
          message: jsonError.detail ||  statusToMessage(response.status)
        };
      }
      return;
    }
  } catch (error) {
    if (error.status) {
      throw {
        status: error.status,
        message: error.message
      };
    } else {
      throw {
        status: 500,
        message: ApiError.UNRECOGNIZED_ERROR
      };
    }
  }
}
