
import { FieldsFormProperties } from './models';
import { Constraint, Guard, Validator } from 'shared';
import { $ } from 'dollarscript';
import { Context, run } from 'dollarscript/build/interpreter';
import { get, isArray, keys, uniq } from 'lodash';

type AppObject = Record<string, any>;

export function getAllowForEntityAction<U extends AppObject = AppObject, E extends AppObject = AppObject>(
  guard?: $<Guard>, currentUser?: U, currentEntity?: E, previousEntity?: E
): boolean {
  return (getGuardForEntityAction(guard, currentUser, currentEntity, previousEntity) || { allow: false }).allow;
}

export function getGuardForEntityAction<U extends AppObject = AppObject, E extends AppObject = AppObject>(
  guard?: $<Guard>, currentUser?: U, currentEntity?: E, previousEntity?: E
): Guard | undefined {
  if (!guard) return undefined
  const runnedGuard = run({ currentUser, currentEntity, previousEntity })(guard)
  return runnedGuard;
}

export function canMakeEntityAction(guard?: $<Guard>, currentUser?: Record<string, any>, currentEntity?: Record<string, any>) {
  return !!getAllowForEntityAction(guard, currentUser, currentEntity);
}


export function mergeFormProperties(formProperties: FieldsFormProperties[]): FieldsFormProperties {
  return formProperties.reduce((acc, v) => {
    return {
      disabled: v.disabled || acc.disabled,
      value: mergeFieldsFormPropertiesValues(acc.value, v.value),
      rules: { ...(acc.rules || {}), ...(v.rules || {}) },
      nullable: v.nullable === false || acc.nullable === false ? false : true
    }
  }, {})
}

function mergeFieldsFormPropertiesValues<T>(value1?: T | T[], value2?: T | T[]) {
  if (isArray(value1) && isArray(value2)) {
    return uniq(value1.filter((v) => value2.includes(v)).concat(value2.filter((v) => value1.includes(v))));
  } else if (isArray(value1) && value2 && !isArray(value2)) {
    return value2;
  } else if (isArray(value2) && value1 && !isArray(value1)) {
    return value1;
  } else {
    return value1 || value2;
  }
}

export function validatorToFormProperties(inputConstraint: Validator, context: Context, nullable?: boolean): FieldsFormProperties {
  const constraint = run(context)(inputConstraint);
  const nullableFormProperties: FieldsFormProperties = nullable === true ? { nullable: true } : { rules: { required: true }, nullable: false };
  let formPropertiesFromConstraint = {};
  if (constraint.type === 'in') {
    formPropertiesFromConstraint = { value: constraint.value }
  } else if (constraint.type === 'and') {
    formPropertiesFromConstraint = mergeFormProperties([...constraint.value.map(validatorToFormProperties)])
  } else if (constraint.type === 'eq') {
    formPropertiesFromConstraint = { value: constraint.value, disabled: true };
  } else if (constraint.type === 'gt') {
    formPropertiesFromConstraint = {
      rules: {
        min: Number(constraint.value) + 0.001
      }
    };
  } else if (constraint.type === 'gte') {
    formPropertiesFromConstraint = {
      rules: {
        min: Number(constraint.value)
      }
    };
  } else if (constraint.type === 'lt') {
    formPropertiesFromConstraint = {
      rules: {
        max: Number(constraint.value) - 0.001
      }
    };
  } else if (constraint.type === 'lte') {
    formPropertiesFromConstraint = {
      rules: {
        max: Number(constraint.value)
      }
    };
  } else if (constraint.type === 'neq') {
    formPropertiesFromConstraint = {
      rules: {
        validate: (data: any) => !constraint.value ?
          validateNotNullableField(data) :
          data !== constraint.value
      },
      nullable: !constraint.value ? false : nullable
    };
  }
  return mergeFormProperties([nullableFormProperties, formPropertiesFromConstraint]);
}

export const validateNotNullableField = (data: any) => !!data ||
  typeof data === 'number' ||
  typeof data === 'boolean';

export function validateEntity(constraint: Constraint, item: any): boolean {
  for (const key in constraint) {
    const validator = constraint[key];
    if (!validateValue(validator, get(item, key))) return false;
  }
  return true;
}

export function validateNumber(a: any, b: any, validate: (a: number, b: number) => boolean): boolean {
  return validate(Number(a), Number(b));
}

export function validateValue(validator: Validator, value: any): boolean {
  switch (validator.type) {
    case 'eq':
      return value === validator.value;
    case 'and':
      return validator.value.every(c => validateValue(c, value));
    case 'in':
      return validator.value.map(v => v).includes(value);
    case 'neq':
      return value !== validator.value;
    case 'lt':
      return validateNumber(value, validator.value, (a, b) => a < b);
    case 'lte':
      return validateNumber(value, validator.value, (a, b) => a <= b);
    case 'gt':
      return validateNumber(value, validator.value, (a, b) => a > b);
    case 'gte':
      return validateNumber(value, validator.value, (a, b) => a >= b);
  }
}
