import { EntityField, Spec, ManyToOneField, Validator, Guard, SendEmailAction } from "shared";
import { FormProperties, FieldsFormProperties, FormFieldType, FormSelectValues, FrontendApp } from "./models";
import { Controller, FieldError } from "react-hook-form";
import { DeepMap } from "react-hook-form/dist/types/utils";
import { UnpackNestedValue } from "react-hook-form/dist/types/form";
import { mergeFormProperties, getGuardForEntityAction, validatorToFormProperties, validateNotNullableField } from "./filters";
import Box from "@material-ui/core/Box";
import React, { useEffect, useState } from "react";
import { camelToKebabCase } from "features/forms/models";
import isArray from "lodash/isArray";
import { FormInput } from "components/shared/form-input";
import MenuItem from "@material-ui/core/MenuItem";
import { useRecoilValue } from "recoil";
import { FrontendEntity } from "./entity";
import { FormCheckbox } from "components/shared/form-checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import { GoogleMapsAutocompleter } from "components/forms/google-maps";
import { PriceForm } from "components/forms/price";
import { Builder } from "shared/build/inputs";
import { flatten, get, kebabCase, keys } from "lodash";
import { NullableFieldWrapper } from "components/forms/nullable-field";
import { RangeInput } from "components/forms/form-range-input";
import { FormEmailInput } from "components/shared/form-email";
import { Collapse, Divider, Typography } from "@material-ui/core";
import { SavedEntitySelect } from "components/forms/saved-entity";
import Button from "@material-ui/core/Button/Button";
import { DateRangeInput } from "components/forms/form-date-range-input";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import { FormPostCodeAddress } from "components/forms/form-post-code-address";
import { FileUploadField } from "components/forms/file-upload";

export class FrontendField<T = any> {
  filter?: true | 'eq' | 'startsWith' | 'includes';
  nullable?: boolean;
  validator?: Validator;
  constructor(
    public specField: EntityField,
    public name: string,
    public type: FormFieldType,
    public forms?: FormProperties<T>,
  ) {
    this.filter = specField.filter;
    this.nullable = specField.nullable;
    this.validator = (specField as any).validator;
  }
  getFormField(
    app: FrontendApp,
    control: any,
    errors: DeepMap<T, FieldError>,
    values: UnpackNestedValue<T>,
    t: (str: string) => string,
    entity?: FrontendEntity,
    editedItem?: any,
    currentUser?: Record<string, any>,
    action?: 'create' | 'update',
    forcedName?: string,
    additionalGuard?: Guard,
    relatedEntity?: FrontendEntity,
    notNullable?: boolean,
    alwaysOnTop?: boolean,
    last?: boolean,
    touched?: DeepMap<any, boolean>,
    arrayOptions?: SendEmailAction['options']
  ): React.ReactNode | React.ReactNode[] {
    const name = forcedName || this.name;
    if (this.specField.type === 'autogenerated-int' || this.specField.value) return '';
    const initialValue = editedItem && get(editedItem, name);
    let fieldProperties: FieldsFormProperties = getRulesFromEntityRules(this, entity, action, editedItem, currentUser, name, values);
    if (this.specField.type !== 'embedded' && this.specField.type !== 'many-to-one' && this.specField.type !== 'enum' && this.validator) {
      const validatorFormProperties = validatorToFormProperties(this.validator, { currentUser, editedItem, previousEntity: values as any }, this.nullable);
      fieldProperties = mergeFormProperties([
        fieldProperties,
        validatorFormProperties,
        ...(this.forms ? [this.forms] : [])
      ]);
    }
    if (additionalGuard && additionalGuard.constraint && additionalGuard.constraint[this.name]) {
      fieldProperties = mergeFormProperties([
        validatorToFormProperties(additionalGuard.constraint[this.name], { currentEntity: editedItem, currentUser }, this.nullable)
      ]);
    }
    fieldProperties = { ...fieldProperties, rules: { ...(fieldProperties.rules || {}), required: notNullable || !fieldProperties.nullable }, nullable: notNullable ? false : fieldProperties.nullable, fieldValue: initialValue }
    if (last) {
      useEffect(() => {
        setTimeout(() => control.trigger(name), 500);
        setTimeout(() => control.trigger(name), 1000);
      }, []);
    }
    if (!this.forms) {
      const embeddedEntity = app.entities.find(e => this.specField.type === 'embedded' && e.specEntity.name === this.specField.entity)!
      if (embeddedEntity.specEntity.input) {
        let field;
        if (embeddedEntity.specEntity.input.type === 'google-maps') {
          field = <Box ><GoogleMapsAutocompleter field={this} fieldProperties={fieldProperties} control={control} inputConfig={embeddedEntity.specEntity.input} /></Box>
        } else if (embeddedEntity.specEntity.input.type === 'post-codes') {
          field = <Box ><FormPostCodeAddress field={this} name={name} fieldProperties={fieldProperties} control={control} inputConfig={embeddedEntity.specEntity.input} initialValue={editedItem} /></Box>
        } else if (embeddedEntity.specEntity.input.type === 'file-upload') {
          field = <Box ><FileUploadField name={name} control={control} initialValue={editedItem} /></Box>
        } else if (embeddedEntity.specEntity.input.type === 'price') {
          field = <Box ><PriceForm touched={touched} field={this} fieldProperties={fieldProperties} control={control} errors={errors} inputConfig={embeddedEntity.specEntity.input} /></Box>
        } else if (embeddedEntity.specEntity.input.type === 'range') {
          field = <Box ><RangeInput field={this} fieldProperties={fieldProperties} control={control} inputConfig={embeddedEntity.specEntity.input} /></Box>
        } else if (embeddedEntity.specEntity.input.type === 'saved-entity') {
          field = <Box ><SavedEntitySelect field={{ ...this, name: name }} fieldProperties={fieldProperties} inputConfig={embeddedEntity.specEntity.input} /></Box>
        } else if (embeddedEntity.specEntity.input.type === 'date-range') {
          field = <Box ><DateRangeInput isEdit={!!editedItem} field={{ ...this, name: name }} fieldProperties={fieldProperties} inputConfig={embeddedEntity.specEntity.input} control={control} errors={errors} /></Box>
        }
        if (fieldProperties.nullable && !alwaysOnTop) {
          return <React.Fragment key={this.name}><NullableFieldWrapper disabled={!!fieldProperties.disabled} value={fieldProperties.fieldValue} label={t(camelToKebabCase(this.name))}>{field}</NullableFieldWrapper></React.Fragment>
        } else {
          return <React.Fragment key={this.name}>{field}</React.Fragment>
        }
      }
      const field = (
        <Box>
          <Box my={2}>
            <Divider />
          </Box>
          <Typography><Box fontWeight={900}>{t(camelToKebabCase(this.name))}</Box></Typography>
          {embeddedEntity.fields.map(ef =>
            ef.getFormField(
              app,
              control as any,
              errors as any,
              values as any,
              t,
              entity,
              editedItem,
              currentUser,
              action,
              `${name}.${ef.name}`,
              additionalGuard,
              undefined,
              undefined,
              undefined,
              undefined,
              touched
            )
          )}
          <Box my={2}>
            <Divider />
          </Box>
        </Box>
      );
      if (fieldProperties.nullable && !alwaysOnTop) {
        return <React.Fragment key={this.name}><NullableFieldWrapper disabled={!!fieldProperties.disabled} value={fieldProperties.fieldValue} label={t(camelToKebabCase(this.name))}>{field}</NullableFieldWrapper></React.Fragment>
      } else {
        return <React.Fragment key={this.name}>{field}</React.Fragment>
      }
    }
    const endAdornment = this.specField.unit && <InputAdornment position="end">{this.specField.unit}</InputAdornment>
    switch (this.type) {
      case 'datetime-local':
      case 'number':
      case 'password':
      case 'text': {
        if ((this.forms!.type === 'password' && editedItem) || (fieldProperties.disabled && !fieldProperties.value)) {
          return '';
        }
        let field;
        if ((this.specField as any).enum) {
          const filter = createFilterOptions<string>();
          field = <>
            <Controller
              render={
                ({ onChange, value }) => {
                  return <Autocomplete<any>
                    options={(this.forms?.values?.map((v) => v.value) || [])}
                    getOptionLabel={(option) => t(option)}
                    noOptionsText={t('no-options')}
                    value={value}
                    onChange={(_e, v) => {
                      onChange(v)
                    }}
                    autoHighlight
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      // Suggest the creation of a new value
                      if (params.inputValue !== '') {
                        filtered.push(params.inputValue);
                      }

                      return filtered;
                    }}
                    onKeyPress={e => {
                      if (e.which === 13 || e.keyCode === 13 || e.key === 'Enter') {
                        e.preventDefault();
                      }
                    }}
                    selectOnFocus
                    // autoSelect
                    // clearOnBlur
                    freeSolo={true as any}
                    renderInput={(params) => <TextField error={get(errors, name)} name={name} {...params} margin="dense" label={t(camelToKebabCase(this.name))} variant="outlined" />}

                  />
                }
              }
              control={control}
              rules={{ ...this.forms!.rules, ...(fieldProperties.rules || {}), ...(alwaysOnTop ? { required: false, validate: undefined } : {}) }}
              defaultValue={fieldProperties.fieldValue || (!isArray(fieldProperties.value) && fieldProperties.value) || initialValue || this.forms!.initialValue!}
              name={name}
            />

          </>
        } else {
          field = (
            <Box style={!!fieldProperties.disabled ? { display: 'none' } : {}} key={this.name} >
              <FormInput
                type={this.forms!.type}
                fullWidth={true}
                select={isArray(fieldProperties.value)}
                label={t(camelToKebabCase(this.name))}
                InputProps={{ endAdornment }}
                margin="dense"
                touched={touched}
                disabled={!!fieldProperties.disabled}
                controllerProps={{
                  defaultValue: fieldProperties.fieldValue || (!isArray(fieldProperties.value) && fieldProperties.value) || initialValue || this.forms!.initialValue!,
                  control,
                  name: name,
                  rules: { ...this.forms!.rules, ...(fieldProperties.rules || {}), ...(alwaysOnTop ? { required: false, validate: undefined } : {}) }
                }}
                errors={(errors as any)[this.name] as any}
                InputLabelProps={this.type === 'datetime-local' ? {
                  shrink: true,
                } : {}}
                formater={this.type === 'datetime-local' ? {
                  parse: (v) => {
                    return new Date(Number(v)).getTime() ? getDateString(new Date((Number(v)))) : undefined
                  },
                  format: (v) => { return new Date(v).getTime() }
                } : undefined}
              >
                {isArray(fieldProperties.value) ? fieldProperties.value.map((v) => (<MenuItem key={v} value={v}>{t(v)}</MenuItem>)) : <></>}
              </FormInput>
            </Box >
          );
        }
        if (fieldProperties.nullable && !alwaysOnTop) {
          return <React.Fragment key={this.name}><NullableFieldWrapper disabled={!!fieldProperties.disabled} value={fieldProperties.fieldValue} label={t(camelToKebabCase(this.name))}>{field}</NullableFieldWrapper></React.Fragment>
        } else {
          return <React.Fragment key={this.name}>{field}</React.Fragment>
        }
      }
      case 'checkbox': {
        return (<Collapse key={this.name} in={!fieldProperties.disabled}><Box >
          <FormCheckbox
            label={t(camelToKebabCase(this.name))}
            disabled={!!fieldProperties.disabled}
            controllerProps={{
              defaultValue: Boolean(initialValue || this.forms!.initialValue!),
              control,
              name: name,
              rules: { ...this.forms!.rules, ...(fieldProperties.rules || {}), required: false }
            }}
            errors={(errors as any)[this.name] as any}
          />
        </Box>
        </Collapse>);
      }
      case 'array': {
        const field = (<Box key={this.name} >
          <FormEmailInput
            label={t(camelToKebabCase(this.name))}
            controllerProps={{
              defaultValue: initialValue || this.forms!.initialValue! || [],
              control,
              name: name,
              rules: { ...this.forms!.rules, ...(fieldProperties.rules || {}) }
            }}
            errors={(errors as any)[this.name] as any}
            options={arrayOptions}
          />
        </Box>);
        if (fieldProperties.nullable && !alwaysOnTop) {
          return <React.Fragment key={this.name}><NullableFieldWrapper disabled={!!fieldProperties.disabled} value={fieldProperties.fieldValue} label={t(camelToKebabCase(this.name))}>{field}</NullableFieldWrapper></React.Fragment>
        } else {
          return <React.Fragment key={this.name}>{field}</React.Fragment>
        }
      }
      case 'entity-array': {
        const value: any = get((values as any), name) || get((editedItem as any), name) || [];
        const minLength = (this.specField as any).minLength || 0
        const [arrayLength, setArrayLength] = useState(value.length > minLength ? value.length : minLength);
        const embeddedEntity = app.entities.find(e => e.specEntity.name === (this.specField as any).entity)!
        return (<Box p={2} my={1} border="dashed 2px rgba(0,0,0,0.13)" borderRadius={8} style={{ background: '#fafafa' }}>
          {/* <Box maxHeight={0} overflow="hidden" maxWidth={0}>
            <Controller control={control} defaultValue={value} name={(name) as any} />
          </Box> */}
          {
            Array(arrayLength).fill(undefined).map((_v, i) => {
              return (<><Box key={`${name}[${i}]`} >
                {embeddedEntity.fields.map(f => f.getFormField(
                  app,
                  control as any,
                  errors as any,
                  values as any,
                  t,
                  entity,
                  editedItem,
                  currentUser,
                  action,
                  `${name}[${i}].${f.name}`,
                  additionalGuard,
                  undefined,
                  undefined,
                  undefined,
                  undefined,
                  touched
                ))}
              </Box>
                {minLength < arrayLength && arrayLength - 1 === i && (<Box>
                  <Button variant="outlined" onClick={() => {
                    setArrayLength(arrayLength - 1);
                    setTimeout(control.setValue(name, (get(values, name) || []).slice(0, arrayLength - 1)), 100);
                  }}>   {t('delete')}</Button>
                </Box>)}
                {arrayLength - 1 > i && (<Box py={1.5}>
                  <Divider />
                </Box>)}
              </>)
            })}
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={() => setArrayLength(arrayLength + 1)} variant="contained" color="primary">{t(kebabCase(`add-next-${this.name}`))}</Button>
          </Box>
        </Box>)
      }
      case 'select': {
        if (this.specField.type === 'many-to-one') {
          const entityName = (this.specField as ManyToOneField).entity;
          const displayNone = !action || !!this.specField.nullable;
          const fieldEntity = app.entities.find(e => e.specEntity.name === entityName)!;
          const state = fieldEntity.state()!;
          const items: any[] = (!alwaysOnTop ? useRecoilValue(state.list)[fieldEntity.pluralName] : useRecoilValue(app.states[entityName].getSelector)).filter((i: any) => !isArray(fieldProperties.value) || fieldProperties.value.includes(i.id));
          // useEffect(() => {
          //   if (!relatedEntity || relatedEntity.name !== fieldEntity.name && !alwaysOnTop) {
          //     state.read()
          //   }
          // }, []);
          const names = items.reduce((acc, v) => ({ ...acc, [v.id]: get(v, fieldEntity.specEntity.titleField || '') }), {});
          const options = [
            ...items.map((i) => i.id),
            ...(displayNone ? [undefined] : [])
          ];
          const field = (
            <Box style={!!fieldProperties.disabled ? { display: 'none' } : {}} key={name}>
              <Controller
                render={
                  ({ onChange, value }) => {
                    return <Autocomplete<number>
                      options={options}
                      getOptionLabel={(option) => option ? names[option] : t('none')}
                      noOptionsText={t('no-options')}
                      value={value}
                      onChange={(_e, v) => {
                        onChange(v);
                        control.trigger(name);
                      }}
                      autoHighlight
                      renderInput={(params) => <TextField error={get(errors, name)} name={name} {...params} margin="dense" label={t(camelToKebabCase(this.name))} variant="outlined" />}
                      onKeyPress={e => {
                        if (e.which === 13 || e.keyCode === 13 || e.key === 'Enter') {
                          e.preventDefault();
                        }
                      }}
                      selectOnFocus
                    />
                  }
                }
                control={control}
                defaultValue={(editedItem && get(editedItem, name)) || (!isArray(fieldProperties.value) && fieldProperties.value) || this.forms!.initialValue || fieldProperties.value || undefined}
                name={name}
                rules={{ ...this.forms!.rules, ...(fieldProperties.rules || {}), ...(alwaysOnTop ? { required: false, validate: undefined } : {}) }}
              />
              {/* <Controller control={control} defaultValue={(editedItem && get(editedItem, name)) || (!isArray(fieldProperties.value) && fieldProperties.value) || this.forms!.initialValue || fieldProperties.value} name={name} />
              <Autocomplete<number>
                options={options}
                getOptionLabel={(option) => option ? names[option] : t('none')}
                noOptionsText={t('no-options')}
                value={get(values, name) || (editedItem && get(editedItem, name)) || fieldProperties.value || ''}
                onChange={(_e, v) => {
                  control.setValue(name, v)
                }}
                renderInput={(params) => <TextField name={name} {...params} margin="dense" label={t(camelToKebabCase(this.name))} variant="outlined" />}
              /> */}
            </Box>
          );
          if (fieldProperties.nullable && !fieldProperties.disabled && !alwaysOnTop) {
            return <React.Fragment key={this.name}><NullableFieldWrapper disabled={!!fieldProperties.disabled} value={fieldProperties.fieldValue} label={t(camelToKebabCase(this.name))}>{field}</NullableFieldWrapper></React.Fragment>;
          } else {
            return <React.Fragment key={this.name}>{field}</React.Fragment>;
          }
        } else {
          const field = (
            <Box display={typeof fieldProperties.value === 'string' || typeof fieldProperties.value === 'number' ? 'none' : 'block'} key={this.name} >
              <FormInput
                type="text"
                fullWidth={true}
                label={t(camelToKebabCase(this.name))}
                margin="dense"
                InputProps={{ endAdornment }}
                select={true}
                touched={touched}
                controllerProps={{
                  defaultValue: !action ? '' : (isArray(fieldProperties.value) ? '' : fieldProperties.value) || initialValue || this.forms!.initialValue,
                  control,
                  name: name,
                  rules: { ...this.forms!.rules, ...(fieldProperties.rules || {}), ...(alwaysOnTop ? { required: false, validate: undefined } : {}) }
                }}
                errors={(errors as any)[this.name] as any}
              >
                {!action && (
                  <MenuItem value={''} key={`${this.name}-undefined`}>
                    {t('none')}
                  </MenuItem>
                )}
                {(isArray(fieldProperties.value) ? fieldProperties.value : (this.forms!.values || [])).map(v => (
                  <MenuItem value={typeof v === 'string' ? v : v.value} key={typeof v === 'string' ? v : v.label + String(v)}>
                    {t(typeof v === 'string' ? v : v.label)}
                  </MenuItem>
                ))}
              </FormInput>
            </Box>
          );
          if (fieldProperties.nullable && !alwaysOnTop) {
            return <React.Fragment key={this.name}><NullableFieldWrapper disabled={!!fieldProperties.disabled} value={fieldProperties.fieldValue} label={t(camelToKebabCase(this.name))}>{field}</NullableFieldWrapper></React.Fragment>
          } else {
            return <React.Fragment key={this.name}>{field}</React.Fragment>;
          }
        }
      }
      default: {
        return <></>;
      }
    }
  }
}

export function convertField(field: EntityField, spec: Spec): FrontendField {
  let fieldType: FormFieldType;
  let initialValue;
  let values: FormSelectValues | undefined;
  if (field.type === 'boolean') {
    fieldType = 'checkbox';
    initialValue = false;
  } else if (field.type === 'int' || field.type === 'float' || field.type === 'autogenerated-int') {
    fieldType = 'number';
    initialValue = 0;
  } else if (field.type === 'string') {
    fieldType = 'text';
    values = (field as any).enum ? (spec.enums.find((e) => e.name === (field as any).enum)!.values.map((v) => ({ label: v, value: v }))) : undefined;
  } else if (field.type === 'enum' || field.type === 'many-to-one') {
    fieldType = 'select';
    initialValue = field.type === 'many-to-one' ? undefined : (spec.enums.find((e) => e.name === field.enum)!.values[0] || '');
    values = field.type === 'enum' ? (spec.enums.find((e) => e.name === field.enum)!.values.map((v) => ({ label: v, value: v }))) : undefined;
  } else if (field.type === 'timestamp') {
    fieldType = 'datetime-local'
    initialValue = Date.now();
  } else if (field.type === 'array') {
    fieldType = field.itemType === 'string' ? 'array' : 'entity-array';
    initialValue = []
  } else {
    fieldType = 'password'
    initialValue = ''
  }
  const name = field.type === 'many-to-one' ? `${field.name}Id` : field.name;
  return new FrontendField(
    field,
    name,
    fieldType,
    (field.type === 'embedded') ? undefined : {
      type: fieldType,
      initialValue,
      values
    },
  )
}


function getRulesFromEntityRules<T = any>(formField: FrontendField<T>, entity?: FrontendEntity, action?: 'create' | 'update', editedItem?: any, currentUser?: Record<string, any>, name?: string, currentEntity?: any) {
  const guard = entity && entity.specEntity.controller && action && getGuardForEntityAction(entity.specEntity.controller[action], currentUser, currentEntity, editedItem);
  let fieldProperties: FieldsFormProperties = formField.nullable === true ? { nullable: true } : { rules: { validate: validateNotNullableField }, nullable: false };
  if (guard && guard.constraint && guard.constraint[formField.name]) {
    fieldProperties = mergeFormProperties([
      fieldProperties,
      validatorToFormProperties(guard.constraint[formField.name], { currentUser, currentEntity: editedItem || currentEntity }, formField.nullable)
    ]);
    if (formField.validator) {
      fieldProperties = mergeFormProperties([
        fieldProperties,
        validatorToFormProperties(formField.validator, { currentUser, currentEntity: editedItem || currentEntity }, formField.nullable)
      ]);
    }
  }
  fieldProperties = { ...fieldProperties, value: fieldProperties.value, fieldValue: action === 'update' && get(editedItem, name || '') || '' }
  return fieldProperties;
}

export function getFieldNames(builder: Builder, prefix: string): string[] {
  return flatten(keys(builder).map((key) => {
    if (builder[key]!.$) {
      return [`${prefix}.${key}`];
    } else {
      return getFieldNames(builder[key] as Builder, `${prefix}.${key}`)
    }
  }))
};

export function getDateString(date: Date): string {
  const hours = dateNumberToString(date.getHours()) + ':' + dateNumberToString(date.getMinutes());
  return `${date.getFullYear()
    }-${dateNumberToString(date.getMonth() + 1)
    }-${dateNumberToString(date.getDate())
    }T` + hours;
}

export function getDateStringToDisplay(date: Date): string {
  return getDateString(date).replace('T', ' ')
}

function dateNumberToString(dateNumber: number): string {
  return `0${dateNumber}`.slice(-2);
}
