export enum ApiError {
  UNRECOGNIZED_ERROR = 'unrecognized',
  UNAUTHORIZED = 'unauthorized',
  FORBIDDEN = 'forbidden',
  NOT_FOUND = 'not-found',
  BAD_REQUEST = 'bad-request',
}

export function statusToMessage(status: number) { 
  switch(status) {
    case 400: return ApiError.BAD_REQUEST 
    case 401: return ApiError.UNAUTHORIZED 
    case 403: return ApiError.FORBIDDEN
    case 404: return ApiError.NOT_FOUND
    default: return ApiError.UNRECOGNIZED_ERROR
  } 
}

export interface RequestError {
  status: number;
  message: ApiError;
}

export function queryParamsObjectToQueryString(queryObject: { [key: string]: any }): string {
  const queryObjectKeys = Object.keys(queryObject);
  return queryObjectKeys.reduce((acc, key, i) => {
    acc += `${key}=${
      typeof queryObject[key] !== 'string' ? JSON.stringify(queryObject[key]) : queryObject[key]
    }${i !== queryObjectKeys.length - 1 ? '&' : ''}`;
    return acc;
  }, '?');
}
