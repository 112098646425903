import { createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

export const border = 'solid 1px rgba(0,0,0,0.13)';
export const dashedBorder = 'dashed 2px rgba(0,0,0,0.13)';

// Create a theme instance.
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Mulish, sans-serif',
    // fontSize: 13,
    // lineHeight: 13,
    // body1: {
    //   fontSize: '0.75rem',
    // },
    // body2: {
    //   fontSize: '0.75rem',
    // },
  },
  // spacing: 6,
  palette: {
    primary: {
      main: '#1723E5',
      light: '#4C55F4',
      dark: '#12176A',
    },
    secondary: {
      main: '#09F383',
      light: '#49F9A5',
      dark: `#0BB463`,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          textDecoration: 'none',
          color: 'inherit',
        },
        ['.email-addresses input']: {
          padding: '12px 16px',
          fontSize: '15px',
          width: '100%',
          border: 'solid 1px rgba(0,0,0,0.3)',
          marginTop: 12,
          borderRadius: 8,
        },
        '.fadeIn': {
          animation: 'fadeIn 500ms '
        },
        '@keyframes fadeIn': {
          from: { opacity: 0 },
          to: {
            opacity: 1,
          },
        },
      }
    },
    MuiButton: {
      root: {
        borderRadius: '100px',
        textTransform: 'none',
        fontWeight: 700,
      },
      contained: {
        boxShadow: '1px 2px 4px rgba(24, 37, 229, 0.2)',
      },
      colorInherit: {
        background: '#151515',
        color: '#fff!important',
        boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.1)',
        ['&:hover']: {
          background: '#333',
          color: '#fff!important',
        },
      },
      outlined: {
        fontWeight: 500,
      }
    },
    MuiTypography: {
      h1: {
        fontWeight: 900,
      },
      h2: {
        fontWeight: 900,
      },
      h3: {
        fontWeight: 900,
      },
      h4: {
        fontWeight: 900,
      },
      h5: {
        fontWeight: 900,
      },
      h6: {
        fontWeight: 900,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 16,
      },
    },
    MuiOutlinedInput: {
      inputMarginDense: {
        paddingTop: '10.5px',
        paddingBottom: '10.5px',
      },
      notchedOutline: {
        borderRadius: 8,
      },
    },
    MuiInputLabel: {
      marginDense: {
        ['&.MuiInputLabel-outlined']: {
          transform: 'translate(14px, 14px) scale(1)',
        },
      },
    },
    MuiTableRow: {
      root: {
        position: 'relative',
        whiteSpace: 'nowrap'
      },
      head: {
        whiteSpace: 'normal'
      }
    },
    MuiTableCell: {
      paddingNone: {
        position: 'sticky',
        left: 0,
        top: 0,
        background: 'rgba(255,255,255,0.87)',
        backdropFilter: 'blur(2px)'
      },
      head: {
        padding: '4px 2px!important',
        fontSize: 13,
      }
    },
    MuiTab: {
      root: {
        flexGrow: 1,
        maxWidth: 100000000,
        textTransform: 'inherit',
        fontWeight: 600,
        borderBottom: 'solid 2px #dedede',
      }
    }
  },
});

export default theme;
