import React, { useCallback, useState } from 'react';
import DialogComponent from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { Button, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface InputProps {
  title: string;
  message: string;
  open: boolean,
  onConfirm: (confirmed: boolean) => void,
}

export const ConfirmDialog: React.FC<InputProps> = ({
  title,
  message,
  open,
  onConfirm
}) => {
  const { t } = useTranslation();
  const handleConfirm = useCallback(() => onConfirm(true), [onConfirm]);
  const handleReject = useCallback(() => onConfirm(false), [onConfirm]);
  return (
    <>
      <DialogComponent
        open={open}
        maxWidth="sm"
        scroll="body"
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleReject}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {message}
          </Typography>
          <Box justifyContent="flex-end" display="flex" pb={1} mt={3}>
            <Box mr={2}>
              <Button variant="contained" color="primary" onClick={handleConfirm}>{t('confirm')}</Button>
            </Box>
            <Button variant="outlined" onClick={handleReject}>{t('cancel')}</Button>
          </Box>
        </DialogContent>
      </DialogComponent>
    </>
  );
};
