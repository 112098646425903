import { Collapse } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { keys } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface InputProps {
  value: string | number | boolean;
  label: string;
  disabled: boolean;
}

export const NullableFieldWrapper: React.FC<InputProps> = ({ value, label, children, disabled }) => {
  const [isOpened, setIsOpened] = useState(
    typeof value !== 'undefined' && value !== null && keys(value).filter(key => (value as any)[key] === null).length === 0
  );
  const { t } = useTranslation();
  if (disabled) return <></>;
  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={isOpened}
            onChange={(_, value: boolean) => {
              setIsOpened(value);
            }}
          />
        }
        label={t(label)}
      />
      <Collapse timeout={100} in={isOpened}>
        {isOpened && children}
      </Collapse>
    </Box>
  )
}