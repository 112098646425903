import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { ReactNode } from 'react';
import Label from './label';
import Value from './value';
import { useTranslation } from 'react-i18next';
import { isArray } from 'lodash';

const useStyles = makeStyles<Theme, { vertical: boolean }>(theme => ({
  container: ({ vertical }) => {
    return ({
      display: 'flex',
      alignItems: vertical ? 'flex-start' : 'center',
      flexDirection: vertical ? 'column' : 'row',
      flexWrap: vertical ? 'wrap' : 'nowrap'
    });
  },
  label: ({ vertical }) => ({
    marginRight: !vertical ? theme.spacing(1) : 0,
    marginBottom: 0,
    minHeight: 20,
    whiteSpace: vertical ? 'pre-wrap' : 'nowrap'
  })
}));

interface InputProps {
  label: string | ReactNode;
  value: string[] | string | JSX.Element | number;
  variant?: 'h6' | 'subtitle1' | 'body1';
  error?: boolean;
  orientation?: 'horizontal' | 'vertical';
  disableTranslation?: boolean;
}

type Props = InputProps;

export const LabelValue: React.FC<Props> = props => {
  const { label, value, error, variant, orientation, disableTranslation } = props;
  const classes = useStyles({ vertical: orientation === 'vertical' });
  return (
    <div className={classes.container}>
      <Label variant={variant} className={classes.label}>
        {label}
      </Label>
      <ValueComponent value={value} variant={variant} error={error} disableTranslation={disableTranslation} />
    </div>
  );
};


export const ValueComponent = ({ value, variant, error, disableTranslation }: any) => {
  const { t } = useTranslation();
  return (<>{
    isArray(value) ? (
      value.map(v => {
        return (
          <Value
            variant={variant}
            color={error ? 'error' : undefined}
            key={v}
          >
            {t(v)}
          </Value>
        );
      })
    ) : typeof value === 'string' && !disableTranslation ? (
      <Value variant={variant} color={error ? 'error' : undefined}>
        {t(value)}
      </Value>
    ) : (
          <Value variant={variant} color={error ? 'error' : undefined}>
            {value}
          </Value>
        )
  }</>);
}