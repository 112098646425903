import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { SignInForm } from './sign-in-form';
import Help from '@material-ui/icons/HelpOutlineRounded';
import { ClickableText } from 'components/shared/clickable-text';
import { Loader } from 'components/shared/loader';
import { apiPost } from 'features/api/api.service';
import { getUserFromToken } from 'features/engine/auth';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { app } from 'application';
import { useSetRecoilState } from 'recoil';

export const SignIn: React.FC = () => {
  const { t } = useTranslation();
  const authService = app.useAuthService!();
  const setIsMakingRequest = useSetRecoilState(app.globalState.isMakingRequest);
  const setError = useSetRecoilState(app.globalState.error);
  const history = useHistory();
  const location = useLocation();
  const handleSubmit = useCallback(
    (credentials: Record<string, string>) => {
      setIsMakingRequest(true);
      const redirect = `${queryString.parse(location.search).redirect || '/'}`;
      apiPost<any>('login', { ...credentials, email: String(credentials.email).toLocaleLowerCase() }, undefined, true).then(res => {
        setIsMakingRequest(false);
        const user = getUserFromToken(res.access_token);
        authService.setCurrentUser(user);
        if (user) {
          new Cookies().set('token', res.access_token, {
            expires: new Date('2030-08-01')
          });
        }
        history.push(redirect);
      }).catch((err) => {
        setIsMakingRequest(false);
        setError(err);
      });
    },
    [authService, history]
  );
  return (
    <>
      <Box
        padding={3}
        height="56px"
        alignItems="center"
        width="100%"
        display="flex"
        position="fixed"
        justifyContent="space-between"
        top="0"
        left="0"
      >
        <img height="20px" src="logo.png" />
        <ClickableText>
          <Box display="flex" alignItems="center">
            <Box mr={0.5} display="flex">
              <Help color="inherit" fontSize="small" />
            </Box>
            <Typography color="inherit" variant="body2">
              {t('help')}
            </Typography>
          </Box>
        </ClickableText>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        height="100vh"
        position="relative"
        overflow="hidden"
      >
        <Box
          borderRadius={16}
          boxShadow="0 1px 40px rgba(0,0,0,0.12)"
          width="340px"
          position="relative"
          maxWidth="100%"
          p={4}
        >
          <Box mb={2}>
            <Typography variant="h4">{t('sign-in')}</Typography>
          </Box>
          <Box mb={4}>
            <Typography color="textSecondary" variant="body2">
              {t('sign-in-info')}
            </Typography>
          </Box>
          <SignInForm onSubmit={handleSubmit}></SignInForm>
          <Loader loading={false} />
        </Box>
      </Box>
    </>
  );
};
