import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Paper from '@material-ui/core/Paper';


const variants = {
  initial: {
    opacity: 0,
    // transform: 'translate3d(0px,-10px, 0px)'
  },
  in: {
    opacity: 1,
    // transform: 'translate3d(0px,0px,0px)'
  },
  out: {
    opacity: 0,
    // transform: 'translate3d(0px,-10px, 0px)'
  }
};

const transitions = {
  duration: 0.35
};

const PageAnimation: React.FC = ({ children }) => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      style={{ width: '100%', height: '100%' }}
      variants={variants}
      transition={transitions}
    >
      <Paper elevation={0} style={{ height: '100%' }}>
        {children}
      </Paper>
    </motion.div>
  );
};

export default PageAnimation;
