import React from 'react';
import Icon from '@material-ui/core/Icon';
import Box from '@material-ui/core/Box';

export const BooleanFieldIndicator: React.FC<{ value?: boolean, leftAlign?: boolean }> = ({ value, leftAlign }) => {
  return (<Box color={value ? 'rgb(56 213 136)' : 'rgb(255 153 153)'} textAlign={!leftAlign ? 'center' : 'left'} mb="-10px" mt="-5px" fontSize={14}>
    {value ? <span role="img">✅ </span>: <span role="img">✖️ </span>}
  </Box>
  );
};
