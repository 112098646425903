import { FrontendField, convertField } from "./field";
import { Entity, Spec, Action, Query, ManyToOneField } from "shared";
import { getStateService } from "./state";
import { getAuthService } from './auth';
import { States, EntityImplementation, FrontendApp, GlobalState } from "./models";
import { canMakeEntityAction } from "./filters";
import { apiPost } from "features/api/api.service";
import { useRecoilValue, useRecoilState } from "recoil";
import { app } from "application";

export class FrontendEntity<T extends Record<string, any> = any> {
  fields: FrontendField<T>[];
  oneToManyRelations: Entity[];
  // useAuthService = getAuthService();
  stateManagment?: States[string];
  constructor(
    public specEntity: Entity,
    private spec: Spec,
    private states: States,
  ) {
    this.fields = this.specEntity.fields.map(f => convertField(f, this.spec));
    this.oneToManyRelations = this.spec.entities.filter(e =>
      e.fields.filter(f => {
        return f.type === 'many-to-one' && f.entity === this.specEntity.name
      }).length > 0
    );
    this.stateManagment = specEntity.persistent || specEntity.materialized ? states[specEntity.name] : undefined;
  }

  public get name() { return this.specEntity.name }
  public get pluralName() { return this.specEntity.pluralName }
  public get persistent() { return this.specEntity.persistent }
  public get controller() { return this.specEntity.controller }
  public get summary() { return this.specEntity.summary }
  public get titleField() { return this.specEntity.titleField }
  public get customFilters() { return this.specEntity.customFilters }
  public get icon() { return this.specEntity.icon; }
  public get sourceEntity() { return this.specEntity.materialized && this.specEntity.materialized.sourceEntity; }

  actions(items: EntityImplementation[], app: FrontendApp): FrontendEntityAction[] {
    if (!this.specEntity.actions) return [];
    const authService = app.useAuthService();
    const state = app.useStateService();
    return this.specEntity.actions.map(a => {
      const inputEntity = a.inputEntity ? app.entities.find(e => e.specEntity.name === a.inputEntity)! : undefined;
      return ({
        ...a,
        inputEntity: a.inputEntity ? app.entities.find(e => e.specEntity.name === a.inputEntity)! : undefined,
        allowed: items.filter(item => canMakeEntityAction(a.guard, authService.currentUser, item)).length === items.length,
        onSubmit: async (data: any, id?: string) => {
          state.setIsMakingRequest(true);
          if (a.multiple || a.batch) {
            return apiPost(
              `${this.specEntity.pluralName}/${a.name}?ids=${items.reduce((acc, v, i) => `${acc}${i === 0 ? '' : ','}${v.id}`, '')}`,
              data
            ).then((res) => {
              state.setIsMakingRequest(false)
              state.setStateFromResponse(res)
            }).catch((err) => {
              state.setError(err);
              state.setIsMakingRequest(false);
            });
          } else {
            return apiPost(
              `${this.specEntity.pluralName}/${id || items[0].id}/${a.name}`,
              data
            ).then((res) => {
              state.setIsMakingRequest(false);
              state.setStateFromResponse(res);
              if (res && a.type === 'create-related-entity') {
                const resEntity = inputEntity || this;
                const returnItem = res[resEntity.pluralName][0];
                const reduced = relateEntities(resEntity.specEntity, this.spec, res, returnItem);
                return { ...returnItem, ...reduced };
              }
            }).catch((err) => {
              state.setError(err);
              state.setIsMakingRequest(false);
            });
          }
        }
      })
    })
  }
  state() {
    const state = app.useStateService();
    const [, onDelete] = useRecoilState(this.stateManagment!.deleteHandler);
    const materializedDeletes = app.entities.filter(e => e.specEntity.materialized && e.sourceEntity === this.name && e.stateManagment).map(e => useRecoilState(e.stateManagment!.deleteHandler));
    const handleResponse = (res: Promise<any>) => {
      return res.then((res) => {
        state.setStateFromResponse(res)
        state.setIsMakingRequest(false);
        if (res) {
          const returnItem = res[this.pluralName][0];
          const reduced = relateEntities(this.specEntity, this.spec, res, returnItem);
          return { ...returnItem, ...reduced };
        }
      }).catch((err) => {
        console.log(err);
        state.setError(err);
        state.setIsMakingRequest(false);
      })
    }
    if (!this.stateManagment) return undefined;
    return {
      onDelete,
      create: (item: T) => {
        state.setIsMakingRequest(true);
        return handleResponse(this.stateManagment!.setItem!(item));
      },
      update: (item: T) => {
        state.setIsMakingRequest(true);
        return handleResponse(this.stateManagment!.updateItem!(item));
      },
      delete: (id: number) => {
        state.setIsMakingRequest(true);
        return handleResponse(this.stateManagment!.delItem!(id).then(() => {
          onDelete(id);
          materializedDeletes.forEach(md => md[1](id))
        }));
      },
      read: (query?: Query, params?: Record<string, string | number>) => {
        // state.setIsMakingRequest(true);
        handleResponse(this.stateManagment!.getItems!(query, params));
      },
      readSingle: (id: number) => {
        state.setIsMakingRequest(true);
        return handleResponse(this.stateManagment!.getItem(id))
      },
      getIncludedItems: (item: T | undefined, app: FrontendApp, includedItemsList?: string[]) => {
        return !item ? [] : this.fields
          .filter((f) => f.specField.type === 'many-to-one' && (!includedItemsList || includedItemsList.includes(f.specField.name)))
          .map(f => ({ field: f, entity: app.entities.find((e) => e.name === (f.specField as any).entity) }))
          .filter(ef => ef.entity!!)
          .map(ef => ({
            entity: ef.entity!,
            // eslint-disable-next-line react-hooks/rules-of-hooks
            item: app.states[ef.entity!.name].getSingle(item[ef.field.name], app.states, 0),
            field: ef.field,
          }))
          .filter(ii => !!ii.item);
      },
      getRelatedEntities: (app: FrontendApp) => {
        return this.oneToManyRelations.filter(otmr => otmr.persistent && otmr.persistent && otmr.controller?.read).map(otmr => app.entities.find(e => e.name === otmr.name)!);
      },
      ...this.stateManagment
    }
  }
}

export interface FrontendEntityAction extends Omit<Action, 'inputEntity'> {
  allowed: boolean;
  onSubmit: (data: any, id?: string) => Promise<Record<string, any> | void>;
  inputEntity?: FrontendEntity;
}

export function convertEntities(spec: Spec, states: States): FrontendEntity[] {
  return spec.entities.map((entity) => {
    return new FrontendEntity(
      entity,
      spec,
      states,
    );
  });
}

function relateEntities(specEntity: Entity, spec: Spec, res: any, entityInstance: Record<string, any>, deep: number = 1) {
  return specEntity.fields.filter(f => f.type === 'many-to-one').reduce((acc, f) => {
    const fieldEntity = spec.entities.find(e => e.name === (f as ManyToOneField).entity);
    if (fieldEntity) {
      const instance = res[fieldEntity.pluralName] && res[fieldEntity.pluralName].find((fee: any) => fee.id === entityInstance[`${f.name}Id`]) || res[fieldEntity.pluralName][0];
      let reducedForInstance = {};
      if (deep > 0) {
        reducedForInstance = relateEntities(fieldEntity, spec, res, instance, deep - 1)
      }
      return { ...acc, [f.name]: instance && { ...instance, ...reducedForInstance } };
    } else {
      return acc;
    }
  }, {});
}