import React from 'react';
import { NotificationListWidget } from 'shared/build/views';
import Typography from '@material-ui/core/Typography';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { run } from 'dollarscript/build/interpreter';
import { useHistory } from 'react-router-dom';
import { getDateStringToDisplay } from 'features/engine/field';
import Box from '@material-ui/core/Box';
import { FrontendEntity } from 'features/engine/entity';
import { useTranslation } from 'react-i18next';

export const NotificationItem: React.FC<NotificationListWidget['props'] & { item: Record<string, any>, entity: FrontendEntity }> = (props) => {
  const state = props.entity.state()!;
  const { t } = useTranslation();
  const history = useHistory();
  const handleClick = () => {
    if (props.link) {
      const link = run({})(props.link)({ currentEntity: props.item });
      if (link) {
        history.push(link);
      }
    }
    if (props.removeAction) {
      handleRemove();
    }
  }
  const handleRemove = (event?: React.MouseEvent) => {
    if (event) event.stopPropagation();
    state.delete(props.item.id)
  }
  return (
    <Box style={{ cursor: 'pointer' }} display="flex" p={2} alignItems="center" borderRadius={8} onClick={handleClick}>
      {props.avatar && (<ListItemAvatar>
        <Avatar
          alt={`Avatar`}
          src={String(props.item[props.avatar])}
        />
      </ListItemAvatar>)}
      <Box flexGrow={1} ml={props.avatar ? 2 : 0} mr={props.removeAction ? 2 : 0}>
        {props.title && <Typography variant="subtitle1"><Box fontWeight={900}>{props.title && t(props.item[props.title], props.item.context)}</Box></Typography>}
        {props.title && <Typography variant="subtitle2">{props.body && t(props.item[props.body], props.item.context)}</Typography>}
        {props.timestamp && <Typography variant="caption" color="textSecondary">{props.timestamp && getDateStringToDisplay(new Date(Number(props.item[props.timestamp])))}</Typography>}
      </Box>
      {props.removeAction && <Box>
        <IconButton onClick={handleRemove} edge="end" aria-label="delete">
          <Icon>remove</Icon>
        </IconButton>
      </Box>}
    </Box>
  );
};
