import React from 'react';
import { useTranslation } from 'react-i18next';
import { Banner as BannerWidget } from 'shared/build/views';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';


export const Banner: React.FC<BannerWidget['props']> = ({ src, title, body }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" color="white" p={4} alignItems="center" flexDirection="column" justifyContent="center" width="100%" minHeight={200} style={{ background: `url(${src})`, backgroundSize: 'cover' }}>
      {title && <Box mb={2}><Typography align="center" variant="h1">{t(title)}</Typography></Box>}
      {body && <Typography variant="h6" align="center">{t(body)}</Typography>}
    </Box>
  );
};
