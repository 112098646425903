import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import {
  DialogConfig,
  fileListToArray,
  filteredFileArray,
} from 'features/engine/file-upload-models';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  elevated: {
    zIndex: 9999,
  },
}));

interface InputProps {
  onDrag?: (drag: boolean) => void;
  onFilesAdded: (files: File[]) => void;
  dialogConfig?: DialogConfig;
}

type Props = InputProps;

const FullscreenFileDropzone: React.FC<Props> = ({
  onDrag,
  onFilesAdded,
  dialogConfig,
}) => {
  const [dragCounter, setDragCounter] = useState(0);
  const classes = useStyles();
  const dropRef = React.useRef<HTMLDivElement>(null);

  const handleDragOver = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      const newDragCounter = dragCounter + 1;
      setDragCounter(newDragCounter);
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const newDragCounter = dragCounter - 1;
    setDragCounter(newDragCounter);
  };

  const handleDrop = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(0);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const filesToUpload = filteredFileArray(
        fileListToArray(e.dataTransfer.files),
        dialogConfig
      );

      if (filesToUpload.length) {
        onFilesAdded(filesToUpload);
      }

      e.dataTransfer.clearData();
    }
  };

  useEffect(() => {
    const drag = dragCounter !== 0;
    if (onDrag) {
      onDrag(drag);
    }
  }, [onDrag, dragCounter]);

  return (
    <div
      className={classNames(classes.root, {
        [classes.elevated]: dragCounter !== 0,
      })}
      ref={dropRef}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    />
  );
};

export default FullscreenFileDropzone;
