import React from 'react';
import Box from '@material-ui/core/Box';
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';

interface InputProps {
  loading: boolean;
}

const useStyles = makeStyles<Theme, { loading: boolean }>((theme) => ({
  overlay: ({ loading }) => ({
    transitionDuration: `${theme.transitions.duration.short}ms`,
    transitionProperty: 'background-color',
    opacity: loading ? 0.9 : 0,
    backgroundColor: loading ? 'rgba(255,255,255, 0.9)' : 'rgba(255,255,255, 0)',
    top: 0,
    left: loading ? 0 : '-100vw',
  }),
}));

export const Loader: React.FC<InputProps> = ({ loading }) => {
  const classes = useStyles({ loading });
  return (
    <Box
      className={classes.overlay}
      zIndex={1000}
      position="absolute"
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <CircularProgress size={48} />
    </Box>
  );
};
