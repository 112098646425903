import { $widget } from "shared/build/views";
import { Banner } from "./layout/banner";
import { Column } from "./layout/column";
import { Row } from "./layout/row";
import { Details } from "./layout/details";
import { List } from "./layout/list";
import { EntityListItem } from "./layout/entity-list-item";
import { NotificationItem } from "./layout/notification-item";
import { Text } from "./layout/text";
import { UserDetails } from "./layout/user-details";
import { TableList } from "./layout/table-list";
import { TabsComponent } from "./layout/tabs";
import { TimelineComponent } from "./layout/timeline";
import { CardNavigation } from "./layout/card-navigation";
import { FileControl } from "./layout/file-control";


export const widgets: Record<$widget['$widget'], React.FC<any>> = {
    row: Row,
    column: Column,
    tabs: TabsComponent,

    list: List,
    entityListItem: EntityListItem,

    notification: NotificationItem,
    cardNavigation: CardNavigation,

    table: TableList,
    timeline: TimelineComponent,
    
    details: Details,
    userDetails: UserDetails,

    fileControl: FileControl,
    
    text: Text,
    image: () => null,
    banner: Banner,
    
}