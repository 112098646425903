import React, { useCallback, useState } from 'react';
import DialogComponent from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { Button, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { app } from 'application';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export const ErrorDialog: React.FC = () => {
  const { t } = useTranslation();
  const [error, setError] = useRecoilState(app.globalState.error);
  const handleClose = useCallback(() => setError(undefined), [setError])
  return (
    <>
      <DialogComponent
        open={!!error}
        maxWidth="sm"
        scroll="body"
        fullWidth={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        {error && (<>
          <DialogTitle>{t('error-dialog-title')}</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              {t('error-dialog-subtitle')}
            </Typography>
            <Box mt={2} borderRadius={8} overflow="hidden" p={2} style={{ background: '#ffe8e8' }}>
              <Typography variant="body1">
                {t(error.message)}
              </Typography>
            </Box>
            <Box justifyContent="flex-end" display="flex" pb={1} mt={3}>
              <Box ml={2}>
                <Button variant="contained" color="primary" onClick={handleClose}>{t('ok')}</Button>
              </Box>
            </Box>
          </DialogContent>
        </>)}
      </DialogComponent>
    </>
  );
};
