import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { app } from 'application';

export const RequireAuth: React.FC<RouteProps> = ({ children, ...rest }) => {
  const authService = app.useAuthService!();
  const currentLocation = useLocation();
  const isAuthenticated = !!authService.currentUser;
  useEffect(() => {
    if (!isAuthenticated && !currentLocation.pathname.includes('sign-in')) {
      new Cookies().set('redirect', currentLocation.pathname);
    }
  }, [isAuthenticated, currentLocation.pathname]);
  return (
    <Route
      {...rest}
      render={() =>
        isAuthenticated ? (
          children
        ) : (
            <Redirect
              to="/sign-in"
            />
          )
      }
    />
  );
};

export default RequireAuth;
