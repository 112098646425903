import React, { useCallback } from 'react';
import { TabsWidget } from "shared/build/views";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { useTranslation } from 'react-i18next';
import { createWidgets } from 'features/engine/create-pages';
import Box from '@material-ui/core/Box';
import { app } from 'application';
import { run } from 'dollarscript/build/interpreter';

type InputProps = TabsWidget['props'];


export const TabsComponent: React.FC<InputProps> = ({ childrenWidgets }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const handleChange = useCallback((_event: any, newValue: number) => {
    setValue(newValue);
  }, [setValue]);
  const { currentUser } = app.useAuthService();
  const widgets = childrenWidgets.filter((cw) => !cw.guard || run({ currentUser })(cw.guard).allow);
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {widgets.map(cw => (
          <Tab key={cw.name} label={t(cw.name)} />
        ))}
      </Tabs>
      {widgets.map((cw, i) => {
        return <Box
          key={`${cw.name}-box`}
          maxHeight="calc(100vh - 124px)"
          className={i === value ? 'fadeIn' : ''}
          display={i === value ? 'block' : 'none'}
        >
          {createWidgets({
            ...cw.widget,
            props: cw.widget.props ? { ...cw.widget.props, displayed: i === value } : undefined
          },
            `${cw.name}${i}`)
          }
        </Box>;
      })}
    </>
  );
};
