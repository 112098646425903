import React, { useState } from 'react';
import Slider from '@material-ui/core/Slider';
import { isArray, keys } from 'lodash';
import { FrontendField, getFieldNames } from 'features/engine/field';
import { run } from 'dollarscript/build/interpreter';
import { FieldsFormProperties } from 'features/engine/models';
import { RangeInputType } from 'shared/build/inputs';
import { debounce, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { camelToKebabCase } from 'features/forms/models';
import { useEffect } from 'react';
import { Control } from 'react-hook-form';


type InputProps = {
  inputConfig: RangeInputType,
  field: FrontendField,
  control: Control,
  fieldProperties: FieldsFormProperties;
};

const valueText = (unit?: string) => (value: number) => {
  return `${value}${unit || ''}`;
}

const handleNewValues = debounce((currentEntity: Record<string, any>, props: InputProps) => {
  const objectValue = run({ currentEntity })(props.inputConfig.builder);
  props.control.setValue(props.field.name, objectValue);
}, 300);

export const RangeInput: React.FC<InputProps> = props => {
  const { inputConfig, field, control, fieldProperties } = props;
  const [value, setValue] = useState(fieldProperties.value || fieldProperties.fieldValue || { min: 0, max: 10 });
  const { t } = useTranslation();
  useEffect(() => {
    getFieldNames(props.inputConfig.builder, props.field.name).forEach((name) => {
      const val = name.includes('min') ? value.min : value.max;
      control.register({ name, type: 'custom', defaultValue: val }, { required: !props.field.nullable });
      setTimeout(() => {
        control.setValue(name, val);
      }, 30);
    });
  }, [])
  useEffect(() => {
    return () => {
      control.unregister(getFieldNames(props.inputConfig.builder, props.field.name));
      control.setValue(field.name, null);
      control.setValue(field.name, undefined);
      setTimeout(() => control.setValue(field.name, undefined), 30);
    }
  }, [])
  return (
    <div>
      <Box mb={5}>
        <Typography variant="body2"><Box component="span" fontWeight="600">{t(camelToKebabCase(field.name))}</Box></Typography>
      </Box>
      <Box px={2}>
        <Slider
          value={[value.min, value.max]}
          getAriaValueText={valueText(inputConfig.props.unit)}
          valueLabelDisplay="on"
          onChange={(_event, newValue) => {
            const currentEntity = isArray(newValue) ?
              { min: newValue[0], max: newValue[1] } :
              { min: newValue, max: newValue };
            handleNewValues(currentEntity, props);
            setValue(currentEntity);
          }}
          min={inputConfig.props.min}
          max={inputConfig.props.max}
          step={1}
          marks={[
            {
              value: inputConfig.props.min,
              label: `${inputConfig.props.min}${inputConfig.props.unit}`,
            },
            {
              value: inputConfig.props.max,
              label: `${inputConfig.props.max}${inputConfig.props.unit}`,
            }
          ]}
        />
      </Box>
    </div>
  );
};
