import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

export interface MtcDialogProps {
  name?: string;
  isOpen: boolean;
  title: string;
  onClose: () => void;
  actions?: React.ReactNode;
  hideCloseButton?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const MtcDialog: React.FC<MtcDialogProps> = ({
  isOpen,
  title,
  actions,
  children,
  name,
  onClose,
  hideCloseButton,
}) => {
  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      disableBackdropClick={true}
      scroll="body"
      fullWidth={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby={`${name}-dialog-slide-title`}
      aria-describedby={`${name}-dialog-slide-description`}
    >
      {!hideCloseButton && (
        <Box right="8px" top="8px" position="absolute">
          <IconButton onClick={() => onClose()}><Icon>close</Icon></IconButton>
        </Box>
      )}
      <DialogTitle id={`${name}-dialog-slide-title`}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
