import React from 'react';
import Box from '@material-ui/core/Box';
import { CardNavigation as CardNavigationInterface, NavItem } from 'shared/build/views';
import Icon from '@material-ui/core/Icon';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { run } from 'dollarscript/build/interpreter';

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        transitionDuration: '200ms',
        cursor: 'pointer',
        color: theme.palette.grey[700],
        ['&:hover']: {
            boxShadow: '1px 1px 10px rgb(184 186 215)',
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        }
    },
}));



export const CardNavigation: React.FC<CardNavigationInterface['props']> = ({ navigationItems }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const navItemGroups: NavItem[][] = [];
    for (let i = 0; i < navigationItems.length; i += 2)
        navItemGroups.push(navigationItems.slice(i, i + 2));
    return (
        <>
            <Box display="flex" flexGrow={1} mx={-3.5} justifyContent="center" flexWrap="wrap" maxWidth="100%" height="100%" >
                {navItemGroups.map((nig) =>
                    <Box display="flex" justifyContent="center" flexWrap="wrap" width="100%">
                        {nig.map((ni) => (
                            <Box onClick={() => history.push(run({})(ni.path))} className={classes.card} minWidth="246px" borderRadius="8px" border="dashed 3px rgba(0,0,0,0.2)" key={`${ni.path}-${ni.label}`} display="flex" flexDirection="column" justifyContent="center" alignItems="center" py={3} px={8} my={2} mx={3}>
                                {ni.icon && <Box fontSize="72px" mb={2}><Icon color="inherit" fontSize="inherit">{ni.icon}</Icon></Box>}
                                <Box fontSize="20px" fontWeight={900}>{t(ni.label)}</Box>
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>
        </>
    );
};
