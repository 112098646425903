import Typography, { TypographyProps } from '@material-ui/core/Typography';
import React from 'react';

const Label: React.FC<TypographyProps> = props => {
  const { children, variant, ...restProps } = props;

  return (
    <Typography
      color="textSecondary"
      variant={variant || 'caption'}
      {...restProps}
    >
      {children}
    </Typography>
  );
};

export default Label;
