import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { FieldError, Controller, useForm } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { SendEmailAction } from '../../../../shared/build';
import { app } from 'application';
import { useRecoilValue } from 'recoil';
import get from 'lodash/get';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

export const emailValidator = /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

type InputProps = {
  label?: string;
  errors: FieldError | undefined;
  controllerProps: ControllerProps<'input'>;
  formater?: {
    format: (value: any) => any;
    parse: (value: any) => any;
  }
  nullable?: boolean;
  options?: SendEmailAction['options'];
};

export const FormEmailInput: React.FC<InputProps> = props => {
  const { errors, controllerProps, nullable, options } = props;
  const { t } = useTranslation();
  const { control } = useForm();
  const fieldEntity = options && app.entities.find(e => e.name === options.entity) || app.entities.find(e => e.name === 'user');
  let names: string[] = [];
  if (fieldEntity) {
    const state = fieldEntity.state()!;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const items: any[] = useRecoilValue(state.list)[fieldEntity.pluralName];
    names = items.map((v) => get(v, options && options.emailField || 'email'));
  }
  useEffect(() => {
    return () => {
      if (nullable) {
        control.setValue(controllerProps.name, undefined)
      }
    }
  }, []);
  return (
    <Box position="relative">
      <Controller
        {...controllerProps}
        defaultValue={controllerProps.defaultValue}
        render={({ onChange, value }) => (
          <>
            <Autocomplete<string>
              options={names.length > 0 ? names.filter(n => !value.includes(n)) : names}
              noOptionsText={t('no-options')}
              onChange={(_e, v) => {
                if (v) {
                  onChange(v);
                }
              }}
              multiple={true as any}
              value={value}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              autoHighlight
              autoSelect
              closeIcon={<></>}
              renderTags={(vs) => (
                vs.map(v => {
                  const isValid = emailValidator.test(v);
                  const tag = (
                  <Box key={v} display="inline-flex" border={isValid ? 'none' : 'dotted 1px red' } borderRadius={100} mb={0.5} ml={1} component="span">
                    <Chip size="small" label={v} onDelete={() => onChange(value.filter((vs: string) => vs !== v))} />
                  </Box>
                );
                return isValid ? tag : <Tooltip key={`${v}-tooltip`} title={t('invalid-email-tooltip') as string}>
                  {tag}
                </Tooltip>
                }
              ))}
              freeSolo={true as any}
              renderInput={(params) => <TextField {...params} margin="dense" label={t('email')} variant="outlined" />}
            />
            {/* <Box ml={-1} mt={1} flexWrap="wrap" display="flex">
              {(value || []).map((v: string) => (
                <Box key={v} display="inline-flex" pb={0.5} ml={1} component="span">
                  <Chip size="small" label={v} onDelete={() => onChange(value.filter((vs: string) => vs !== v))} />
                </Box>
              ))}
            </Box> */}
          </>
        )}
      />
      {errors && (
        <Typography role="alert" color="textSecondary">
          {errors.message}
        </Typography>
      )}
    </Box>
  );
};


