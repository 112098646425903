import React from 'react';
import Box from '@material-ui/core/Box';
import { Row as RowInterface } from 'shared/build/views';

export const Row: React.FC<RowInterface['props']> = ({ children, ...props }) => {
  return (
    <Box display="flex" width="100%" flexWrap="wrap" {...(props.boxProperties || {})} >
      {children}
    </Box>
  );
};
