import React from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { Text as TextWidget } from 'shared/build/views';
import { Typography } from '@material-ui/core';


export const Text: React.FC<TextWidget['props']> = ({ value, variant }) => {
  const { t } = useTranslation();
  return (
    <Typography variant={variant}>
      <Box fontSize="30px" fontWeight="700" mb={2} textAlign="center">
        {t(String(value))}
      </Box>
    </Typography>
  );
};
