import React, { useCallback, useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { border } from 'features/mui/theme';
import { useTheme, Box } from '@material-ui/core';

const useStyles = makeStyles<Theme, { sidebarOpened: boolean }>((theme: Theme) => ({
    sideBar: ({ sidebarOpened }) => ({
        background: theme.palette.common.white,
        left: 0,
        top: 0,
        maxWidth: sidebarOpened ? 600 : 52,
        minWidth: sidebarOpened ? 320 : 0,
        maxHeight: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        transitionDuration: `${theme.transitions.duration.short}ms`,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        zIndex: 100,
        borderRight: border,
        [theme.breakpoints.down('sm')]: {
            minWidth: 0,
            position: 'absolute',
            overflowX: 'hidden',
            height: '100%',
        },
    }),
    sideBarSpacer: {
        [theme.breakpoints.down('sm')]: {
            minWidth: 52,
        },
    },
    sideBarContent: {
        minWidth: 320,
        position: 'relative',
    },
    sideBarToggleIcon: ({ sidebarOpened }) => ({
        marginLeft: sidebarOpened ? 'auto' : 0,
        transitionDuration: `${theme.transitions.duration.short}ms`,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        transitionProperty: 'margin-left',
        cursor: 'pointer',
        transform: sidebarOpened ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    overlay: ({ sidebarOpened }) => ({
        transitionDuration: `${theme.transitions.duration.shortest}ms`,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        position: sidebarOpened ? 'absolute' : 'relative',
        backgroundColor: sidebarOpened ? 'rgba(255,255,255,0.6)' : 'rgba(255,255,255,0)',
        zIndex: sidebarOpened ? 3 : -1,
        [theme.breakpoints.down('sm')]: {
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            cursor: 'pointer',

        },
    }),
    sidebarOverlay: ({ sidebarOpened }) => ({
        backgroundColor: !sidebarOpened ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)',
        zIndex: !sidebarOpened ? 3 : -1,
    })
}));

const Sidebar: React.FC<{ defaultHidden?: boolean }> = ({ children, defaultHidden }) => {
    // const matches = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
    const [sidebarOpened, setSidebarOpened] = useState(!defaultHidden);
    // useEffect(() => {
    //     setSidebarOpened(matches);
    // }, [matches])
    const classes = useStyles({ sidebarOpened });
    const toggleSidebar = useCallback(() => {
        setSidebarOpened(!sidebarOpened);
    }, [sidebarOpened, setSidebarOpened]);
    return (
        <>
            <div className={classes.sideBarSpacer} />
            <div onClick={toggleSidebar} className={classes.overlay} />
            <div className={classes.sideBar}>
                <div className={classes.sideBarContent}>
                    <Box position="relative">
                        <Box width="100%" position="absolute" zIndex={200} top="20px" display="flex" justifyContent="flex-end" right={0}>
                            <ListItem button={true} onClick={toggleSidebar}>
                                <ArrowRightIcon
                                    className={classes.sideBarToggleIcon}
                                />
                            </ListItem>
                        </Box>
                        <Box position="absolute" className={classes.sidebarOverlay} top={0} left={0} width="100%" height="100%" />
                        {children}
                    </Box>
                </div>
            </div>
        </>
    );
};

export default Sidebar;
