import Button, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import {
  DialogConfig,
  fileListToArray,
  filteredFileArray,
} from 'features/engine/file-upload-models';

interface InputProps {
  onFilesAdded: (files: File[]) => void;
  dialogConfig?: DialogConfig;
}

type Props = InputProps & ButtonProps;

const displayNoneStyle = { display: 'none' };

const OpenFileSelectorButton: React.FC<Props> = props => {
  const inputOpenFileRef = React.useRef<HTMLInputElement>(null);
  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = () => {
    if (inputOpenFileRef.current) {
      const files = inputOpenFileRef.current.files;
      if (files) {
        const filesToUpload = filteredFileArray(
          fileListToArray(files),
          props.dialogConfig
        );

        if (filesToUpload.length) {
          props.onFilesAdded(filesToUpload);
        }
      }
    }
  };

  const showOpenFileDialog = () => {
    if (inputOpenFileRef.current) {
      inputOpenFileRef.current.click();
    }
  };

  const { children, dialogConfig, onFilesAdded, ...restProps } = props;
  const multiple = !!onFilesAdded && dialogConfig && !dialogConfig.singleFile;
  const accept =
    dialogConfig &&
    dialogConfig.acceptFileExtensions &&
    dialogConfig.acceptFileExtensions.map(m => `.${m}`).join(',');
  return (
    <>
      <input
        ref={inputOpenFileRef}
        type="file"
        style={displayNoneStyle}
        onChange={handleOnChange}
        multiple={true}
        accept={accept}
      />
      <Button {...restProps} onClick={showOpenFileDialog}>
        {children}
      </Button>
    </>
  );
};

export default OpenFileSelectorButton;
