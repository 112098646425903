import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { ClickableText } from 'components/shared/clickable-text';
import { FrontendField } from 'features/engine/field';
import { app } from 'application';
import { CustomFilter } from 'shared/build';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { debounce, isEqual } from 'lodash';
import { useDeepCompareEffect } from "use-deep-compare";
import { FormInput } from 'components/shared/form-input';


interface InputProps<T> {
  filters: FrontendField<T>[];
  customFilters?: (CustomFilter & { active: boolean })[];
  setCustomFilterActivity?: (name: string, value: boolean) => void;
  onSubmit: (value: Partial<T>) => void;
}

export function Filters<T>({ filters, onSubmit, customFilters, setCustomFilterActivity }: InputProps<T>) {
  const methods = useForm<
    T
  >({
    mode: 'onChange',
    shouldUnregister: false,
  });
  const { errors, control, handleSubmit, setValue, getValues, formState } = methods;
  const values = getValues();
  const { t } = useTranslation();
  const submitNewValues = useCallback(debounce((newValues: any) => {
    onSubmit(newValues);
  }, 350), [onSubmit, values]);
  const resetValues = useCallback(() => {
    const values = filters
      .map(f => {
        setValue(f.name, '' as any);
        return { [f.name]: '' as any };
      })
      .reduce((acc, v) => ({ ...acc, ...v }), {}) as Partial<T>;
    submitNewValues(
      values
    );
  }, [filters, setValue, onSubmit, submitNewValues]);
  useDeepCompareEffect(() => {
    submitNewValues(values);
  }, [values]);
  return (
    <>
      {(customFilters || []).map(cf => (<Box key={`${cf.name}${String(cf.constraint)}`} mb={1}><FormControlLabel
        control={<Checkbox
          checked={cf.active}
          onChange={(_e, v) => {
            if (setCustomFilterActivity) {
              setCustomFilterActivity(cf.name, v);
            }
          }}
        />}
        label={t(cf.name)}
      /></Box>))}
      <FormProvider {...methods} >
        <form onClick={() => submitNewValues(values)} onChange={() => submitNewValues(values)} onSubmit={handleSubmit(onSubmit as any)}>
          {filters.map(f => {
            return f.getFormField(
              app,
              control,
              errors,
              values,
              t,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              false,
              true,
              undefined,
              formState.touched,
            )
          })}
          <Box textAlign="right" mb={2}>
            <ClickableText onClick={resetValues}>{t('reset-values')}</ClickableText>
          </Box>
        </form>
      </FormProvider>
    </>
  );
}
