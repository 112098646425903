import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { app } from 'application';
import Cookies from 'universal-cookie';

export const LogoutButton: React.FC<ButtonProps> = (props) => {
    const { t } = useTranslation();
    const authService = app.useAuthService();
    const logout = useCallback(() => {
        authService.setCurrentUser(undefined)
        const cookies = new Cookies();
        const token = cookies.remove('token');
    }, [authService]);
    return (
        <Button onClick={logout} variant="contained" color="primary" {...props}>{t('logout')}</Button>
    );
};
