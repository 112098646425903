import React from 'react';
import Box from '@material-ui/core/Box';
import { Column as ColumnInterface } from 'shared/build/views';

export const Column: React.FC<ColumnInterface['props']> = ({ children, ...props }) => {
  return (
    <Box display="flex" flexGrow={1} maxWidth="100%" height="100%" flexDirection="column" {...(props.boxProperties || {})} >
      {children}
    </Box>
  );
};
