import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloudUpload from '@material-ui/icons/CloudUploadRounded';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useInterval } from 'components/shared/use-interval';
import { formatPercentProgress } from 'features/engine/file-upload-models';
import { useTranslation } from 'react-i18next';
import { formatBytes } from 'features/engine/models';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    flex: '2 1 auto',
    width: theme.spacing(10),
    height: theme.spacing(10),
    color: theme.palette.grey[700],
  },
  uploadText: {
    flex: '1 0 auto',
  },
  text: {
    flex: '0 0 auto',
    display: 'flex',
    width: '100%',
  },
  textPercent: {
    flex: '1 0 auto',
  },
  textSize: {
    marginTop: 5,
  },
  progress: {
    flex: '0 0 auto',
    width: '100%',
    marginTop: 2,
    marginBottom: theme.spacing(6),
  },
  cancelButton: {
    flex: '0 0 auto',
  },
}));

interface InputProps {
  totalUpload: number;
  isCanceling: boolean;
  onCancelFileUpload: () => void;
}

type Props = InputProps;

const UploadingComponent: React.FC<Props> = ({
  isCanceling,
  totalUpload,
  onCancelFileUpload,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [progress, setProgress] = React.useState(0);
  useInterval(
    () =>
      setProgress(
        Math.min(progress + Math.min((0.02 * 2000000) / totalUpload, 0.06), 1)
      ),
    250
  );
  const percentProgress = formatPercentProgress(progress, 2);
  const byteProgress = t('uploaded-bytes-of-total', {
    uploaded: formatBytes(totalUpload * progress),
    total: formatBytes(totalUpload),
  });

  const label = isCanceling ? t('canceling-upload') : t('uploading-files');

  return (
    <div className={classes.root}>
      <CloudUpload className={classes.icon} />
      <Typography className={classes.uploadText} variant="h5">
        {label}
      </Typography>
      <div className={classes.text}>
        <Typography className={classes.textPercent}>
          {percentProgress}
        </Typography>
        <Typography
          className={classes.textSize}
          variant="caption"
          color="textSecondary"
        >
          {byteProgress}
        </Typography>
      </div>
      <LinearProgress
        className={classes.progress}
        variant="determinate"
        value={progress * 100}
      />
      <Button
        onClick={onCancelFileUpload}
        className={classes.cancelButton}
        variant="outlined"
        color="secondary"
        size="large"
        fullWidth={true}
        disabled={isCanceling}
      >
        {t('cancel')}
      </Button>
    </div>
  );
};

export default UploadingComponent;
