import includes from 'lodash/includes';
import last from 'lodash/last';
import axios, { CancelToken } from 'axios';
import Cookies from 'universal-cookie';

export interface DialogConfig {
  singleFile?: boolean;
  acceptFileExtensions?: string[];
  maxFileSize?: number;
}

export interface UploadedFile {
  name: string;
  url: string;
  size: number;
}

export interface FileUploadTaskStatus {
  filename: string;
  loaded: number;
  total: number;
}

export function isUploadedFile(
  o: FileUploadTaskStatus | UploadedFile
): o is UploadedFile {
  return (o as UploadedFile).url !== undefined;
}

export function isFileUploadTask(
  o: FileUploadTaskStatus | UploadedFile
): o is FileUploadTaskStatus {
  return (o as FileUploadTaskStatus).total !== undefined;
}

export const CANCEL_ERROR_TOKEN = 'CANCEL_ERROR_TOKEN';

export function formatPercentProgress(progress: number, decimalPlaces: number) {
  return `${Number(progress * 100).toFixed(decimalPlaces)}%`;
}

export const fileListToArray = (list: FileList) => {
  const array = [];
  for (let i = 0; i < list.length; i++) {
    const item = list.item(i);
    if (item) {
      array.push(item);
    }
  }
  return array;
};

export const filteredFileArray = (files: File[], config?: DialogConfig) => {
  if (config) {
    let filtered = files;
    const acceptedFilenames = config.acceptFileExtensions;
    if (acceptedFilenames) {
      filtered = filtered.filter(f =>
        hasAcceptedExtension(f, acceptedFilenames)
      );
    }

    const maxFileSize = config.maxFileSize;
    if (maxFileSize) {
      filtered = filtered.filter(f => f.size <= maxFileSize);
    }

    if (!!config.singleFile && filtered.length > 1) {
      filtered = [filtered[0]];
    }

    return filtered;
  }

  return files;
};

const hasAcceptedExtension = (file: File, extensionsList: string[]) => {
  const lastSegment = last(file.name.split('.'));
  return (
    lastSegment &&
    includes(
      extensionsList.map(e => e.toLowerCase()),
      lastSegment.toLowerCase()
    )
  );
};

export function getCancelTokenSource() {
  return axios.CancelToken.source();
}

export const isCancel = (value: any): boolean => axios.isCancel(value);

export function postFile(path: string, file: File, cancelToken: CancelToken) {
  const formData = new FormData();
  formData.append('files', file);
  const token = new Cookies().get('token');
  const authHeader = token
  ? {
    Authorization: `Bearer ${token}`
  }
  : {
    Authorization: '',
  };
  return axios.post(`${process.env.REACT_APP_API_URL}/${path}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      ...authHeader
    },
    cancelToken,
  });
}
