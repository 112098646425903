import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { Filters } from 'components/layout/filters';
import { FormDialog } from 'components/forms/form-dialog';
import Sidebar from 'components/shared/sidebar';
import { ContentWithSidebar } from 'components/shared/content-with-sidebar';
import { authState } from 'features/engine/auth';
import { useRecoilValue } from 'recoil';
import { app } from 'application';
import { TableWidget } from 'shared/build/views';
import { useTheme } from '@material-ui/core';
import { Actions } from './actions';
import { useListState } from 'features/engine/state';
import { useState } from 'react';
import { MemoizedTable } from 'components/shared/table';
import { DateRageQueryControl } from 'components/query-controls/date-range-control';
import { border } from 'features/mui/theme';
import { run } from 'dollarscript/build/interpreter';
import { LabelValue } from 'components/shared/label-value';

type InputProps = TableWidget['props'] & {
  filterByKeyValue?: { key: string, value: any }[];
  onlyTable?: boolean;
  disableSelect?: boolean;
  displayed?: boolean;
  rows?: number;
};

function TableListComponent({
  entity,
  filters,
  title,
  subtitle,
  actions,
  fields,
  customActions,
  filterByKeyValue,
  customFilters,
  defaultHiddenSidebar,
  onlyTable,
  displayed,
  query,
  queryControl,
  disableSelect,
  link,
  rows,
  reloadingOnMount,
  defaultRows,
  paramsControl,
  summary,
}: InputProps) {
  const {
    filteredItems,
    setCustomFilterActivity,
    handleSubmit,
    handleClose,
    handleCreate,
    setFilterValues,
    canCreateGuard,
    dialogOpened,
    entityInstance,
    sourceEntity,
    frontendEntity,
    filtersKeys,
    customFiltersActivity,
    filterFields,
    isMaterialized,
    period,
    dateRange,
    paramsDateRange,
    handleActionSubmit,
    actionItem,
  } = useListState(app, entity, displayed || !!reloadingOnMount, filters, customFilters, filterByKeyValue, query as any, queryControl, paramsControl);
  const rowsPerPage = defaultRows || rows;
  const itemName = frontendEntity.specEntity.name;
  const { t } = useTranslation();
  const currentUser = useRecoilValue(authState);
  const listTitle = title ? t(title) : t(frontendEntity.pluralName);
  const listSubtitle = subtitle && t(subtitle);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const theme = useTheme();
  const actionItems = isMaterialized ? selectedRows.map((v: any) => ({ ...v, ...v.sourceEntity })) : selectedRows;
  const canAdd = canCreateGuard && (actions || []).includes('create');
  const summaries = summary && summary.map(s => ({ ...s, value: run({})(s.value)({ listItems: filteredItems }) }))
  const table = <>
    <Box display="flex">
      <Box width="100%">
        {queryControl && (disableSelect ? <Box border={border} style={{ borderRadius: '8px 8px 0 0' }} borderBottom="none" p={1}><DateRageQueryControl period={period} dateRange={dateRange} /></Box> : <Box mb={2}><DateRageQueryControl period={period} dateRange={dateRange} /></Box>)}
        {paramsControl && (disableSelect ? <Box border={border} style={{ borderRadius: '8px 8px 0 0' }} borderBottom="none" p={1}><DateRageQueryControl startName="start" endName="end" period={period} dateRange={paramsDateRange} /></Box> : <Box mb={2}><DateRageQueryControl startName="start" endName="end" period={period} dateRange={paramsDateRange} /></Box>)}
        <Box>
          {!disableSelect && (<Box
            px={2}
            style={selectedRows.length > 0 ?
              { background: `${theme.palette.primary.main}0f`, transitionDuration: '200ms' } :
              { background: '#efefef', transitionDuration: '200ms' }}
            borderRadius="8px 8px 0 0"
            minHeight={56}
            display="flex"
            alignItems="center"
            border="solid 1px rgba(0,0,0,0.1)"
            borderBottom="mome"
          >
            <Box mr={2} flexGrow={1}>
              <Typography variant="caption">{t('selected', { count: selectedRows.length })}</Typography>
            </Box>
            <Box display="flex">
              <Actions
                selectedItems={actionItems} actions={actions || []}
                customActions={customActions || []}
                entity={sourceEntity || frontendEntity}
                materializedEntity={sourceEntity ? entityInstance : undefined}
              >
                {isMaterialized && selectedRows.length > 0 && <Actions
                  actions={[]}
                  selectedItems={actionItems}
                  customActions={customActions || []}
                  entity={entityInstance}
                />}
              </Actions>
            </Box>
          </Box>)}
        </Box>
        {summaries && (
          <Box style={{ background: '#fcfcfc' }} border={border} borderTop="none" py={1} px={2} justifyContent="space-between" display="flex">
            {summaries.map(s => (
              <Box key={s.label} m={0.25}>
                <LabelValue orientation="vertical" label={t(s.label)} value={s.value} />
              </Box>
            ))}
          </Box>
        )}
        <MemoizedTable rows={rowsPerPage} showToolbar={onlyTable} entity={entity} link={link} items={filteredItems} selection={!disableSelect} currentUser={currentUser} selectedRows={selectedRows} onSelectionChange={setSelectedRows} fields={fields} />
      </Box>
      {dialogOpened && <FormDialog
        title={`create-${frontendEntity.name}`}
        entity={frontendEntity}
        onClose={handleClose}
        onSubmit={handleSubmit}
        open={dialogOpened}
      />}
      {actionItem && <FormDialog
        title={actionItem.action.name}
        entity={actionItem.action.inputEntity!}
        onClose={() => handleActionSubmit(undefined)}
        onSubmit={handleActionSubmit}
        relatedEntity={frontendEntity}
        item={actionItem.action.inputEntityInitialValue ? run({ currentUser, currentEntity: actionItem.item })(actionItem.action.inputEntityInitialValue) : actionItem.item}
        open={!!actionItem}
      />}
    </Box>
  </>
  if (filtersKeys.length > 0) {
    return (
      <ContentWithSidebar sidebar={<Sidebar defaultHidden={defaultHiddenSidebar}>
        <Box paddingRight={3} paddingLeft={3} paddingTop={3} width="320px">
          <Box mr={canAdd && !listSubtitle ? 0 : 1} display={canAdd && !listSubtitle ? 'block' : 'inline-block'} mb={2}>
            <Typography variant="h5">{listTitle}</Typography>
          </Box>
          {listSubtitle && <Box mb={3}>
            <Typography color="textSecondary" variant="body2">
              {listSubtitle}
            </Typography>
          </Box>}
          {canAdd && (<Box mb={3}>
            <Button
              variant="contained"
              fullWidth={true}
              color="primary"
              onClick={() => handleCreate()}
              size="large"
            >
              {t(`add-new-${itemName}`)}
            </Button>
          </Box>)}
          <>
            <Box display={canAdd && !listSubtitle ? 'block' : 'inline-block'} mb={1.5}>
              <Typography variant="h5">{t('filters')}</Typography>
            </Box>
            <Box mb={2}>
              <Filters setCustomFilterActivity={setCustomFilterActivity} customFilters={customFiltersActivity} onSubmit={setFilterValues} filters={filterFields} />
            </Box>
          </>
        </Box>
      </Sidebar>}>
        {table}
      </ContentWithSidebar>
    );
  } else {
    return <Box minWidth="100%" width="100%">
      {!onlyTable && <Box position="sticky" mb={2} display="flex" textAlign="right">
        <Box mr={2}><Typography variant="h6">{listTitle}</Typography></Box>
        {canCreateGuard && (<Box mb={3}>
          <Button
            variant="contained"
            fullWidth={true}
            color="primary"
            onClick={() => handleCreate()}
            size="large"
          >
            {t(`add-new-${frontendEntity.specEntity.name}`)}
          </Button>
        </Box>)}
      </Box>}
      {table}
    </Box>
  }
}

export const TableList = React.memo(TableListComponent, (_prevProps, props) => !props.displayed);