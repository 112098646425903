import React, { useState, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { WithId } from 'features/engine/models';
import { FormDialog } from 'components/forms/form-dialog';
import { useTranslation } from 'react-i18next';
import { FrontendEntity, FrontendEntityAction } from 'features/engine/entity';
import { app } from 'application';
import { ResourceController } from 'shared/build';
import { canMakeEntityAction } from 'features/engine/filters';
import { useRecoilValue } from 'recoil';
import { authState } from 'features/engine/auth';
import { ConfirmDialog } from 'components/shared/confirm-dialog';
import { run } from 'dollarscript/build/interpreter';

export interface InputProps<T extends WithId> {
  entity: FrontendEntity<T>;
  materializedEntity?: FrontendEntity<T>;
  actions: (keyof ResourceController)[];
  customActions: string[];
  justify?: 'center' | 'flex-start' | 'flex-end';
  disablePadding?: boolean;
  selectedItems: T[];
}

export const Actions: React.FC<InputProps<any>> = ({
  selectedItems,
  entity,
  customActions,
  actions,
  justify,
  children,
  materializedEntity,
}) => {
  const state = entity.state()!;
  const materializedEntityState = materializedEntity ? materializedEntity.state() : undefined;
  const { t } = useTranslation();
  const [openedState, setOpenedState] = useState<Record<string, boolean>>(customActions.reduce((acc, v) => ({ ...acc, [v]: false }), {}));
  const [editDialogOpened, setEditDialogOpened] = useState(false);
  const [confirmDeleteDialogOpened, setConfirmDeleteDialogOpened] = useState(false);
  const setOpened = (actionName: string, open: boolean) => (event?: React.MouseEvent) => {
    if (event) event.stopPropagation();
    setOpenedState({ ...openedState, [actionName]: open })
  }
  const [actionItem, setActionItem] = useState<{ item: Record<string, any>, action: FrontendEntityAction } | undefined>();
  const handleActionSubmit = useCallback((actionObject?: Record<string, any>) => {
    if (actionObject) {
      actionItem!.action.onSubmit(actionObject, actionItem!.item.id);
    }
    setActionItem(undefined);
  }, [setActionItem, actionItem]);
  const handleSubmit = (a: FrontendEntityAction, afterAction?: FrontendEntityAction) => (item: any) => {
    a.onSubmit(item).then((item) => {
      if (afterAction && item) {
        setActionItem({ item, action: afterAction });
      }
      setOpenedState({ ...openedState, [a.name]: false })
    }).then(() => {
      if (materializedEntityState) { materializedEntityState.read() }
    });
  };
  const handleEditOpen = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setEditDialogOpened(true);
  }, [setEditDialogOpened]);
  const handleEdit = (item: any) => {
    state.update(item).then(() => setEditDialogOpened(false)).then(() => {
      if (materializedEntityState) { materializedEntityState.read() }
    });
  };
  const handleDeleteOpen = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setConfirmDeleteDialogOpened(true);
  }, [setConfirmDeleteDialogOpened])
  const handleDeleteConfirm = useCallback((confirm) => {
    if (confirm) {
      selectedItems.map(si => state.delete(si.id).then(() => {
        if (materializedEntityState) {
          materializedEntityState.onDelete(si.id)
        }
      }));
    }
    setConfirmDeleteDialogOpened(false);
  }, [setConfirmDeleteDialogOpened, state])
  const actionButtonClicks = {
    update: handleEditOpen,
    delete: handleDeleteOpen,
  };
  const dialogs = {
    update: <>{selectedItems.length > 0 && editDialogOpened && <FormDialog
      item={selectedItems[0]}
      title={`edit-${entity.name}`}
      entity={entity}
      onClose={() => setEditDialogOpened(false)}
      onSubmit={handleEdit}
      open={editDialogOpened}
    />}</>,
    delete: <ConfirmDialog
      open={confirmDeleteDialogOpened}
      onConfirm={handleDeleteConfirm}
      title={t('delete-confirm-title')}
      message={t('delete-confirm-message')}
    />
  }
  const currentUser = useRecoilValue(authState);
  return (<Box
    flexGrow={1}
    component="span"
    flexWrap="wrap"
    display="flex"
    alignItems="flex-end"
    justifyContent={justify || 'flex-end'}
    onClick={e => e.stopPropagation()}
  >
    {children}
    {
      entity.actions(selectedItems, app).filter(a => a.allowed && customActions.includes(a.name)).map(a => {
        const active = a.single && !a.multiple && !a.batch ? selectedItems.length === 1 : selectedItems.length > 0;
        const inputEntityGuard = (a as any).inputEntityGuard;
        const runnedInputEntityGuard = inputEntityGuard && run({ currentUser, currentEntity: selectedItems[0] })(inputEntityGuard)
        const afterAction = a.type === 'create-related-entity' ? app.entities.find(e => e.name ===  a.inputEntity!.name)!.actions([], app).find(a => a.withCreate) : undefined;
        if(selectedItems.length < 1) return '';
        return (<React.Fragment key={a.name}>
          {active && (<>
            <Box mt={0.5} mb={0.5} ml={1}>
              <Button onClick={a.inputEntity ? setOpened(a.name, true) : () => handleSubmit(a)(undefined)} variant="outlined">
                {t(a.name)}
              </Button>
            </Box>
            {a.inputEntity && openedState[a.name] && <FormDialog
              title={a.name}
              entity={a.inputEntity}
              onClose={setOpened(a.name, false)}
              open={openedState[a.name]}
              onSubmit={handleSubmit(a, afterAction)}
              inputEntityGuard={runnedInputEntityGuard}
              relatedEntity={entity}
              item={a.type === 'create-related-entity' ? undefined : inputEntityGuard && selectedItems.length === 1 ? selectedItems[0] : a.inputEntityInitialValue ? run({ currentUser, currentEntity: selectedItems[0] })(a.inputEntityInitialValue) : undefined}
              options={(a as any).options}
            />}
          </>)}
        </React.Fragment>);
      })
    }
    {actionItem && <FormDialog
      title={actionItem.action.name}
      entity={actionItem.action.inputEntity!}
      onClose={() => handleActionSubmit(undefined)}
      onSubmit={handleActionSubmit}
      relatedEntity={entity}
      item={actionItem.action.inputEntityInitialValue ? run({ currentUser, currentEntity: actionItem.item })(actionItem.action.inputEntityInitialValue) : actionItem.item}
      open={!!actionItem}
    />}
    {
      selectedItems.length > 0 && actions && entity.controller && actions.filter((a) => a !== 'create' && a !== 'read').map(a => {
        if (!entity.controller![a]) return;
        if (selectedItems.length > 1 && a === 'update') return;
        if (!canMakeEntityAction(entity.controller![a], currentUser, selectedItems[0])) return;
        return <Box key={`${entity.name}-${a}-box`} mt={0.5} mb={0.5} ml={1}>
          {(dialogs as any)[a]}
          <Button variant={a === 'delete' ? 'outlined' : 'contained'} color="primary" onClick={(actionButtonClicks as any)[a]}>
            {t(a)}
          </Button>
        </Box>;
      }).filter(a => !!a)
    }
  </Box>
  );
}
