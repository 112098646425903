import { ValidationRules } from 'react-hook-form';
import { RecoilState, RecoilValueReadOnly } from 'recoil';
import { Enum, ResourceController, JWTAuthorization, Spec, Query } from 'shared';
import { FrontendEntity } from './entity';
import { getAuthService } from './auth';
import { getStateService } from './state';
import { NavItem, Page } from 'shared/build/views';
import { RequestError } from 'features/api/models';

export type EntityImplementation = Record<string, any>;

export type WithId = { id: number } & Record<string, any>;

export type FormFieldType =
  | 'checkbox'
  | 'text'
  | 'number'
  | 'select'
  | 'password'
  | 'datetime-local'
  | 'embedded'
  | 'array'
  | 'entity-array';

export type FormSelectValues<T = any> = {
  label: string;
  value: NonNullable<T>;
}[];


export interface FormProperties<T = any> {
  initialValue: T;
  type: FormFieldType;
  values?: FormSelectValues<T>
  rules?: ValidationRules;
}

export type FieldFrontendProperties<T> = { forms: FormProperties<T> }


export interface EntityState<T = any, ApiRes = any> {
  entity: string;
  getItems: (query?: Query, params?: Record<string,  string | number>) => Promise<ApiRes>;
  getItem: (id: number) => Promise<ApiRes>;
  setItem: (i: T) => Promise<ApiRes>;
  delItem: (id: number) => Promise<ApiRes>;
  updateItem: (i: T) => Promise<ApiRes>;
  deleteHandler: RecoilState<number>;
  deleteRefresh: RecoilState<number>;
  state: RecoilState<Record<number, T>>;
  list: RecoilState<ApiRes>;
  getSelector: RecoilValueReadOnly<T[]>;
  getList: (states: States) => T[];
  getSingleSelector: (id: number) => RecoilValueReadOnly<T | undefined>;
  getSingle: (id: number, states: States, iterations?: number) => T | undefined;
}

export type States = Record<string, EntityState>;

export type GlobalState = {
  isMakingRequest: RecoilState<boolean>;
  error: RecoilState<RequestError | undefined>;
}

export type EntityAction = keyof Exclude<ResourceController, undefined>;

export interface FieldsFormProperties {
  rules?: ValidationRules;
  value?: any | any[];
  fieldValue?: any; 
  disabled?: boolean;
  nullable?: boolean;
}

export interface FrontendApp {
  states: Record<string, EntityState<any, any>>;
  globalState: GlobalState;
  entities: FrontendEntity<any>[];
  enums: Enum[];
  authorization: JWTAuthorization | undefined;
  pages: Page[]
  navigation: NavItem[];
  services: Spec['services'];
  useAuthService: ReturnType<typeof getAuthService>;
  useStateService: ReturnType<typeof getStateService>
}

export type Period = 'day' | 'week' | 'month';

export function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export interface DialogConfig {
  singleFile?: boolean;
  acceptFileExtensions?: string[];
  maxFileSize?: number;
}
