import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogConfig } from 'features/engine/models';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MtcDialog } from '../dialog';
import UploadFilesComponent from './upload-files-component';


interface Props {
  opened: boolean;
  isSingleFile: boolean;
  onUpload: (urls: string[]) => void;
  onClose: () => void;
  dialogConfig?: DialogConfig;
}

const FileUploadDialog: React.FC<Props> = ({
  opened,
  isSingleFile,
  onUpload,
  dialogConfig,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <MtcDialog hideCloseButton={true} isOpen={opened} onClose={onClose} title={t('upload-files')}>
      <DialogContent>
        {opened && <UploadFilesComponent
          dialogConfig={dialogConfig}
          onClose={onClose}
          isShowingConfirmation={false}
          onUpload={onUpload}
          isSingleFile={isSingleFile}
        />}
      </DialogContent>
    </MtcDialog>
  );
};

export default FileUploadDialog;
