import Cookies from "universal-cookie";
import jwt from 'jsonwebtoken';
import { atom, useRecoilState } from "recoil";
import { apiGet } from "features/api/api.service";

export const getUserFromCookies = async () => {
  const cookies = new Cookies();
  const token = cookies.get('token');
  const currentUser = getUserFromToken(token);
  if (!currentUser) {
    cookies.remove('token');
    return;
  }
  try {
    const response = await apiGet(`users/${currentUser.id}`, undefined, token);
    if(!response.users[0]) {
      cookies.remove('token');
      return;
    }
    return response.users[0];
  } catch (err) {
    cookies.remove('token');
    console.log('currentUser error', err);
    return;
  }
};

export const getUserFromToken = (token: string) => {
  try {
    const decoded = jwt.decode(token);
    if (!!decoded && typeof decoded === 'object' && decoded.id) {
      return decoded;
    } else {
      return;
    }
  } catch {
    return;
  }
};

export const authState = atom({
  key: 'currentUser',
  default: getUserFromCookies()
});


export function getAuthService() {
  return function useAuthService() {
    const [currentUser, setCurrentUser] = useRecoilState(authState);
    return {
      currentUser,
      setCurrentUser
    };
  };
}