import React from 'react';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme, { color: boolean }>(theme => ({
  root: ({ color }) => ({
    transitionDuration: `${theme.transitions.duration.enteringScreen}ms`,
    cursor: 'pointer',
    userSelect: 'none',
    ['&:hover']: {
      color: !color ? theme.palette.secondary.dark : 'inherit',
      textDecoration: 'underline'
    }
  })
}));

type Props = TypographyProps;

export const ClickableText: React.FC<Props> = props => {
  const classes = useStyles({ color: !!props.color });
  return (
    <Typography
      variant="caption"
      color="textSecondary"
      className={classes.root}
      {...props}
    >
      {props.children}
    </Typography>
  );
};
