import { Spec } from "shared/build";
import { NavItem } from "shared/build/views";

export function createNavigationItems(spec: Spec): NavItem[] {
  if (spec.navigation) return spec.navigation.map(n => ({
    ...n,
    guard: (spec.pages.find(p => p.path === n.path) || n).guard
  }));
  return [
    ...spec.pages.map(p => {
      return {
        path: p.path,
        label: p.name,
        icon: p.icon,
        sidebar: true,
      }
    }),
    ...(spec.entities.filter(e => e.persistent && e.controller?.read && !spec.pages.find(p => (p.widget as any).entity === e.name)).map(e => {
      return {
        path: `/${e.pluralName}`,
        label: e.pluralName,
        icon: e.icon,
        toolbar: true,
        sidebar: true,
      }
    })),
  ]
}