import Button from '@material-ui/core/Button';
import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloudUpload from '@material-ui/icons/CloudUploadRounded';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import OpenFileSelectorButton from './open-file-selector-button';
import { useTranslation } from 'react-i18next';
import { DialogConfig, formatBytes } from 'features/engine/models';

const useStyles = makeStyles<Theme, { draggingFiles: boolean }>(theme => ({
  root: ({ draggingFiles }) => ({
    height: '100%',
    width: '100%',
    padding: theme.spacing(3),
    border: draggingFiles
      ? `2px dashed ${theme.palette.primary.main}`
      : `2px dashed ${theme.palette.grey[400]}`,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  }),
  icon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    color: theme.palette.grey[700],
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

interface InputProps {
  draggingFiles: boolean;
  onFilesAdded: (files: File[]) => void;
  onClose: () => void;
  dialogConfig?: DialogConfig;
}

type Props = InputProps;

const DropzoneWithButton: React.FC<Props> = ({
  onFilesAdded,
  draggingFiles,
  onClose,
  dialogConfig,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ draggingFiles });
  const isSingleFile = false;// dialogConfig && dialogConfig.singleFile;
  const acceptedFileExtensions =
    dialogConfig &&
    dialogConfig.acceptFileExtensions &&
    dialogConfig.acceptFileExtensions.map(e => `.${e}`).join(', ');
  return (
    <div className={classes.root}>
      <CloudUpload className={classes.icon} />
      <div className={classes.text}>
        <Typography variant="h6">
          {isSingleFile
            ? t('drag-and-drop-file-here')
            : t('drag-and-drop-files-here')}
        </Typography>
        <Typography variant="subtitle1">
          {t('or-click-button-below')}
        </Typography>
        {dialogConfig && dialogConfig.maxFileSize && (
          <Typography variant="caption">
            {`${t('max-file-size-is')}: ${formatBytes(
              dialogConfig.maxFileSize
            )}`}
          </Typography>
        )}
        {acceptedFileExtensions && (
          <Typography variant="caption">
            {`${t('accepted-file-extensions-are')}: ${acceptedFileExtensions}`}
          </Typography>
        )}
      </div>
      <OpenFileSelectorButton
        onFilesAdded={onFilesAdded}
        dialogConfig={dialogConfig}
        color="primary"
        variant="contained"
        size="large"
      >
        {isSingleFile
          ? t('select-file-to-upload')
          : t('select-files-to-upload')}
      </OpenFileSelectorButton>
      <Button onClick={onClose} variant="outlined" size="small">
        {t('or-close-this-panel')}
      </Button>
    </div>
  );
};

export default DropzoneWithButton;
