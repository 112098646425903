import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { FieldsFormProperties } from 'features/engine/models';
import { Controller, get, useFormContext } from 'react-hook-form';
import { app } from 'application';
import Typography from '@material-ui/core/Typography';
import { SavedEntity } from 'shared/build/inputs';
import { FrontendField } from 'features/engine/field';
import { run } from 'dollarscript/build/interpreter';
import CreatableSelect from 'react-select/creatable';
import { FormDialog } from './form-dialog';
import { kebabCase } from 'lodash';
import { selectStyles } from './google-maps';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

export const SavedEntitySelect: React.FC<{ field: FrontendField, fieldProperties?: FieldsFormProperties, inputConfig: SavedEntity }> = ({
  field,
  inputConfig,
}) => {
  const [addedItem, setAddedItem] = useState<string | undefined>(undefined);
  const { load, inputEntity, itemView, searchField } = inputConfig;
  const inputEntityInstance = app.entities.find((e) => e.name === inputEntity)!;
  const inputEntityState = inputEntityInstance.state()!;
  const items: any[] = useRecoilValue(inputEntityState.list)
  const options = items.map((inputEntityItem) => ({ ...run({ inputEntity: inputEntityItem })(itemView), inputEntity: inputEntityItem }));
  const formContext = useFormContext()
  // useEffect(() => {
  //   inputEntityState.read();
  // }, []);
  const [state, setState] = useState(undefined);
  const value = get(formContext.getValues(), field.name);
  useEffect(() => {
    setState(value)
  }, [value])
  const onSelect = (val: any) => {
    const newState = run({ inputEntity: val.inputEntity })(load);
    formContext.setValue(field.name, newState)
    setState(newState);
  }
  const { t } = useTranslation();
  const formatOptionLabel = (itemDisplay: any) => {
    return (
      <Box>
        <Box height={16} mb="2px">
          <Typography variant="caption">{(itemDisplay.label || '').includes('Create') ? `${t('create')} ${itemDisplay.value}` : itemDisplay.value}</Typography>
        </Box>
        {itemDisplay.description && (
          <Typography variant="caption">
            <Box style={{ opacity: 0.8 }} fontSize={10}>{itemDisplay.description}</Box>
          </Typography>
        )}
      </Box>
    )
  }


  return (
    <Box>
      <Controller control={formContext.control} defaultValue={value} name={field.name} />
      <CreatableSelect key={`${field.name}${'value'}`} styles={selectStyles} onCreateOption={(v) => setAddedItem(v)} value={run({ inputEntity: state })(itemView)} onChange={onSelect} formatOptionLabel={formatOptionLabel} options={options} name={field.name} isSearchable={true} />
      {addedItem && <FormDialog item={{ [searchField]: addedItem }} onSubmit={v => { inputEntityState.create(v); setAddedItem(undefined); onSelect({...v, inputEntity: v}) }} onClose={() => setAddedItem(undefined)} entity={inputEntityInstance} open={!!addedItem} title={`add-${kebabCase(inputEntity)}`} />}
    </Box>
  );
};

