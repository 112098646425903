import React from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { emailValidator } from 'features/forms/models';
import { FormInput } from 'components/shared/form-input';
import { ClickableText } from 'components/shared/clickable-text';
import { app } from 'application';

interface InputProps {
  onSubmit: (credentials: Record<string, string>) => void;
}

const loginName = app.authorization!.login;
const passwordName = app.authorization!.password;

export const SignInForm: React.FC<InputProps> = ({ onSubmit }) => {
  const methods = useForm({ mode: 'onChange', shouldUnregister: false });
  const { errors, control, handleSubmit } = methods;
  const { t } = useTranslation();
  const invalid = Object.keys(errors).length > 0;
  return (
    <FormProvider {...methods} >
      <form onSubmit={handleSubmit((credentials => onSubmit({ ...credentials, [app.authorization!.login]: String(credentials[app.authorization!.login]).toLocaleLowerCase() })))}>
        <Box mb={1}>
          <FormInput
            name={loginName}
            type="text"
            label={t(loginName)}
            controllerProps={{
              defaultValue: '',
              control,
              name: loginName,
              rules: {
                required: { value: true, message: t('required-form-error') },
                pattern: {
                  value: emailValidator,
                  message: t('email-form-error')
                }
              }
            }}
            errors={errors[loginName]}
            fullWidth={true}
            margin="dense"
          />
        </Box>
        <Box mb={2}>
          <FormInput
            name={passwordName}
            type="password"
            label={t(passwordName)}
            controllerProps={{
              defaultValue: '',
              control,
              name: passwordName,
              rules: {
                required: true,
                minLength: {
                  value: 3,
                  message: t('min-length-form-error', { length: 3 })
                }
              }
            }}
            errors={errors[passwordName]}
            fullWidth={true}
            margin="dense"
          />
        </Box>
        <Box mb={2}>
          <Button
            size="large"
            fullWidth={true}
            type="submit"
            variant="contained"
            disabled={invalid}
            color="primary"
          >
            {t('sign-in')}
          </Button>
        </Box>
        <Box style={{ opacity: 0.7 }} textAlign="right">
          <ClickableText align="right">{t('forgot-password')}</ClickableText>
        </Box>
      </form>
    </FormProvider>
  );
};
