import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { useRecoilValue } from 'recoil';
import { app } from 'application';
import { Actions } from './actions';
import { UserDetailsWidget } from 'shared/build/views';
import { run } from 'dollarscript/build/interpreter';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  avatar: {
    height: 128,
    width: 128,
    fontSize: 96,
  },
}));

export type InputProps = UserDetailsWidget['props'];

export function UserDetails(props: InputProps) {
  const frontendEntity = app.entities.find(e => e.name === props.entity)!;
  const currentUser = app.useAuthService().currentUser;
  const id = run({})(props.id)({ currentUser });
  const state = frontendEntity.state()!;
  useEffect(() => {
    state.readSingle(id);
  }, []);
  const item = state.getSingle(id, app.states);
  return item ? <DetailsContent {...props} item={item}></DetailsContent> : <></>
}

function DetailsContent({
  entity,
  avatar,
  title,
  subtitle,
  phone,
  email,
  updateAction,
  customActions,
  item,
}: InputProps & { item: any }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const frontendEntity = app.entities.find(e => e.name === entity)!;
  const emailContent = email && run({})(email)({ currentEntity: item });
  const phoneContent = phone && run({})(phone)({ currentEntity: item });
  const subtitleContent = run({})(subtitle)({ currentEntity: item });
  return (
    <Box paddingY={2} width="100%">
      <Box width="100%">
        <Box mb={1} alignItems="center" justifyContent="center" display="flex">
          <Avatar
            className={classes.avatar}
            alt={(item as any)[title!]}
            src={avatar && run({})(avatar)({ currentEntity: item })}
          >
            {!avatar && <Icon fontSize="inherit">person</Icon>}
          </Avatar>
        </Box>
        <Typography align="center" variant="h6">{t((item as any)[title!])}</Typography>
        <Box mb={2}>
          <Typography align="center" color="textSecondary" variant="subtitle1">{t(subtitleContent)}</Typography>
        </Box>
        <Box display="flex" justifyContent="center" mb={4}>
          <Box>
            {emailContent && (<Box mb={2} display="flex" alignItems="center">
              <Icon>email</Icon><Box component="span" ml={2}><Typography component="span">{emailContent}</Typography></Box>
            </Box>)}
            {phoneContent && (<Box display="flex" alignItems="center">
              <Icon>phone_iphone</Icon><Box component="span" ml={2}><Typography component="span">{phoneContent}</Typography></Box>
            </Box>)}
          </Box>
        </Box>
        <Box mb={1}>
          <Actions justify="center" selectedItems={[item]} customActions={customActions || []} entity={frontendEntity} actions={updateAction ? ['update'] : []} />
        </Box>
      </Box >
    </Box>
  );
}
