import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { LabelValue, ValueComponent } from 'components/shared/label-value';
import { useParams } from 'react-router-dom';
import { app } from 'application';
import { Actions } from './actions';
import { camelToKebabCase } from 'features/forms/models';
import { BooleanFieldIndicator } from 'components/shared/boolean-field-indicator';
import { DetailsWidget } from 'shared/build/views';
import { TableList } from './table-list';
import { ExpansionPanel } from 'components/shared/expansion-panel';
import { run } from 'dollarscript/build/interpreter';
import { getFieldsWithValues } from 'components/shared/helper-functions';
import Icon from '@material-ui/core/Icon';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { formatters } from 'features/engine/formats';
import Info from '@material-ui/icons/InfoRounded';
import Tooltip from '@material-ui/core/Tooltip';

export type InputProps = DetailsWidget['props'];

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    background: theme.palette.primary.main,
    height: 48,
    width: 48,
    fontSize: 24
  },
}));

export function Details(props: InputProps) {
  const frontendEntity = app.entities.find(e => e.name === props.entity)!;
  const urlParams = useParams();
  const currentUser = app.useAuthService().currentUser;
  const id = run({})(props.id)({ currentUser, urlParams });
  const state = frontendEntity.state()!;
  useEffect(() => {
    state.readSingle(id);
  }, []);
  const item = state.getSingle(id, app.states);
  return item ? <DetailsContent {...props} item={item}></DetailsContent> : <></>
}

function DetailsContent({
  entity,
  title,
  fields,
  actions,
  customActions,
  item,
  relatedItems,
  orientation,
  large,
}: InputProps & { item: any }) {
  const frontendEntity = app.entities.find(e => e.name === entity)!;
  const state = frontendEntity.state()!;
  const { t, i18n } = useTranslation();
  const includedItems = state.getIncludedItems(item, app);
  const fieldsWithValues = !item ? [] : getFieldsWithValues({ ...item }, includedItems, t, fields)
  const relatedEntities = relatedItems ? relatedItems.map((ri) => app.entities.find((e) => e.name === ri.entity)!) : state.getRelatedEntities(app);
  const classes = useStyles();
  let endItem = { ...item };
  frontendEntity.fields.filter(f => f.specField.type === 'many-to-one').forEach((f) => {
    endItem[f.name.split('Id')[0]] = (includedItems.find(ii => ii.field.name === f.name) || {}).item;
  });
  return (
    <Box padding={3} width="100%">
      <Box width="100%">
        <Box alignItems="center" fontSize="36px" display="flex" mb={4}>
          <Avatar className={classes.avatar}>
            <Icon fontSize="inherit">{frontendEntity.icon}</Icon>
          </Avatar>
          <Box ml={2}><Typography component="h1" variant="h5">{run({})(title)({ currentEntity: item })}</Typography></Box>
        </Box>
        <Box mb={1} flexWrap="wrap" display="flex">
          <Box display="flex" flexWrap="wrap" alignItems="center">
            {fieldsWithValues.map(fv => {
              const element = fv.tooltip && !fv.value ? <Box display="flex" justifyContent={large ? 'flex-start' : 'center'}><Info color="disabled" fontSize="small" /></Box> : <Box><ValueComponent fontWeight={large ? 700 : undefined} variant={large ? 'subtitle1' : 'body1'} disableTranslation={true} value={
                (fv.value === '') ?
                  <BooleanFieldIndicator leftAlign={true} value={!!fv.value} /> : fv.field.format ? (formatters[fv.field.format] as any)(fv.value) : i18n.exists(String(fv.value || '')) ? t(fv.value) as any : <span dangerouslySetInnerHTML={{ __html: String(fv.value) }} />
              }></ValueComponent></Box >
              return (
                <Box minWidth={large ? '24%' : '12%'} mb={2} key={fv.name} mr={2}>
                  <LabelValue
                    orientation={orientation || 'horizontal'}
                    variant={large ? 'subtitle1' : 'body1'}
                    label={`${t(camelToKebabCase(fv.name))}${large ? ': ' : ''}`}
                    value={fv.tooltip ? <Tooltip title={fv.tooltip}>{element}</Tooltip> : element}
                  />
                </Box>
              )
            })}
          </Box>
          <Actions selectedItems={[endItem]} customActions={customActions || []} entity={frontendEntity} actions={actions || []} />
        </Box>
      </Box >
      {relatedEntities.map((ri, i) => {
        const filterByKeyValue = ri.fields.filter(f => f.specField.type === 'many-to-one' && f.specField.entity === frontendEntity.name).map(f => ({
          key: f.name, value: item.id,
        }))
        return (
          <ExpansionPanel divider={relatedEntities.length - 1 !== i} key={ri.name} title={`${ri.pluralName}`}>
            <Box mb={2}>

            </Box>
            <Box>
              <TableList
                filterByKeyValue={filterByKeyValue}
                title={ri.pluralName}
                fields={relatedItems && relatedItems[i].fields || (ri.summary || ri.fields.map(f => f.name)).map(f => ({ label: f, value: { $$: { $: `currentEntity.${f}` } } }))}
                entity={ri.name}
                rows={15}
                onlyTable={true}
                actions={relatedItems && relatedItems[i].actions}
                customActions={relatedItems && relatedItems[i].customActions}
                disableSelect={relatedItems && !relatedItems[i].actions && !relatedItems[i].customActions}
                query={relatedItems && relatedItems[i].query}
                queryControl={relatedItems && relatedItems[i].queryControl}
                paramsControl={relatedItems && relatedItems[i].paramsControl}
                summary={relatedItems && relatedItems[i].summary}
              />
            </Box>
          </ExpansionPanel>
        )
      })}
    </Box>
  );
}
