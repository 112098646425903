import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { FieldError, Controller, useForm, useFormContext, DeepMap } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@material-ui/core';
import { get } from 'lodash';

type InputProps = TextFieldProps & {
  errors: FieldError | undefined;
  controllerProps: ControllerProps<'input'>;
  formater?: {
    format: (value: any) => any;
    parse: (value: any) => any;
  }
  nullable?: boolean;
  touched?: DeepMap<any, boolean>;
};

export const FormInput: React.FC<InputProps> = props => {
  const { errors, children, controllerProps, formater, nullable, ...inputProps } = props;
  const [stateValue, setValue] = useState(formater ? formater.parse(controllerProps.defaultValue) : undefined);
  const methods = useFormContext();
  const val = methods ? methods.watch(controllerProps.name) : '';
  useEffect(() => {
    return () => {
      if (nullable) {
        methods.control.setValue(controllerProps.name, undefined)
      }
    }
  }, []);
  useEffect(() => {
    if (formater) {
      setValue(formater.parse(val));
    }
  }, [val]);
  const { t } = useTranslation();
  const touched = props.touched && get(props.touched, controllerProps.name);
  return (
    <>
      <Controller
        {...controllerProps}
        defaultValue={controllerProps.defaultValue}
        render={({ onChange, onBlur, value }) => {
          const inputValue = formater ?
            stateValue :
            (value && inputProps.type === 'number' ? Number(value) : value);
          return (
            <TextField
              aria-invalid={!!errors}
              variant={'outlined' as any}
              {...inputProps}
              error={!!errors}
              value={
                inputValue === 0 && !touched ? '' : inputValue
              }
              onChange={e => {
                if (controllerProps.onChange) controllerProps.onChange(e as any);
                const newValue = e.target.value && inputProps.type === 'number'
                  ? Number(e.target.value)
                  : e.target.value;
                setTimeout(() => methods.trigger(controllerProps.name));
                if (formater) {
                  onChange(formater.format(newValue));
                  setValue(newValue);
                } else {
                  onChange(
                    newValue
                  );
                }
              }}
              onBlur={onBlur}
              onKeyPress={e => {
                if (inputProps.type === 'number' && (e.which === 13 || e.keyCode === 13 || e.key === 'Enter' || e.key === 'e' || e.key === 'E' || e.keyCode === 69 || e.which === 69)) {
                  e.preventDefault();
                }
              }}
            >
              {children}
            </TextField>
          )
        }}
      />
      <Collapse in={!!errors}>
        <Typography variant="caption" color="error" role="alert">
          {t('field-is-required')}
        </Typography>
      </Collapse>
    </>
  );
};
