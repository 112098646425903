import { atom, selector, DefaultValue, selectorFamily, useSetRecoilState, SetterOrUpdater, useRecoilValue } from 'recoil';
import { apiGet, apiPost, apiDelete, apiPut } from 'features/api/api.service';
import { WithId, EntityState, States, GlobalState, FrontendApp } from 'features/engine/models';
import { CustomFilter, Entity, EntityField, ManyToOneField, Query, stringifyObject, stringifyQuery } from 'shared';
import { RequestError } from 'features/api/models';
import { useCallback, useEffect } from 'react';
import { useState } from 'react';
import keys from 'lodash/keys';
import { authState } from './auth';
import { canMakeEntityAction, validateEntity } from './filters';
import { handleFilter } from 'components/shared/helper-functions';
import { Context, run } from 'dollarscript/build/interpreter';
import { $ } from 'dollarscript';
import values from 'lodash/values';
import { ParamsControl, QueryControl } from 'shared/build/views';
import { getDateRageAndPeriodFromUrl, getUpdatedQuery } from 'components/query-controls/date-range-control';
// import { useDeepCompareEffect } from 'use-deep-compare';
import { useLayoutEffect } from 'react';
import { useDeepCompareEffect } from 'use-deep-compare';
import { FrontendEntityAction } from './entity';

export function createState<T extends WithId, M extends Entity['pluralName'] = Entity['pluralName'], ApiRes extends Record<M, T[]> = Record<M, T[]>>(
  entity: Entity
): EntityState<T> {
  const stateObject = {} as EntityState<T>;
  stateObject.entity = entity.name;
  stateObject.state = atom<Record<number, T>>({
    key: `${entity.pluralName}State`,
    default: {}
  });

  stateObject.deleteRefresh = atom<number>({
    key: `${entity.pluralName}DeleteRefresh`,
    default: Date.now(),
  });
  const includedItemFields = entity.fields.filter(f => f.type === 'many-to-one') as ManyToOneField[];

  stateObject.getSelector = selector<T[]>({
    key: `getOneToMany${entity.name}`,
    get: ({ get }) => values(get(stateObject.state!)),
  });

  stateObject.getList = (states: States) => {
    const lists = includedItemFields.reduce((acc, v) => ({
      ...acc,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      [v.entity]: useRecoilValue(states[v.entity].getSelector)
    }), {})
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useRecoilValue(stateObject.getSelector).map(i => {
      const itemWithIncludedItems = { ...i };
      includedItemFields.forEach(iif => {
        const id = itemWithIncludedItems[`${iif.name}Id`] || -1;
        (itemWithIncludedItems as any)[iif.name] = (lists as any)[iif.entity].find((relatedItem: any) => relatedItem.id === id);
      });
      return itemWithIncludedItems;
    });
  }

  stateObject.getItems = async (query?: Query, params?: Record<string, string | number>) => {
    const response = await apiGet(`${entity.pluralName}${query ? `?${stringifyQuery(query)}` : ''}${params ? `${query ? '&' : '?'}${stringifyObject(params, 'params')}` : ''}`);
    return response;
  };

  stateObject.list = selector<ApiRes & { entity: string }>({
    key: `${entity.name}List`,
    get: async ({ get }) => {
      const refresh = get(stateObject.deleteRefresh);
      const res = await stateObject.getItems()
      return { ...res, refresh };
    },
    set: ({ set, get }, newValue) => {
      const items = get(stateObject.state!);
      if (
        !(newValue instanceof DefaultValue) &&
        newValue !== undefined &&
        !!newValue[entity.pluralName as M] &&
        newValue[entity.pluralName as M].length > 0
      ) {
        const addedItems = newValue[entity.pluralName as M]
          .map(i => ({ [i.id]: i }))
          .reduce((acc, v) => ({ ...acc, ...v }), {});
        if (newValue.entity === entity.name) {
          set(stateObject.state!, addedItems);
        } else {
          set(stateObject.state!, { ...items, ...addedItems });
        }
      }
    }
  });

  stateObject.getItem = async (id: number) => {
    const response = await apiGet(`${entity.pluralName}/${id}`);
    return response;
  };

  stateObject.getSingleSelector = selectorFamily<T | undefined, number>({
    key: `getSingle${entity.name}`,
    get: (id) => ({ get }) => {
      return get(stateObject.getSelector).find(i => String(i.id) === String(id))
    }
  });

  stateObject.getSingle = (id: number, states: States, iterations: number = 0) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let item = useRecoilValue(stateObject.getSingleSelector(id));
    let itemWithIncludedItems = { ...(item || {}) } as T;
    includedItemFields.forEach(iif => {
      const iIId = itemWithIncludedItems[`${iif.name}Id`] || -1;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      (itemWithIncludedItems as any)[iif.name] = iterations > 0 ? states[iif.entity].getSingle(id, states, iterations - 1) : useRecoilValue(states[iif.entity].getSingleSelector(iIId));
    });
    return item ? itemWithIncludedItems : undefined;
  }


  stateObject.setItem = async (i: T) => {
    const response = await apiPost(
      `${entity.pluralName}`,
      i
    );
    return response;
  };

  stateObject.updateItem = async (i: T) => {
    const response = await apiPut(
      `${entity.pluralName}/${i.id}`,
      i
    );
    return response;
  };

  stateObject.delItem = async (id: number) => {
    const response = await apiDelete(`${entity.pluralName}/${id}`);
    return response;
  };

  stateObject.deleteHandler = selector({
    key: `${entity.name}DeleteHandler`,
    get: () => { return 1 },
    set: ({ set, get }, id) => {
      const items = { ...(get(stateObject.state) || {}) };
      delete items[id as any];
      set(stateObject.state, items);
      if (keys(items).length === 0) {
        set(stateObject.deleteRefresh, Date.now());
      }
    }
  });

  return stateObject;
}

export function createStates(entities: Entity[]): States {
  const states = entities
    .filter(e => e.persistent || e.materialized)
    .map(e => ({ [e.name]: createState(e) }))
    .reduce((acc, v) => ({ ...acc, ...v }), {});
  return states;
}

export function getGlobalState(): GlobalState {
  return {
    isMakingRequest: atom<boolean>({
      key: `isMakingRequest`,
      default: false,
    }),
    error: atom<RequestError | undefined>({
      key: `error`,
      default: undefined,
    }),
  };
}

export function getStateService<ApiRes>(states: States, globalState: GlobalState) {
  return function useStateService() {
    const stateSetters: { ss: SetterOrUpdater<any>, entity: string }[] = Object.values(states).filter(e => e.state).map(e =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      ({ ss: useSetRecoilState(e.list!), entity: e.entity })
    );
    const isMakingRequestSetter = useSetRecoilState(globalState.isMakingRequest);
    const requestErrorSetter = useSetRecoilState(globalState.error);
    const setStateFromResponse = (response: ApiRes, entity?: string) => {
      stateSetters.forEach(ss => ss.ss({ ...response, entity }));
    };
    const setIsMakingRequest = (active: boolean) => {
      isMakingRequestSetter(active);
    }
    const setError = (requestError?: RequestError) => {
      requestErrorSetter(requestError);
    }
    return {
      setStateFromResponse,
      setIsMakingRequest,
      setError
    };
  };
}

const emptyTable: any[] = [];

export function useListState(
  app: FrontendApp,
  entity: string,
  displayed?: boolean,
  filters?: Record<string, true | 'eq' | 'startsWith' | 'includes'>,
  customFilters?: string[],
  filterByKeyValue?: { key: string, value: any }[],
  query?: $<(c: Context) => string>,
  queryControl?: QueryControl,
  paramsControl?: ParamsControl,
) {
  const currentUser = useRecoilValue(authState);
  const stateService = app.useStateService();
  const { dateRange, period } = getDateRageAndPeriodFromUrl('startDate', 'endDate', 'month');
  const paramsDateRange = getDateRageAndPeriodFromUrl('start', 'end', 'month').dateRange;
  const params = { start: paramsDateRange.startDate.valueOf(), end: paramsDateRange.endDate.valueOf() };
  const updatedQuery = getUpdatedQuery(queryControl, query ? run({ currentUser, params })(query)({ currentUser, params }) : {}, dateRange);
  const queried = !!query || !!queryControl;
  const paramsNeeded = !!paramsControl && !!paramsDateRange;
  const entityInstance = app.entities.find(e => e.name === entity)!;
  const sourceEntity = app.entities.find(e => e.name === entityInstance.sourceEntity);
  const frontendEntity = sourceEntity || entityInstance;
  const state = entityInstance.state()!;
  const sourceEntityState = sourceEntity ? sourceEntity.state()! : undefined;
  const refresh = useSetRecoilState(state.deleteRefresh);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const list: any = queried || !!paramsControl ? emptyTable : useRecoilValue(state.list);
  const refreshValue = list.refresh;
  const dis = !!displayed;
  useLayoutEffect(() => {
    if (dis) {
      refresh(Date.now());
    }
  }, [dis, dateRange.startDate.valueOf(), dateRange.endDate.valueOf(), paramsDateRange.startDate.valueOf(), paramsDateRange.endDate.valueOf()]);
  const sourceItems = sourceEntityState ? sourceEntityState.getList(app.states) : [];
  useDeepCompareEffect(() => {
    // if (Date.now() - refreshValue < 10000) {
    stateService.setStateFromResponse(list, entity);
    // }
  }, [list]);
  useDeepCompareEffect(() => {
    if (queried || paramsNeeded) {
      if (paramsNeeded) {
        state.read(updatedQuery, params);
      } else {
        state.read(updatedQuery);
      }
    }
  }, [queried, updatedQuery, paramsNeeded, params])
  const listItems = ((list[entityInstance.pluralName] || []) as any[]);
  const selectorItems = state.getList(app.states);
  const items = (selectorItems.length > 0 ? selectorItems : listItems).filter((i) => {
    if (!filterByKeyValue || filterByKeyValue.length === 0) return true;
    return filterByKeyValue.filter(kv => i[kv.key] === kv.value).length > 0;
  }).map((i) => {
    const sourceItem = sourceItems.find(si => si.id === i.id)
    let item = i;
    if (sourceItem) {
      item = { ...i, sourceEntity: sourceItem };
    }
    return item;
  });
  const [dialogOpened, setDialogOpened] = useState(false);
  const [actionItem, setActionItem] = useState<{item: Record<string, any>, action: FrontendEntityAction} | undefined>();
  const filtersInputKeys = keys(filters || {});
  const filterFields = filtersInputKeys.map(k => entityInstance.fields.find(f => f.specField.name === k)!);
  const filtersKeys = filterFields.map(f => f.name);
  const [filterValues, setFilters] = useState<{ [key: string]: { value: any, type: Exclude<EntityField['filter'], boolean> } }>(
    filterFields.reduce((acc, v) => ({ ...acc, [v ? v.name : '']: { value: '' } }), {})
  );
  const setFilterValues = useCallback((newFilterValues: Record<string, any>) => {
    const updatedFilterValues = { ...filterValues };
    Object.keys(newFilterValues).forEach(key => updatedFilterValues[key].value = newFilterValues[key])
    setFilters(updatedFilterValues);
  }, [])
  const [customFiltersActivity, setCustomFiltersActivity] = useState<(CustomFilter & { active: boolean; })[]>(
    (frontendEntity.customFilters || []).filter((f) => (customFilters || []).includes(f.name)).map(v => ({ ...v, active: false }))
  );
  const setCustomFilterActivity = useCallback((name: string, active: boolean) => {
    setCustomFiltersActivity([...customFiltersActivity].map((cf) => {
      if (cf.name === name) { return { ...cf, active } } else { return cf }
    }));
  }, [])
  const handleSubmit = useCallback(
    (item: Record<string, any>, action?: FrontendEntityAction) => {
      (sourceEntityState || state).create(item).then((newItem) => {
        setDialogOpened(false);
        if (sourceEntity) {
          state.read(updatedQuery);
        }
        if(action) {
          setActionItem({item: newItem, action});
        }
      });
    },
    [setDialogOpened, sourceEntity]
  );
  const handleClose = useCallback(() => {
    setDialogOpened(false);
  }, [setDialogOpened]);
  const handleCreate = useCallback(() => {
    setDialogOpened(true);
  }, [setDialogOpened]);
  const handleActionSubmit = useCallback((actionObject?: Record<string, any>) =>{ 
    if(actionObject){
      actionItem!.action.onSubmit(actionObject, actionItem!.item.id);
    }
    setActionItem(undefined);
  }, [setActionItem, actionItem]);
  const canCreateGuard: boolean = !!frontendEntity.controller &&
    !!frontendEntity.controller.create &&
    (canMakeEntityAction(frontendEntity.controller.create, currentUser));
  const activeCustomFilters = customFiltersActivity.filter(cf => cf.active);
  const activeFilters = filtersKeys.filter(f => !!(filterValues as any)[f] && (filterValues as any)[f].value);
  const filteredItems = items.filter(i => {
    if (updatedQuery && updatedQuery.where && !validateEntity(updatedQuery.where, i)) {
      return false;
    }
    if (activeFilters.length === 0 && activeCustomFilters.length === 0) return !!i;
    return (
      activeFilters
        .map(f => {
          const filter = filterValues[f]!;
          return handleFilter(filter, f, i);
        })
        .filter(v => !!v).length === activeFilters.length && !activeCustomFilters.find(cf => {
          const currentItem = i.sourceEntity ? i.sourceEntity : i;
          const contraint = run({ currentUser, currentEntity: currentItem })(cf.constraint);
          const constraintKeys = keys(contraint);
          return constraintKeys.filter(k => {
            return handleFilter(contraint[k], k, currentItem);
          }).length !== constraintKeys.length;
        })
    );
  });
  return {
    filteredItems,
    setCustomFilterActivity,
    handleSubmit,
    handleActionSubmit,
    handleClose,
    handleCreate,
    setFilterValues,
    canCreateGuard,
    dialogOpened,
    entityInstance,
    sourceEntity,
    frontendEntity,
    filtersKeys,
    customFiltersActivity,
    filterFields,
    items,
    isMaterialized: !!sourceEntity,
    query: updatedQuery,
    dateRange,
    period,
    paramsDateRange,
    actionItem,
  }
}
