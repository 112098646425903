import React, { useState, useCallback } from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { Collapse, Typography, makeStyles, Theme } from '@material-ui/core';
import { border } from 'features/mui/theme';
import { ClickableText } from './clickable-text';
import Down from '@material-ui/icons/KeyboardArrowDownRounded';

const useStyles = makeStyles<Theme, { open: boolean }>(theme => ({
  icon: ({ open }) => ({
    transitionDuration: `${theme.transitions.duration.shorter}ms`,
    transitionProperty: 'transform',
    transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
  })
}));

export const ExpansionPanel: React.FC<{ open?: boolean; title: string, divider?: boolean }> = ({ open, children, title, divider }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(!!open)
  const classes = useStyles({ open: isOpen })
  const handleClick = useCallback(() => {
    setIsOpen(!isOpen)
  }, [setIsOpen, isOpen])
  return (
    <Box borderBottom={divider ? border : undefined}>
      <Box style={{ cursor: 'pointer' }} paddingY={2} onClick={handleClick} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <ClickableText color="textPrimary"><Typography variant="subtitle1">
            <Box fontWeight="700">
              {t(title)}
            </Box>
          </Typography>
          </ClickableText>
        </Box>
        <ClickableText><Box display="flex" alignItems="center"><Typography variant="caption">{t(`show-${isOpen ? 'less' : 'more'}`)}</Typography> <Box display="flex" mt={-0.25} ml={0.5}><Down className={classes.icon} fontSize="small" /></Box></Box></ClickableText>
      </Box>
      <Collapse in={isOpen}>
        <Box pb={2}>
          {children}
        </Box>
      </Collapse>
    </Box >
  );
};
