import Typography, { TypographyProps } from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
  value: {
    minHeight: 20,
    width: '100%',
  }
});

const Value: React.FC<TypographyProps> = props => {
  const { children, variant, ...restProps } = props;
  const classes = useStyles();
  return (
    <Typography
      variantMapping={{ caption: 'div' }}
      className={classes.value}
      variant={variant || 'caption'}
      {...restProps}
    >
      {children}
    </Typography>
  );
};

export default Value;
