import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        maxHeight: '100%',
        maxWidth: '100%',
        height: '100%',
        display: 'flex',
        position: 'relative',
        overflow: 'auto',
        width: '100%'
    },
    content: {
        position: 'relative',
        flexGrow: 1,
        padding: theme.spacing(2),
        maxHeight: '100%',
        overflow: 'auto',
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1.5),
        },
    },
}));

interface InputProps {
    sidebar: React.ReactNode;
}

type Props = InputProps;

export const ContentWithSidebar: React.FC<Props> = props => {
    const classes = useStyles();
    const { children, sidebar } = props;
    return (
        <>
            <div className={classes.container}>
                {sidebar}
                <div className={classes.content}>{children}</div>
            </div>
        </>
    );
};
