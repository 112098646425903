import Button from '@material-ui/core/Button';
import { Theme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FileListElement from './file-list-element';
import OpenFileSelectorButton from './open-file-selector-button';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    flex: '1 1 0',
    position: 'relative',
    overflowY: 'scroll',
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  addFilesButton: {
    flex: '0 0 auto',
    marginBottom: theme.spacing(1),
  },
  uploadButton: {
    flex: '0 0 auto',
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 20,
  },
}));

interface InputProps {
  files: File[];
  onFilesAdded: (files: File[]) => void;
  onFilesRemoved: (files: File[]) => void;
  uploadFiles: (files: File[]) => void;
  isSingleFile: boolean;
  isUploadingFiles: boolean;
}

type Props = InputProps;

const FileListConponent: React.FC<Props> = ({
  files,
  onFilesAdded,
  onFilesRemoved,
  uploadFiles,
  isSingleFile,
  isUploadingFiles,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {files.map(f => (
          <FileListElement
            name={f.name}
            size={f.size}
            onDelete={() => onFilesRemoved([f])}
            key={f.name}
          />
        ))}
      </div>
      {!isSingleFile && (
        <div className={classes.addFilesButton}>
          <OpenFileSelectorButton
            onFilesAdded={onFilesAdded}
            color="secondary"
            variant="text"
            fullWidth={true}
            disabled={isUploadingFiles}
          >
            <AddCircleOutlineIcon className={classes.icon} />
            {t('select-files-to-upload')}
          </OpenFileSelectorButton>
        </div>
      )}
      <div className={classes.uploadButton}>
        <Button
          color="primary"
          variant="contained"
          fullWidth={true}
          size="large"
          onClick={() => uploadFiles(files)}
        >
          {t('upload')}
        </Button>
      </div>
    </div>
  );
};

export default FileListConponent;
