import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Today from '@material-ui/icons/Today';
import React, { useCallback } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { getSetPeriod } from 'components/query-controls/date-range-control';

interface InputProps {
  start: moment.Moment;
  end: moment.Moment;
  handleDateChange(start: moment.Moment, end: moment.Moment): void;
  period: 'day' | 'week' | 'month',
  relative?: boolean;
}

type Props = InputProps;

const useStyles = makeStyles<Theme>(theme => ({
  iconBtn: {
    margin: theme.spacing(0.5),
  },
  currentMonth: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  datePickerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const isCurrentPeriod = (startDate: moment.Moment, endDate: moment.Moment): boolean => {
  const today = new Date();
  return startDate.isSameOrBefore(today) && endDate.isSameOrAfter(today);
};

export const DatePicker: React.FC<Props> = props => {
  const {
    start,
    end,
    children,
    handleDateChange,
    period,
    relative,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const setPeriod = getSetPeriod(period);
  const handlePreviousPeriodClick = useCallback(() => {
    if (relative) {
      handleDateChange(start.clone().subtract(1, period), end.clone().subtract(1, period))
    } else {
      handleDateChange(start.clone().subtract(1, period).startOf(setPeriod), start.clone().subtract(1, period).endOf(setPeriod))
    }
  }, [handleDateChange, start, setPeriod, end, relative]);
  const handleNextPeriodClick = useCallback(() => {
    if (relative) {
      handleDateChange(start.clone().add(1, period), end.clone().add(1, period))
    } else {
      handleDateChange(start.clone().add(1, period).startOf(setPeriod), start.clone().add(1, period).endOf(setPeriod))
    }
  }, [handleDateChange, start, setPeriod, end, relative]);
  const handleCurrentPeriodClick = useCallback(() => {
    const today = new Date();
    const newStart = start.clone().subtract(end.diff(today, period), period);
    const newEnd = end.clone().subtract(end.diff(today, period), period);
    if (isCurrentPeriod(newStart, newEnd)) {
      handleDateChange(newStart, newEnd)
    } else {
      handleDateChange(newStart.add(1, period), newEnd.add(1, period));
    }
  }, [handleDateChange, start, end, period]);

  return (
    <>
      <div className={classes.datePickerContainer}>
        <Tooltip placement="top" title={t(`previous-${period}`) || ''}>
          <Box mr={1}>
            <IconButton
              onClick={handlePreviousPeriodClick}
              className={classes.iconBtn}
              size="small"
              aria-label={t(`previous-${period}`)}
            >
              <KeyboardArrowLeft />
            </IconButton>
          </Box>
        </Tooltip>
        <Tooltip
          className={classes.currentMonth}
          placement="top"
          title={t(`current-${period}`) || ''}
        >
          <Box mr={1}>
            <IconButton
              size="small"
              disabled={isCurrentPeriod(start, end)}
              onClick={handleCurrentPeriodClick}
              aria-label={t(`current-${period}`)}
            >
              <Today />
            </IconButton>
          </Box>
        </Tooltip>
        <Typography variant="caption">{`${start.format('YYYY-MM-DD')}${period === 'day' ? '' : ` - ${end.format('YYYY-MM-DD')}`}`}</Typography>
        <Tooltip placement="top" title={t(`next-${period}`) || ''}>
          <div>
            <Box ml={1}>
              <IconButton
                className={classes.iconBtn}
                onClick={handleNextPeriodClick}
                size="small"
                aria-label={t(`next-${period}`)}
              >
                <KeyboardArrowRight />
              </IconButton>
            </Box>
          </div>
        </Tooltip>
      </div>
      {children}
    </>
  );
};
