import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardArrowDownRounded } from '@material-ui/icons';
import { DatePicker } from 'components/shared/date-picker';
import { Period } from 'features/engine/models';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Query } from 'shared/build';
import { DateRangeQueryControl } from 'shared/build/views';

type DateRange = { startDate: moment.Moment, endDate: moment.Moment };

export const getDateRageAndPeriodFromUrl = (startName: string, endName: string, defaultPeriod?: 'day' | 'week' | 'month', fixedPeriods?: Period[]) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const period = validatePeriod(search.get('period') || defaultPeriod || 'month')
  setTimeout(() => window.dispatchEvent(new Event('resize')));
  const queryStart = new Date(Number(search.get(startName)));
  const queryEnd = new Date(Number(search.get(endName)))
  const startDate = queryStart.getTime() ? moment(queryStart) : getDefaultStartDateForPeriod(period);
  const endDate = queryEnd.getTime() ? moment(queryEnd) : undefined;
  const dateRange = getNewDateRangeForPeriod(period, startDate, endDate, fixedPeriods);
  return {
    dateRange,
    period
  };
}

export function getUpdatedQuery(queryControl?: DateRangeQueryControl, inputQuery?: Query, dateRange?: DateRange): Query | undefined {
  if (!dateRange || !queryControl) return inputQuery;
  return {
    ...(inputQuery || {}),
    where: {
      ...((inputQuery || {}).where || {}),
      [queryControl.startDateField]: { type: 'lte', value: dateRange.endDate.valueOf() },
      ...(queryControl.endDateField ? { [queryControl.endDateField]: { type: 'gte', value: dateRange.startDate.valueOf() } } : {})
    }
  }
}

export const DateRageQueryControl: React.FC<{ startName?: string, endName?: string, period: Period, dateRange: DateRange, fixedPeriod?: boolean, fixedPeriods?: Period[] }> = ({ period, dateRange, fixedPeriod, fixedPeriods, startName, endName }) => {
  const history = useHistory();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [menuAnchorElement, setMenuAnchorElement] = React.useState<null | HTMLElement>(null);
  const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorElement(event.currentTarget);
  }, [setMenuAnchorElement]);
  const handleMenuClose = useCallback(() => {
    setMenuAnchorElement(null);
  }, [setMenuAnchorElement]);
  const handleDateChange = useCallback((startDate: moment.Moment, endDate: moment.Moment) => {
    search.set(startName || 'startDate', String(startDate.valueOf()))
    search.set(endName || 'endDate', String(endDate.valueOf()))
    history.push({
      pathname: location.pathname,
      search: search.toString()
    });
  }, [search, history])
  const handlePeriodSet = (newPeriod: 'week' | 'month' | 'day') => () => {
    setMenuAnchorElement(null);
    const newRange = getNewDateRangeForPeriod(newPeriod, dateRange.startDate, undefined, fixedPeriods || [])
    search.set(startName || 'startDate', String(newRange.startDate.valueOf()))
    search.set(endName || 'endDate', String(newRange.endDate.valueOf()))
    search.set('period', newPeriod);
    history.push({
      pathname: location.pathname,
      search: search.toString()
    });
  };
  const { t } = useTranslation();
  return <>
    <Menu open={!!menuAnchorElement} anchorEl={menuAnchorElement} onClose={handleMenuClose}>
      <MenuItem onClick={handlePeriodSet('day')}>{t('day')}</MenuItem>
      <MenuItem onClick={handlePeriodSet('week')}>{t('week')}</MenuItem>
      <MenuItem onClick={handlePeriodSet('month')}>{t('month')}</MenuItem>
    </Menu>
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
      <Box position="relative">
        {!fixedPeriod && <Button size="small" onClick={handleMenuOpen}>{`${t('period')}: ${t(period)}`} <Box component="span" fontSize="14px" display="flex" mr={-0.5} ml={1}><KeyboardArrowDownRounded fontSize="inherit" /></Box></Button>}
      </Box>
      <DatePicker relative={(fixedPeriods || []).includes(period)} period={period} handleDateChange={handleDateChange} start={dateRange.startDate} end={dateRange.endDate} />
    </Box>
  </>
}



function validatePeriod(queryPeriod: string): Period {
  if (queryPeriod === 'day' || queryPeriod === 'week' || queryPeriod === 'month') {
    return queryPeriod;
  } else {
    return 'month';
  }
}

function getDefaultStartDateForPeriod(period: Period): moment.Moment {
  const setPeriod = getSetPeriod(period);
  return moment(new Date().setHours(0, 0, 0, 0)).startOf(setPeriod);
}

function getNewDateRangeForPeriod(period: Period, startDate: moment.Moment, endDate?: moment.Moment, fixed: Period[] = []): DateRange {
  const setPeriod = getSetPeriod(period);
  if (!endDate) {
    const newStartDate = startDate.clone().startOf(setPeriod);
    const newEndDate = newStartDate.clone().endOf(setPeriod);
    const today = new Date();
    return {
      startDate: fixed.includes(period) ? newStartDate.clone().add(today.getDay() - 1, 'day') : newStartDate,
      endDate: fixed.includes(period) ? newEndDate.clone().add(today.getDay() - 1, 'day') : newEndDate,
    };
  } else {
    return {
      startDate,
      endDate,
    };
  }
}

export function getSetPeriod(period: Period) {
  return period === 'week' ? 'isoWeek' : period;
}