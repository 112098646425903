import { CircularProgress, Fade, Theme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import { app } from 'application';
import React from 'react';
import { useRecoilValue } from 'recoil';

const useStyles = makeStyles<Theme, { isLoading: boolean }>({
  overlay: ({ isLoading }) => ({
    background: isLoading ? 'rgba(255,255,255,0.8)' : 'rgba(255,255,255,0)',
    zIndex: isLoading ? 10000000 : -1,
    transitionDuration: '300ms'
  })
});

const LoaderOverlay: React.FC = () => {
  const isLoading = useRecoilValue(app.globalState.isMakingRequest);
  const classes = useStyles({ isLoading });
  return (
    <Fade in={isLoading} timeout={200}>
      <Box
        className={classes.overlay}
        position="fixed"
        left={0}
        top={0}
        display="flex"
        justifyContent="center"
        alignItems="center"
        zIndex={10000000}
        width="100vw"
        height="100vh"
      >
        {isLoading && <CircularProgress size={96} />}
      </Box>
    </Fade>
  );
};

export default LoaderOverlay;
