import { FrontendApp } from "features/engine/models";
import { $widget, DetailsWidget, Page } from "shared/build/views";
import React from 'react';
import { widgets } from "components/widgets";
import keys from "lodash/keys";
import { extractFlatReferences } from "dollarscript";
import { run } from 'dollarscript/build/interpreter';
import { Route } from "react-router-dom";
import { ResourceController, Spec, } from "shared/build";
import flatten from "lodash/flatten";
import PageAnimation from "components/shared/page-animation.";

export function createPage(page: Page, frotendApp: FrontendApp) {
  const $widget = page.widget;
  const path = page.path;
  const references = extractFlatReferences(page.widget);
  const currentUser = references.includes('currentUser') ? frotendApp.useAuthService().currentUser : undefined;
  const context = { currentUser };
  const guard = (page || frotendApp.navigation.find(n => n.path === path))?.guard;
  const allow = !guard || run(context)(guard)?.allow;
  if (!allow) return <React.Fragment key={path} />;
  const widget = run(context)($widget);
  return <Route key={path} exact path={path}><PageAnimation>{createWidgets(widget)}</PageAnimation></Route>;
}

export function createWidgets(value: any, key?: string): any {
  if (value === null || value === undefined) {
    return <React.Fragment key={key}></React.Fragment>
  } else if (Array.isArray(value)) {
    return value.map((v, i) => createWidgets(v, `${JSON.stringify(v)}-${i}`));
  } else if (typeof value === 'object') {
    if (value.$widget) {
      const props = keys(value.props).map(k => ({ [k]: createWidgets(value.props[k]) })).reduce((acc, v) => ({ ...acc, ...v }), {});
      return React.createElement(
        widgets[value.$widget as $widget['$widget']],
        { ...props, key },
        value.children ? createWidgets(value.children) : undefined,
      )
    } else if (value.$Widget) {
      return widgets[value.$Widget as $widget['$widget']]
    } else {
      return value;
    }
  } else {
    return value;
  }
}

export function createDefaultPages(spec: Spec): Page[] {
  return flatten(spec.entities.filter(e => e.persistent).map(e => {
    const listOverriden = !!spec.pages.find(p => p.type === 'list' && p.entity === e.name)
    const detailsOverriden = !!spec.pages.find(p => p.type === 'details' && p.entity === e.name)
    if (listOverriden && detailsOverriden) return [];
    const customFilters = e.customFilters
    const filterFields = e.fields.filter(f => f.filter);
    const filters = customFilters && customFilters.length || !!filterFields.length ? filterFields.reduce((acc, v) => ({ ...acc, [v.name]: v.filter! }), {}) : undefined;
    const listWidget = {
      $widget: 'list',
      props: {
        title: e.pluralName,
        subtitle: `${e.pluralName}-subtitle`,
        entity: e.name,
        element: { $Widget: 'entityListItem' },
        elementProps: {
          title: { $$: { $: `currentEntity.${e.titleField || e.fields[0].name}` } },
          fields: e.fields.filter(f => f.type !== 'autogenerated-int' && f.type !== 'password' && f.type !== 'embedded' && f.type !== 'many-to-one').map(f => ({ label: f.name, format: f.type === 'timestamp' ? 'date' as any : undefined, value: { $$: { $: `currentEntity.${f.name}` } } })),
          actions: e.controller ? (keys(e.controller) as (keyof ResourceController)[]) : undefined,
          customActions: (e.actions || []).map(a => a.name),
          link: { $$: { $: { type: 'concat', values: [`/${e.pluralName}/`, { $: 'currentEntity.id' }] } } } as any,
        },
        filters,
      },
    };
    const list: Page = {
      type: 'list',
      name: e.pluralName,
      entity: e.name,
      path: `/${e.pluralName}`,
      widget: {
        $widget: 'row',
        children: [listWidget as $widget]
      }

    };
    const detailsWidget: DetailsWidget = {
      $widget: 'details',
      props: {
        entity: listWidget.props.entity,
        title: listWidget.props.elementProps.title,
        fields: listWidget.props.elementProps.fields,
        actions: listWidget.props.elementProps.actions,
        customActions: listWidget.props.elementProps.customActions,
        id: { $$: { $: 'urlParams.id' } }
      }
    }
    const details: Page = {
      type: 'details',
      name: e.name,
      path: `/${e.pluralName}/:id`,
      entity: e.name,
      widget: {
        $widget: 'row',
        children: [detailsWidget],
        props: {
          boxProperties: {
            pl: 3,
            pr: 3,
          }
        }
      }
    };
    if (detailsOverriden) return [list];
    if (listOverriden) return [details];
    return [
      list,
      details,
    ]
  }))
}