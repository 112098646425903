import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { app } from 'application';
import { FileControlWidget } from 'shared/build/views';
import { run } from 'dollarscript/build/interpreter';
import { FileUploadField } from 'components/forms/file-upload';
import keys from 'lodash/keys';

export type InputProps = FileControlWidget['props'];

export function FileControl(props: InputProps) {
  const frontendEntity = app.entities.find(e => e.name === props.entity)!;
  const urlParams = useParams();
  const currentUser = app.useAuthService().currentUser;
  const id = run({})(props.id)({ currentUser, urlParams });
  const state = frontendEntity.state()!;
  useEffect(() => {
    state.readSingle(id);
  }, []);
  const item = state.getSingle(id, app.states);
  return item ? <FileControlContent {...props} item={item}></FileControlContent> : <></>
}

function FileControlContent({
  entity,
  field,
  item
}: InputProps & { item: any }) {
  const frontendEntity = app.entities.find(e => e.name === entity)!;
  const state = frontendEntity.state()!;
  const control = {
    getValues: () => item,
    setValue: (name: string, values: any) => {
      const newItem = { ...item };
      const fieldNames = frontendEntity.fields.map(f => f.name);
      keys(newItem).forEach(k => {
        if (!fieldNames.includes(k)) {
          delete newItem[k];
        }
      })
      state.update({ ...newItem, [field]: { urls: values } })
    }
  }
  return (
    <FileUploadField name={field} control={control} initialValue={item} />
  );
}
