import React from 'react';
import { border } from 'features/mui/theme';
import { LabelValue, ValueComponent } from 'components/shared/label-value';
import { BooleanFieldIndicator } from 'components/shared/boolean-field-indicator';
import { camelToKebabCase } from 'features/forms/models';
import MaterialTable from 'material-table';
import Search from '@material-ui/icons/SearchRounded';
import FirstPage from '@material-ui/icons/FirstPageRounded';
import LastPage from '@material-ui/icons/FirstPageRounded';
import Clear from '@material-ui/icons/ClearRounded';
import NextPage from '@material-ui/icons/KeyboardArrowRightRounded';
import Info from '@material-ui/icons/InfoRounded';
import PreviousPage from '@material-ui/icons/KeyboardArrowLeftRounded';
import { run } from 'dollarscript/build/interpreter';
import { formatters } from 'features/engine/formats';
import { Box, Paper, Typography } from '@material-ui/core';
import { FieldView, TableWidget } from 'shared/build/views';
import { useTranslation } from 'react-i18next';
import { cloneDeep, differenceWith, isEmpty, isEqual } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import { useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

interface InputProps {
  items: any[];
  selection: boolean;
  currentUser: any;
  onSelectionChange: (rows: any[]) => void;
  fields: FieldView[];
  link?: any;
  entity: string;
  showToolbar?: boolean;
  selectedRows?: any[];
  rows?: number;
}

const Table: React.FC<InputProps> = ({ items, selection, currentUser, onSelectionChange, fields, link, entity, showToolbar, rows, selectedRows }) => {
  const { t, i18n } = useTranslation();
  onSelectionChange([]);
  let columnOrder = (JSON.parse(window.localStorage.getItem(`${entity}ColumnsOrder`) || 'null') as number[] | undefined) || fields.map((_v, i) => i);
  if (!columnOrder || columnOrder.length !== fields.length) {
    columnOrder = fields.map((_v, i) => i);
  }
  const generateCsv = () => {
    const csvContent = "data:text/csv;charset=utf-8,"
      + fields.map(f => t(f.label)).join(",")
      + "\n"
      + items.map(i => fields.map((f) => {
        const value = run({})(f.value)({ currentEntity: i, currentUser });
        return value && f.format ? formatters[f.format](value) : i18n.exists(String(value || '')) ? t(value) as any : value ? String(value) : '';
      }).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "loads-summary.csv");
    document.body.appendChild(link); // Required for FF
    link.click();
  }
  return (
    <>

      <MaterialTable
        onSelectionChange={(rows) => onSelectionChange(rows)}
        style={{ width: '100%', boxShadow: 'none', border, borderRadius: "0 0 8px 8px", borderTop: 'none' }}
        title={''}
        options={{ selection, pageSize: rows || 40, pageSizeOptions: [20, rows || 40, 80], exportButton: true }}
        columns={
          columnOrder.map((v) => {
            const f = fields[v];
            return ({
              title: t(camelToKebabCase(f.label)),
              field: f.field || f.label,
              render: (currentEntity: any) => {
                const value = run({})(f.value)({ currentEntity, currentUser });
                const tooltip = f.tooltip && run({})(f.tooltip)({ currentEntity, currentUser });
                const element = tooltip && !value ? <Box display="flex"><Info color="disabled" fontSize="small" /></Box> :
                  <Typography variant="caption">
                    {(typeof value === 'boolean' || typeof value === 'undefined' || value === null) ?
                      <BooleanFieldIndicator leftAlign={true} value={value} /> : f.format ? formatters[f.format](value) : i18n.exists(String(value || '')) ? t(value) as any : <span dangerouslySetInnerHTML={{ __html: String(value) }} />}
                  </Typography>
                return tooltip ? <Tooltip title={tooltip}>
                  {element}
                </Tooltip> : element
              },
            });
          })}
        data={items.map((i) => ({ ...i, link, selection }))}
        icons={{
          Search: Search as any,
          FirstPage: FirstPage as any,
          LastPage: LastPage as any,
          Clear: Clear as any,
          NextPage: NextPage as any,
          PreviousPage: PreviousPage as any,
        }}
        onColumnDragged={(from: number, to: number) => {
          if (from === to) return;
          const moved = columnOrder.splice(from, 1)[0];
          columnOrder.splice(to, 0, moved);
          window.localStorage.setItem(`${entity}ColumnsOrder`, JSON.stringify(columnOrder));
        }}
        components={{
          ...(showToolbar ? {

            Toolbar: () => {
              return <Box display="flex" justifyContent="flex-end" pb={1} pt={1} pr={1}>
                <Button color="primary" variant="contained" onClick={generateCsv}>{t('generate-csv')}</Button>
              </Box>
            }
          } : {
              Toolbar: () => {
                return <></>
              }
            }),
          Row: Row,
        }}
        localization={{
          header: {
            actions: t('actions'),
          },
          toolbar: {
            searchPlaceholder: t('search'),
          },
        }}
      />
    </>);
}

function isArrayEqual(x: any[], y: any[]) {
  return isEmpty(differenceWith(x, y, isEqual)) && x.length === y.length;
};

export const MemoizedTable = React.memo(Table, (prevProps, newProps) => {
  return isArrayEqual(prevProps.items, newProps.items);
});

const Row: React.FC<any> = (p: any) => {
  const history = useHistory();
  return <Box style={{ cursor: 'pointer' }} onClick={() => history.push(run({})(p.data.link)({ currentEntity: p.data }))} borderBottom={border} component="tr" display="table-row">
    {p.data.selection && (<Box
      position="sticky"
      left={0}
      top={0}
      style={{
        background: 'rgba(255,255,255,0.87)',
        backdropFilter: 'blur(2px)'
      }}
      whiteSpace="nowrap"
      component="td"
      width={48}
    >
      <Checkbox size="small" checked={p.data.tableData.checked === true} onChange={event => {
        p.onRowSelected(event, p.path, p.data);
      }} onClick={e => e.stopPropagation()} />
    </Box>
    )}
    {p.columns.sort((a: any, b: any) => a.tableData.columnOrder - b.tableData.columnOrder).map((c: any) => {
      return <Box whiteSpace="nowrap" p={0.5} key={c.tableData.id} component="td" width={c.tableData.width}>{c.render(p.data)}</Box>
    })}
  </Box>
}
