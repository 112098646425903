import { FrontendApp } from './models';
import { createStates, getGlobalState, getStateService } from './state';
import { Spec } from 'shared';
import { getAuthService } from './auth';
import { convertEntities } from './entity';
import { createDefaultPages } from './create-pages';
import { createNavigationItems } from './create-navigation';

export function run(spec: Spec): FrontendApp {
  const states = createStates(spec.entities);
  const globalState = getGlobalState();
  const entities = convertEntities(spec, states);
  const useStateService = getStateService(states, globalState);
  const useAuthService = getAuthService();
  const deafaultPages = createDefaultPages(spec);
  return {
    states,
    globalState,
    entities,
    enums: spec.enums,
    authorization: spec.authorization,
    useAuthService,
    useStateService,
    pages: [...spec.pages, ...deafaultPages],
    navigation: createNavigationItems(spec),
    services: spec.services,
  };
}

