import { app } from 'application';
import { run } from 'dollarscript/build/interpreter';
import React, { useEffect, useState } from 'react';
import { spec } from "shared";

export function Chat() {
  const {currentUser} = app.useAuthService();
  const [chatMounted, setChatMounted] = useState(false);

  useEffect(() => {
    if (!currentUser) return;
    if (chatMounted) return;
    if (!spec.services?.chat) return;

    switch (spec.services.chat.type) {
      case 'cometchat': {
        const w = (window as any);
        w.chat_appid = spec.services.chat.appId;
        w.chat_auth = spec.services.chat.authKey;
        const {id, name, avatar, profileLink} = run({currentUser})(spec.services.chat.user);
        w.chat_id = id;
        w.chat_name = name;
        w.chat_avatar = avatar;
        w.chat_link = profileLink;

        (function() {
          var chat_css = document.createElement('link'); chat_css.rel = 'stylesheet'; chat_css.type = 'text/css'; chat_css.href = 'https://fast.cometondemand.net/'+w.chat_appid+'x_xchat.css';
          document.getElementsByTagName("head")[0].appendChild(chat_css);
          var chat_js = document.createElement('script'); chat_js.type = 'text/javascript'; chat_js.src = 'https://fast.cometondemand.net/'+w.chat_appid+'x_xchat.js'; var chat_script = document.getElementsByTagName('script')[0]; chat_script?.parentNode?.insertBefore(chat_js, chat_script);
        })();

        setChatMounted(true);
      }
    }

  }, [currentUser, chatMounted])

  return <></>;
}