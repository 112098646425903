import React from 'react';
import Typography from '@material-ui/core/Typography';
import { FieldError, Controller } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';

type InputProps = CheckboxProps & {
  errors: FieldError | undefined;
  controllerProps: ControllerProps<'input'>;
  label: string;
};

export const FormCheckbox: React.FC<InputProps> = props => {
  const { label, errors, children, controllerProps, ...inputProps } = props;
  return (
    <>
      <Controller
        {...controllerProps}
        defaultValue={controllerProps.defaultValue}
        render={({ onChange, onBlur, value }) => (
          <FormControlLabel
            control={<Checkbox
              aria-invalid={!!errors}
              {...inputProps}
              checked={Boolean(value)}
              onChange={(_e, v) => {
                onChange(v);
              }}
              onBlur={onBlur}
            />}
            label={label}
          />
        )}
      />
      {errors && (
        <Typography role="alert" color="textSecondary">
          {errors.message}
        </Typography>
      )}
    </>
  );
};
