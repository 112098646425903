import React, { useEffect, useState } from 'react';
import Timeline, { CustomHeader, CustomMarker, TimelineHeaders, TodayMarker } from 'react-calendar-timeline'
import moment from 'moment'
import { app } from 'application';
import { run } from 'dollarscript/build/interpreter';
import flatten from 'lodash/flatten';
import { TimelineWidget } from 'shared/build/views';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@material-ui/core';
import { DateRageQueryControl, getDateRageAndPeriodFromUrl, getUpdatedQuery } from 'components/query-controls/date-range-control';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

type InputProps = TimelineWidget['props']

const voided = () => { };

const Marker = (props: { date: number }) => <CustomMarker date={props.date}>{
  ({ styles }) =>
    <>

      <div id={`${'my-marker'}-${props.date}`} style={{ ...styles, backgroundColor: 'rgba(0, 0, 0, 0.06)', width: 1, fontSize: '9px', color: 'rgba(0, 0, 0, 0.6)' }}> {new Date(props.date).getHours()}:00</div>
    </>
}</CustomMarker>

export const TimelineComponent: React.FC<InputProps> = ({
  groupEntity, eventEntities
}) => {
  const { dateRange, period } = getDateRageAndPeriodFromUrl('startDate', 'endDate', 'week', ['week']);
  const groupEntityInstance = app.entities.find(e => e.name === groupEntity.name)!;
  const eventEntitiesInstances = eventEntities.map(ee => ({ instance: app.entities.find(e => e.name === ee.name)!, config: ee }));
  const groupsState = groupEntityInstance.state()!;
  const eventStates = eventEntitiesInstances.map(eei => ({
    config: eei.config,
    state: eei.instance.state()!,
    query: getUpdatedQuery(eei.config.queryControl, eei.config.query, dateRange)
  }));
  const { t } = useTranslation();
  useEffect(() => {
    groupsState.read(groupEntity.query);
    eventStates.forEach((es) => es.state.read(es.query));
  }, [dateRange.startDate.valueOf()]);
  const groups = groupsState.getList(app.states).map((gi) => ({
    id: gi.id,
    title: run({})(groupEntity.title)({ currentEntity: gi, t }),
    subtitle: groupEntity.subttitle && run({})(groupEntity.subttitle)({ currentEntity: gi }),
    stackItems: true
  }));
  const theme = useTheme();
  let notAssignedCount = 0;
  const items = flatten(eventStates.map(es => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return es.state.getList(app.states).map(currentEntity => {
      const color = run({ currentEntity })(es.config.color)({ currentEntity });
      const group = run({})(es.config.relation)({ currentEntity, t }) || -1;
      if (group === -1) notAssignedCount = notAssignedCount + 1;
      return {
        id: `${es.config.name}_${currentEntity.id}`,
        group,
        title: run({})(es.config.title)({ currentEntity, t }),
        subtitle: es.config.subtitle && run({})(es.config.subtitle)({ currentEntity, t }),
        tooltip: es.config.tooltip && run({})(es.config.tooltip)({ currentEntity, t }),
        color: `${(theme.palette as any)[color || ''] ? (theme.palette as any)[color || ''].light : color || theme.palette.primary.light}`,
        start_time: moment(Number(run({})(es.config.startDate)({ currentEntity }))),
        end_time: moment(Number(run({})(es.config.endDate)({ currentEntity }))),
      }
    });
  }));
  const allGroups = [{ id: -1, title: t('not-assigned'), height: (notAssignedCount + 1) * 42, stackItems: true }, ...groups]
  const format = period === 'week' ? 'YYYY-MM-DD' : period === 'month' ? 'DD-MM' : 'HH:mm';
  return (<Box minWidth={period === 'week' ? '100%' : '1600px'} border="solid 1px rgba(0,0,0,0.3)" borderBottom="none" maxWidth="100%">
    <Timeline
      stackItems={true}
      traditionalZoom={true}
      groups={allGroups}
      onCanvasClick={voided}
      onZoom={voided}
      items={items}
      visibleTimeStart={dateRange.startDate}
      visibleTimeEnd={dateRange.endDate}
      onTimeChange={(_visibleTimeStart, _visibleTimeEnd, updateScrollCanvas) => {
        updateScrollCanvas(dateRange.startDate.valueOf(), dateRange.endDate.valueOf());
      }}
      itemRenderer={(props) => {
        const style: CSSProperties = { background: props.item.color, left: props.itemContext.dimensions.left, top: props.itemContext.dimensions.top || undefined, width: props.itemContext.dimensions.width, whiteSpace: 'nowrap', textShadow: '0px 0px 2px rgb(0,0,0)' };
        return <Box
          style={style}
          border="solid 1px rgba(0,0,0,0.2)"
          borderRadius={8}
          overflow="hidden"
          minWidth={16}
          boxShadow="0px 0px 2px rgba(0,0,0,0.1)"
          zIndex={90}
          position="absolute"
          textAlign={props.item.start_time.isSameOrBefore(dateRange.startDate) ? 'right' : 'left'}
        >
          <Tooltip title={props.item.tooltip}>
            <Box overflow="hidden" px={1}>
              <Box overflow="hidden" my="2px" color="#fff" fontSize="10px" lineHeight="10px" fontWeight="600" component="p">{props.item.title}</Box>
              <Box overflow="hidden" mt="2px" mb="1px" color="rgba(255,255,255,0.94)" fontSize={9} lineHeight="9px" component="p">{props.item.subtitle}</Box>
            </Box>
          </Tooltip>
        </Box>;
      }}
      groupRenderer={(props) => {
        const subtitle = (props.group as any).subtitle;
        return <Box p={1} pt={0.5} whiteSpace="nowrap" lineHeight="initial">
          <Box fontSize={9} lineHeight="9px" fontWeight={900}>{props.group.title}</Box>
          {subtitle && <Box mt={-0.5}><Typography style={{ fontSize: 9, lineHeight: '9px' }} variant="caption" color="textSecondary">{subtitle}</Typography></Box>}
        </Box>
      }}
    >
      <TimelineHeaders>
        <CustomHeader height={20} headerData={{ format }}>
          {(props) => {
            if (!props) return <></>;
            return (
              <>
                <Box maxWidth="100vw" display="flex" p={0.5} alignItems="center" justifyContent="space-between" marginLeft="100%" width="calc(100vw - 160px)">
                  <DateRageQueryControl fixedPeriods={['week']} period={period} dateRange={dateRange} />
                </Box>
                <div {...props.getRootProps()}>
                  {props.headerContext.intervals.map(interval => {
                    return (
                      <div
                        {...props.getIntervalProps({ interval })}
                      >
                        <Box fontSize="12px">
                          {interval.startTime.format(props.data.format)}
                        </Box>
                      </div>
                    )
                  })}
                </div>
              </>
            )
          }}
        </CustomHeader>
      </TimelineHeaders>
      <TodayMarker date={Date.now()} interval={60000} />
      {Array(period === 'week' ? 7 : 0).fill(undefined).map((_v, i) => {
        const date = dateRange.startDate.clone().add(i, 'day').toDate();
        return [
          <Marker date={date.setHours(6, 0, 0, 0)} />,
          <Marker date={date.setHours(12, 0, 0, 0)} />,
          <Marker date={date.setHours(18, 0, 0, 0)} />,
        ]
      })}
    </Timeline>
  </Box>)
}