import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/CloseRounded';
import ImageIcon from '@material-ui/icons/ImageRounded';
import { makeStyles } from '@material-ui/styles';
import { formatBytes } from 'features/engine/models';
import React from 'react';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    ['&:first-child']: {
      marginTop: theme.spacing(2),
    },
    ['&:last-child']: {
      marginBottom: theme.spacing(2),
    },
  },
  iconContainer: {
    flex: '0 0 auto',
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: 40,
    margin: `4px 10px -2px 4px`,
  },
  text: {
    flex: '1 0 0',
  },
  titleText: {
    lineHeight: 1.4,
  },
  closeButton: {
    flex: '0 0 auto',
  },
}));

interface InputProps {
  name: string;
  size: number;
  onDelete: (name: string) => void;
}

type Props = InputProps;

const FileListElement: React.FC<Props> = ({ name, size, onDelete }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.iconContainer}>
        <ImageIcon className={classes.icon} />
      </div>
      <div className={classes.text}>
        <Typography className={classes.titleText} variant={'subtitle2'}>
          {name}
        </Typography>
        <Typography variant={'caption'} color={'textSecondary'}>
          {formatBytes(size)}
        </Typography>
      </div>
      <IconButton
        size="small"
        className={classes.closeButton}
        onClick={() => onDelete(name)}
      >
        <CloseIcon />
      </IconButton>
    </Paper>
  );
};

export default FileListElement;
