import { run } from "dollarscript/build/interpreter";
import { FrontendEntity } from "features/engine/entity";
import { FrontendField } from "features/engine/field";
import isArray from "lodash/isArray";
import { FieldView } from "shared/build/views";

export function getFieldsWithValues(
  item: any,
  includedItems: {
    entity: FrontendEntity<any>;
    item: any;
    field: FrontendField<any>;
  }[],
  t: (value: any) => string,
  fields?: FieldView[]) {
  return (fields || []).map((f) => {
    const currentEntity = { ...item };
    if (f.manyToOne) {
      includedItems.forEach((ii) => {
        currentEntity[ii.field.specField.name] = ii.item;
      })
    }
    const value = run({})(f.value)({ currentEntity: item });
    const tooltip = f.tooltip && run({})(f.tooltip)({ currentEntity: item });
    return {
      name: f.label,
      value: value || '',
      format: f.format,
      field: f,
      tooltip,
    };
  })
}

export const handleFilter = (filter: { value: any, type: any }, f: string, i: any) => {
  if (filter.type === 'eq') {
    return String(filter.value).toLocaleLowerCase() === String((i as any)[f]).toLocaleLowerCase();
  } else if (filter.type === 'startsWith') {
    return String((i as any)[f]).toLocaleLowerCase().startsWith(String(filter.value).toLocaleLowerCase());
  } else {
    if (isArray((i as any)[f])) {
      return (i as any)[f].includes(filter.value)
    } else {
      return String((i as any)[f]).toLocaleLowerCase().includes(String(filter.value).toLocaleLowerCase());
      // return JSON.stringify((i as any)[f]).toLocaleLowerCase().includes(String(filter.value).toLocaleLowerCase());
    }
  }
}