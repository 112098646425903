import React, { useCallback } from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { LabelValue } from 'components/shared/label-value';
import { useHistory } from 'react-router-dom';
import { app } from 'application';
import { camelToKebabCase } from 'features/forms/models';
import { BooleanFieldIndicator } from 'components/shared/boolean-field-indicator';
import { Actions } from './actions';
import { FrontendEntity } from 'features/engine/entity';
import { makeStyles } from '@material-ui/core';
import { EntityListItemWidget } from 'shared/build/views';
import { run } from 'dollarscript/build/interpreter';
import { formatters } from 'features/engine/formats';
import Info from '@material-ui/icons/InfoRounded';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';


const useStyles = makeStyles(() => ({
  container: {
    transitionDuration: '200ms',
    transitionProperty: 'border',
    borderRadius: 16,
    cursor: 'pointer',
    border: 'dotted 2px rgba(0,0,0,0)',
    ['&:hover ']: {
      border: 'dotted 2px rgba(0,0,0,0.13)',
      boxShadow: '0px 2px 2px rgba(0,0,0,0.01), 0px 0px 3px rgba(0,0,0,0.02)'
    }
  },
}));

type InputProps = EntityListItemWidget['props'] & {
  item: Record<string, any>;
  entity: FrontendEntity;
  isMaterialized: boolean;
}

export function EntityListItem({ title, item, fields, customActions, link, actions, entity, isMaterialized }: InputProps) {
  const classes = useStyles();
  const history = useHistory();
  const titleValue = run({})(title)({ currentEntity: item });
  const { t, i18n } = useTranslation();
  const state = entity.state()!;
  const includedItems = state.getIncludedItems(item, app);
  const fieldsWithValues = (fields || []).map((f) => {
    const currentEntity = { ...item };
    if (f.manyToOne) {
      includedItems.forEach((ii) => {
        currentEntity[ii.field.specField.name] = ii.item;
      })
    }
    const value = run({})(f.value)({ currentEntity: item });
    const tooltip = f.tooltip && run({})(f.tooltip)({ currentEntity: item });
    return {
      name: f.label,
      value: f.format && value ? formatters[f.format](value) : !value ? value : i18n.exists(String(value)) ? t(String(value)) : String(value),
      tooltip,
    };
  })

  const handleClick = useCallback(() => {
    if (link) {
      history.push((link as any)({ currentEntity: item }));
    }
  }, [link, entity, item]);
  return (
    <Box paddingX={2} paddingY={1} pb={0.5} className={classes.container} width="100%" height="100%" onClick={handleClick}>
      <Box className="title" mb={1}>
        <Typography variant="subtitle1"><Box fontWeight={900}>{t(titleValue)}</Box></Typography>
      </Box>
      <Box mb={1} flexWrap="wrap" display="flex">
        <Box display="flex" flexWrap="wrap" alignItems="center">
          {fieldsWithValues.map((fv: any) => {
            const element = fv.tooltip && !fv.value ? <Box display="flex" justifyContent="center"><Info fontSize="small" color="disabled" /></Box> : typeof fv.value === 'boolean' || typeof fv.value === 'undefined' || fv.value === null || fv.value === 'true' || fv.value === 'false' ? <BooleanFieldIndicator value={Boolean(fv.value)} /> : <span dangerouslySetInnerHTML={{__html: String(fv.value)}}/>;
            const finalElement = fv.tooltip ? <Tooltip title={fv.tooltip}>{element}</Tooltip> : element;
            return (
              <Box mb={2} key={fv.name} mr={2}>
                <LabelValue
                  orientation="vertical"
                  label={t(camelToKebabCase(fv.name))}
                  disableTranslation={true}
                  value={finalElement}
                />
              </Box>
            )
          })}
        </Box>
        <Actions actions={actions || []} customActions={customActions || []} selectedItems={[isMaterialized ? item.sourceEntity : item]} entity={entity} />
      </Box>
    </Box >
  );
}
