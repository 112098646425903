import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import difference from 'lodash/difference';
import uniqBy from 'lodash/uniqBy';
import React, { useCallback } from 'react';
import Confirmation from './confirmation';
import DropzoneWithButton from './dropzone-with-button';
import FileListConponent from './files-list';
import FullscreenFileDropzone from './fullscreen-file-dropzone';
import Uploading from './uploading';
import { postFile, getCancelTokenSource } from 'features/engine/file-upload-models';
import { DialogConfig } from 'features/engine/models';

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    height: 334,
    minWidth: 380,
  },
}));

interface InputProps {
  isShowingConfirmation: boolean;
  isSingleFile: boolean;
  onUpload: (urls: string[]) => void;
  onClose: () => void;
  dialogConfig?: DialogConfig;
  entityName?: string;
}

type Props = InputProps;

interface State {
  readonly draggingFiles: boolean;
  readonly files: File[];
}

const initialState: State = {
  draggingFiles: false,
  files: [],
};

const UploadFilesComponent: React.FC<Props> = ({
  isShowingConfirmation,
  isSingleFile,
  onUpload,
  onClose,
  dialogConfig,
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState(initialState);
  const [isUploadingFiles, setIsUploadingFiles] = React.useState(false);
  const cancelSourceRef = React.useRef(getCancelTokenSource());
  const [totalUpload, setTotalUpload] = React.useState(0);
  const [isCanceling, setIsCanceling] = React.useState(false);

  const handleOnDrag = useCallback(
    (drag: boolean) => {
      setState(prevState => ({ ...prevState, draggingFiles: drag }));
    },
    [setState]
  );

  const handleOnFilesAdded = (files: File[]) => {
    const updatedFiles = uniqBy([...state.files, ...files], 'name');
    setState(prevState => ({ ...prevState, files: updatedFiles }));
  };

  const handleOnFilesRemoved = (files: File[]) => {
    const updatedFiles = difference(state.files, files);
    setState(prevState => ({ ...prevState, files: updatedFiles }));
  };

  const uploadFiles = async (files: File[]) => {
    const uploaded: string[] = [];
    for (let i = 0; i < files.length; i++) {
      setIsUploadingFiles(true);
      const file = files[i];
      setTotalUpload(file.size);
      await postFile('files/loads', file, cancelSourceRef.current.token)
        .then(response => {
          const url = response.data.urls[0];
          uploaded.push(url);
        })
        .catch(err => {
          console.log(err);
          setIsCanceling(false);
          cancelSourceRef.current = getCancelTokenSource();
        });
    }
    setIsCanceling(false);
    setIsUploadingFiles(false);
    onUpload(uploaded);
  };

  const cancel = React.useCallback(() => {
    cancelSourceRef.current.cancel();
    setIsCanceling(true);
  }, [cancelSourceRef, setIsCanceling]);

  return (
    <div className={classes.root}>
      {isShowingConfirmation ? (
        <Confirmation
          closeUploadDialog={onClose}
          uploadedBytes={totalUpload}
          isCanceled={isCanceling}
        />
      ) : isUploadingFiles ? (
        <Uploading
          isCanceling={isCanceling}
          totalUpload={totalUpload}
          onCancelFileUpload={cancel}
        />
      ) : (
        <>
          <FullscreenFileDropzone
            onDrag={handleOnDrag}
            onFilesAdded={handleOnFilesAdded}
          />
          {state.files.length > 0 ? (
            <FileListConponent
              files={state.files}
              isSingleFile={isSingleFile}
              isUploadingFiles={isUploadingFiles}
              uploadFiles={uploadFiles}
              onFilesAdded={handleOnFilesAdded}
              onFilesRemoved={handleOnFilesRemoved}
            />
          ) : (
            <DropzoneWithButton
              draggingFiles={state.draggingFiles}
              onFilesAdded={handleOnFilesAdded}
              onClose={onClose}
              dialogConfig={dialogConfig}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UploadFilesComponent;
