import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/styles/makeStyles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/MenuRounded';
import { useRouteMatch, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { app } from 'application';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { ListItem, Hidden, Typography, Icon, Avatar, Theme } from '@material-ui/core';
import { LogoutButton } from 'components/auth/logout-button';
import { run } from 'dollarscript/build/interpreter';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    background: '#151515'
  },
  button: {
    color: '#fff'
  },
  avatar: {
    color: 'white',
    background: '#3a3a3a',
    width: 40,
    height: 40,
  }
}));

export const Layout: React.FC = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [drawerOpened, setDrawerOpened] = useState(false);
  const isOnSignIn = useRouteMatch({
    path: '/sign-in'
  });
  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpened(open);
  }
  const { currentUser } = app.useAuthService();
  const context = { currentUser };
  const navigation = app.navigation.filter((ni) => !ni.guard || run(context)(ni.guard).allow);
  return (
    <Box min-height="100%" width="100%">
      {!(isOnSignIn && isOnSignIn.isExact) && (
        <>
          <Box
            zIndex={1000}
            className={classes.appBar}
            position="fixed"
            width="100%"
            height="56px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingX={3}
          >
            <Hidden xsDown>
              <Box display="flex" marginBottom="-3px">
                <Link to="/">
                  <img height="22px" alt="" src="/logo-white.png" />
                </Link>
              </Box>
            </Hidden>
            <Hidden xsDown>
              <Box flexGrow={1} />
              <Box mr={1}>
                {navigation.filter(ni => ni.toolbar).map((ni) => (<Link key={run(context)(ni.path)} to={run(context)(ni.path)}>
                  <Button className={classes.button} startIcon={<Icon fontSize="small" color="inherit">{ni.icon}</Icon>}>{t(ni.label)}</Button>
                </Link>))}
              </Box>
            </Hidden>
            {currentUser && currentUser.name && <Link to="/"><Box display="flex" alignItems="center" mr={1}>
              <Icon color="secondary">radio_button_checked</Icon>
              <Box color="white" ml={1.5}>
                <Typography variant="body1">
                  <Box mb={-0.5} fontSize={14} fontWeight={600}>
                    {currentUser.name}
                  </Box>
                </Typography>
                <Typography variant="caption">{currentUser.email}</Typography>
              </Box>
            </Box>
            </Link>}
            <Box mr={-2}>
              <IconButton onClick={toggleDrawer(true)} className={classes.button}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </>
      )
      }
      <SwipeableDrawer
        anchor="right"
        open={drawerOpened}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box display="flex" flexDirection="column" height="100%" color="white" style={{ background: 'rgb(20 20 23)' }}>
          <Box height={56} />
          <Box minWidth={280}>
            {navigation.filter(ni => ni.sidebar).map((ni) => (<Link key={run(context)(ni.path)} to={run(context)(ni.path)}>
              <ListItem onMouseUp={toggleDrawer(false)} button={true}>
                <Box alignItems="center" display="flex" color="rgba(255,255,255,0.87)">
                  <Box display="flex" mr={2}><Icon fontSize="small" color="inherit">{ni.icon}</Icon></Box>
                  <Typography color="inherit"><Box fontWeight={600} component="span">{t(ni.label)}</Box></Typography>
                </Box>
              </ListItem>
            </Link>))}
          </Box>
          <Box p={2} textAlign="right">
            <LogoutButton onMouseUp={toggleDrawer(false)} />
          </Box>
          <Box flexGrow={1} />
          <Box pb={1.5} textAlign="center">
            <img height="15px" alt="" src="/logo-white.png" />
          </Box>
        </Box>
      </SwipeableDrawer>
      <Box height="100%" pb={3} pt={7}>
        {children as React.ReactElement}
      </Box>
    </Box >
  );
};
